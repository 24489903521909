import reportTypes from "../types/report";

const initialStore = {
  modalPreloader: false,
  reportModal: false,
  reportModalStep: 1,
  dateReportStart: undefined,
  dateReportEnd: undefined,
  reportDataTypes: [
    {
      id: 'pretensions',
      name_ua: 'Претензії',
      name_ru: 'Претензии',
      checked: false,
    },
    {
      id: 'returns',
      name_ua: 'Повернення',
      name_ru: 'Возвраты',
      checked: false,
    }
  ],
  reportAddresses: [],
  reportAddressesChecked: [],
  reportAddressesAll: false,
  reportError: '',
}

const reportStore = (state = initialStore, action) => {
  switch (action.type) {
    case reportTypes.SET_MODAL_PRELOADER:
      return {
        ...state,
        modalPreloader: action.value,
      };
    case reportTypes.SET_REPORT_MODAL:
      return {
        ...state,
        reportModal: action.bool,
      };
    case reportTypes.SET_REPORT_MODAL_STEP:
      return {
        ...state,
        reportModalStep: action.num,
      };
    case reportTypes.SET_REPORT_DATE_START:
      return {
        ...state,
        dateReportStart: action.text,
      };
    case reportTypes.SET_REPORT_DATE_END:
      return {
        ...state,
        dateReportEnd: action.text,
      };
    case reportTypes.SET_REPORT_DATA_TYPES:
      return {
        ...state,
        reportDataTypes: action.array,
      };
    case reportTypes.SET_REPORT_ADDRESSES:
      return {
        ...state,
        reportAddresses: action.array,
      };
    case reportTypes.SET_REPORT_ADDRESSES_CHECKED:
      return {
        ...state,
        reportAddressesChecked: action.array,
      };
    case reportTypes.SET_REPORT_ADDRESSES_ALL:
      return {
        ...state,
        reportAddressesAll: action.bool,
      };
    case reportTypes.SET_REPORT_ERROR:
      return {
        ...state,
        reportError: action.text,
      };
    default: return state;
  }
}

export default reportStore;
