import React, {useEffect} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import Helmet from "react-helmet";
import {Link} from "react-router-dom";
import SearchPretensions from "./SearchPretensions";
import PretensionsList from "./PretensionsList";
import PretensionChat from "./PretensionChat";
import pretensionsActions from "../../store/actions/pretensions";
import PretensionViewImages from "./PretensionViewImages";
import PretensionViewList from "./PretensionViewList";
import PretensionStatus from "./PretensionStatus";
import SearchProducts from "./SearchProducts";
import SearchPretensionProducts from "./SearchPretensionProducts";
import Preloader from "../Preloader/Preloader";


const pretensionsHeadline = {
  ru : 'Претензия',
  ua : 'Претензія',
};
const pretensionInfoTitle = {
  ru : 'Информация о претензии',
  ua : 'Інформація про претензію',
};
const pretensionNumber = {
  ru : 'Номер претензии',
  ua : 'Номер претензії',
};
const pretensionDateForm = {
  ru : 'Дата оформления',
  ua : 'Дата оформлення',
};
const textBack = {
  ru : 'Назад к списку претензий',
  ua : 'Назад до списку претензій',
};
const textSave = {
  ru : 'Сохранить изменения',
  ua : 'Зберегти зміни',
};
const textCancel = {
  ru : 'Отменить',
  ua : 'Скасувати',
};
const textEdits = {
  ru : 'Правки',
  ua : 'Виправлення',
};





const PretensionView = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore   = useSelector((store) => store.configStore);
  const dispatch      = useDispatch();

  const returnToSearch = () => {
    dispatch(pretensionsActions.setPretensionsSection(1));
  }

  const UploadMoreImages = () => {
    if (pretensionsStore.imagesList.length > 0) {
      dispatch(pretensionsActions.AddMoreImages(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.currentPretensionUid,
        pretensionsStore.imagesList,
      ));
    } else {
      pretensionsActions.setPretensionsError('view_save_success');
      returnToSearch();
    }
  }

  return (
    <section className="pretension-view">
      <div className="pretension-view__headline-container">
        <button type="button" className="pretension-view__btn-back" onClick={returnToSearch}>
          <i className="icon-chevron-left-bold"/>
          <div className="pretension-view__btn-back-text">
            {configStore.isCurrentLangUa ? textBack.ua : textBack.ru}
          </div>
        </button>
        <h2 className="pretension-view__headline">
          {configStore.isCurrentLangUa ? pretensionsHeadline.ua : pretensionsHeadline.ru}
          <span> №{pretensionsStore.currentPretension.docnum}</span>
        </h2>
      </div>
      <div className="pretension-view__body">
        <Preloader/>
        <div className="pretension-view__aside">
          <PretensionChat/>
          <div className="pretension-view__info">
            <div className="pretension-view__info-title">
              {configStore.isCurrentLangUa ? pretensionInfoTitle.ua : pretensionInfoTitle.ru}
            </div>
            <div className="pretension-view__info-list">
              <div className="pretension-view__info-item">
                <div className="pretension-view__info-col-left">
                  {configStore.isCurrentLangUa ? pretensionNumber.ua : pretensionNumber.ru}
                </div>
                <div className="pretension-view__info-col-right">
                  {pretensionsStore.currentPretension.docnum}
                </div>
              </div>
              <div className="pretension-view__info-item">
                <div className="pretension-view__info-col-left">
                  Статус
                </div>
                <div className="pretension-view__info-col-right">
                  <PretensionStatus status={pretensionsStore.currentPretension.status}/>
                </div>
              </div>
              <div className="pretension-view__info-item">
                <div className="pretension-view__info-col-left">
                  {configStore.isCurrentLangUa ? pretensionDateForm.ua : pretensionDateForm.ru}
                </div>
                <div className="pretension-view__info-col-right">
                  {pretensionsStore.currentPretension.entered}
                </div>
              </div>
              <div className="pretension-view__info-item">
                <div className="pretension-view__info-col-left">
                  Фактура
                </div>
                <div className="pretension-view__info-col-right">
                  {pretensionsStore.pretensionFactureNum}
                </div>
              </div>
              <div className="pretension-view__info-item">
                <div className="pretension-view__info-col-left">
                  {configStore.isCurrentLangUa ? textEdits.ua : textEdits.ru}
                </div>
                <div className="pretension-view__info-col-right">
                  {pretensionsStore.currentPretension.dif === '1' ?
                    `${configStore.isCurrentLangUa ? 'Ні' : 'Нет'}` :
                    `${configStore.isCurrentLangUa ? 'Так' : 'Да'}`}
                </div>
              </div>
              <div className="pretension-view__info-item">
                <div className="pretension-view__info-col-left">
                  {configStore.isCurrentLangUa ? 'Сума' : 'Сумма'}
                </div>
                <div className="pretension-view__info-col-right">
                  {pretensionsStore.currentPretension.amount} грн
                </div>
              </div>
              <div className="pretension-view__info-item">
                <div className="pretension-view__info-col-left">
                  {configStore.isCurrentLangUa ? 'Оновлений' : 'Обновлен'}
                </div>
                <div className="pretension-view__info-col-right">
                  {pretensionsStore.currentPretension.changeddate}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pretension-view__content">
          <div className="pretension-view__content-title">
            Фактура {pretensionsStore.pretensionFactureNum}
            {configStore.isCurrentLangUa ? ' від ' : ' от '}
            {pretensionsStore.pretensionFactureDate}
          </div>
          <PretensionViewImages/>
          <SearchPretensionProducts/>
          <PretensionViewList/>
        </div>
      </div>
      <div className="pretension-view__footer">
        <button type="button" className="pretension-view__btn-back" onClick={returnToSearch}>
          <i className="icon-chevron-left-bold"/>
          <div className="pretension-view__btn-back-text">
            {configStore.isCurrentLangUa ? textBack.ua : textBack.ru}
          </div>
        </button>
        <button className="pretension-view__button-cancel"
                type="button" onClick={returnToSearch}>
          <i className="icon-status-error"/>
          {configStore.isCurrentLangUa ? textCancel.ua : textCancel.ru}
        </button>
        <button className="pretension-view__button-save"
                type="button"
                onClick={() => UploadMoreImages()}>
          <i className="icon-hammer"/>
          {configStore.isCurrentLangUa ? textSave.ua : textSave.ru}
        </button>
      </div>
    </section>
  );
}


export default PretensionView;
