import React, {useEffect} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import Helmet from "react-helmet";
import {Link} from "react-router-dom";
import SearchPretensions from "./SearchPretensions";
import PretensionsList from "./PretensionsList";
import DocumentsFactures from "../Documents/DocumentsFactures";
import documentsActions from "../../store/actions/documents";
import pretensionsActions from "../../store/actions/pretensions";


const pretensionsHeadline       = {
  ru : 'Претензии',
  ua : 'Претензії',
};
const pretensionsHeadlineButton = {
  ru : 'Инструкция по разделу',
  ua : 'Інструкція до розділу',
};
const nothing                   = {
  ua : 'Нічого не знайдено за вашими критеріями пошуку. ',
  ru : 'Ничего не найдено по вашим критериям поиска. ',
};
const backText                  = {
  ua : 'Повернутись до пошуку.',
  ru : 'Вернуться к поиску.',
};


const PretensionsChoose = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore   = useSelector((store) => store.configStore);
  const dispatch      = useDispatch();

  const resetSearch = () => {
    dispatch(pretensionsActions.setErrorNum(0));
    dispatch(pretensionsActions.setDateStart(undefined));
    dispatch(pretensionsActions.setDateEnd(undefined));
    dispatch(pretensionsActions.setSearchFactureNum(''));
    dispatch(pretensionsActions.setSearchPretensionStatus('ALL'));
    dispatch(pretensionsActions.setPretensionsSortColumn(''));
    dispatch(pretensionsActions.setPretensionsSortDirection(''));
    dispatch(pretensionsActions.setCurrentPage(1));
    dispatch(
      pretensionsActions.fetchPretensions(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        undefined,
        undefined,
        '',
        'ALL',
        '',
        '',
        pretensionsStore.perPage,
        1,
      ),
    );
  };

  return (
    <section className="pretensions">
      <div className="pretensions__headline-container">
        <h2 className="pretensions__headline">
          {configStore.isCurrentLangUa ? pretensionsHeadline.ua : pretensionsHeadline.ru}
        </h2>
        <Link className="pretensions__button-instructions" to="/pretensions-help">
          {configStore.isCurrentLangUa ? pretensionsHeadlineButton.ua : pretensionsHeadlineButton.ru}
        </Link>
      </div>
      <SearchPretensions/>
      <div className="pretensions__content">
        {pretensionsStore.errorNum === 1 ? (
          <p className="pretensions__content-search-error">
            <i className="icon-info"/>
            {configStore.isCurrentLangUa ? nothing.ua : nothing.ru}
            <button
              onClick={resetSearch}
              type="button"
              className="certificates__content-search-error-button"
            >
              {configStore.isCurrentLangUa ? backText.ua : backText.ru}
            </button>
          </p>
        ) : (
          <PretensionsList/>
        )}
      </div>
    </section>
  );
}


export default PretensionsChoose;
