import React, {useEffect} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import StepSearchFactures from "./StepSearchFactures";
import StepFormalizePretension from "./StepFormalizePretension";
import pretensionsActions from "../../store/actions/pretensions";
import documentsActions from "../../store/actions/documents";
import Preloader from "../Preloader/Preloader";

const pretensionsHeadlineSearch  = {
  ru : 'Создание претензии',
  ua : 'Створення претензії',
};
const pretensionsHeadlineFormalize  = {
  ru : 'Оформление претензии',
  ua : 'Оформлення претензії',
};

const PretensionCreate = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore   = useSelector((store) => store.configStore);
  const dispatch      = useDispatch();

  let pretensionCreateStep;
  let hasInfo = false;
  let pretensionsHeadline = 'test';
  if (pretensionsStore.pretensionsStep === 1) {
    pretensionCreateStep = <StepSearchFactures/>
    hasInfo = true;
    pretensionsHeadline = configStore.isCurrentLangUa ?
      pretensionsHeadlineSearch.ua : pretensionsHeadlineSearch.ru;
  } else if (pretensionsStore.pretensionsStep === 2) {
    pretensionCreateStep = <StepFormalizePretension/>
    pretensionsHeadline = configStore.isCurrentLangUa ?
      pretensionsHeadlineFormalize.ua : pretensionsHeadlineFormalize.ru;
  }

  useEffect(() => {
    dispatch(pretensionsActions.fetchPretensionsInfoColumn(configStore.isCurrentLangUa));
  }, [configStore.isCurrentLangUa]);

  return (
    <section className="pretensions">
      <div className="pretensions__headline-container">
        <h2 className="pretensions__headline">
          {pretensionsHeadline}
        </h2>
      </div>
      <div className="pretensions__content">
        <div className="pretension-create">
          <div className="pretension-create__main">
            {pretensionCreateStep}
          </div>
          <div className={`pretension-create__info ${hasInfo ? '' : 'hidden'}`}>
            <div dangerouslySetInnerHTML={{ __html: pretensionsStore.pretensionsInfoColumn }} />
          </div>
        </div>
      </div>
    </section>
  );
}


export default PretensionCreate;
