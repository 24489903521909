import { combineReducers } from 'redux';
import configStore from './config';
import loginStore from './login';
import deliveryStore from './delivery';
import menuStore from './menu';
import factureStore from './factures';
import certificatesStore from './certificates';
import documentsStore from './documents';
import pretensionsStore from './pretensions';
import reportStore from './report';
import mailsStore from './mails';

export default combineReducers({
  configStore,
  loginStore,
  certificatesStore,
  documentsStore,
  deliveryStore,
  factureStore,
  menuStore,
  pretensionsStore,
  reportStore,
  mailsStore
});

