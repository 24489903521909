import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UtpLogin from './UtpLogin';
import loginActions from '../../store/actions/login';

const LoginContent = () => {
  const loginStore = useSelector((store) => store.loginStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginActions.fetchInfo(configStore.isCurrentLangUa));
  }, [configStore.isCurrentLangUa]);

  return (
    <div className="login__left">
      <h1 className="login__headline">{loginStore.headline}</h1>
      <UtpLogin />
      <div
        className="login__bottom-text"
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: loginStore.bottomText }}
      />
    </div>
  );
};

export default LoginContent;
