import deliveryTypes from '../types/delivery';

const initialStore = {
  selectCity: '',
  isOpenPopUp: false,
  selectCityUid: '',
  addresses: [],
};

const loginStore = (state = initialStore, action) => {
  switch (action.type) {
    case deliveryTypes.SELECT_CITY:
      return {
        ...state,
        selectCity: action.city,
      };
    case deliveryTypes.SELECT_CITY_UID:
      return {
        ...state,
        selectCityUid: action.uid,
      };
    case deliveryTypes.SET_ADDRESSES:
      return {
        ...state,
        addresses: action.addresses,
      };
    case deliveryTypes.TOGGLE_POP_UP:
      return {
        ...state,
        isOpenPopUp: action.state,
      };
    default:
      return state;
  }
};

export default loginStore;
