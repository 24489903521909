import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {LightBox} from 'react-lightbox-pack';
import "react-lightbox-pack/dist/index.css";
import pretensionsActions from "../../store/actions/pretensions";
import ViewImageStatic from "./ViewImageStatic";
import ImagesUpload from "./ImagesUpload";



const uploadTitle = {
  ru : 'Ваши фото',
  ua : 'Ваші фото',
};
const uploadDescription = {
  ru : 'Форматы jpeg, jpg, png. Размер не более 800 КБ каждая. Максимальный размер одной стороны не более 180 px.',
  ua : 'Формати jpeg, jpg, png. Розмір не більше 800 КБ кожна. Максимальний розмір однієї сторони не більше 180 px.',
};

const PretensionViewImages = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore   = useSelector((store) => store.configStore);
  const dispatch = useDispatch();

  // State
  const [toggleClient, setToggleClient] =  React.useState(false);
  const [toggleAdmin, setToggleAdmin] =  React.useState(false);
  const [sIndexClient, setSIndexClient] =  React.useState(0);
  const [sIndexAdmin, setSIndexAdmin] =  React.useState(0);

  // eslint-disable-next-line no-shadow
  const  lightBoxHandlerClient  = (state, sIndexClient) => {
    setToggleClient(state);
    setSIndexClient(sIndexClient);
  };
  // eslint-disable-next-line no-shadow
  const  lightBoxHandlerAdmin  = (state, sIndexAdmin) => {
    setToggleAdmin(state);
    setSIndexAdmin(sIndexAdmin);
  };

  const imageUploadedRemove = (item) => {
    dispatch(pretensionsActions.deleteUploadedImage(
      configStore.isCurrentLangUa,
      deliveryStore.selectCityUid,
      pretensionsStore.currentPretensionUid,
      item.uid
    ));
  }

  return (
    <div className="pretension-formalize__details pretension-view">
      <div className="details-block images client">
        <div className="details-block__heading">
          <div className="details-block__title">{configStore.isCurrentLangUa ? uploadTitle.ua : uploadTitle.ru}</div>
          <div className="details-block__description">
            {configStore.isCurrentLangUa ? uploadDescription.ua : uploadDescription.ru}
          </div>
        </div>
        <div className="images-wrapper">
          <div className="images-upload">
            <div className="images-list">
              {pretensionsStore.imagesClientList.map((item, index) => (
                <div className="image-item uploaded">
                  <button className="image-item__open" type="button" onClick={() => {
                    lightBoxHandlerClient(true, index);
                  }}>
                    <img
                      key={item.id}
                      src={item.image}
                      alt={item.title}
                      style={{ maxHeight:  "80vh", maxWidth:  "50vw" }}
                    />
                  </button>
                  <button type="button" className="image-item__remove" onClick={() => imageUploadedRemove(item)}>
                    <i className="icon-close-thin"/>
                  </button>
                </div>
              ))}
              <LightBox
                state={toggleClient}
                event={lightBoxHandlerClient}
                data={pretensionsStore.imagesClientList}
                imageWidth="60vw"
                imageHeight="70vh"
                thumbnailHeight={50}
                thumbnailWidth={50}
                setImageIndex={setSIndexClient}
                imageIndex={sIndexClient}
              />
            </div>
          </div>
          <ImagesUpload/>
        </div>
      </div>
      <div className="details-block images admin">
        <div className="details-block__heading">
          <div className="details-block__title">
            Фото Вента
          </div>
        </div>
        <div className="images-upload">
          <div className="images-list">
            {pretensionsStore.imagesAdminList.map((item, index) => (
              <div className="image-item">
                <button className="image-item__open" type="button" onClick={() => {
                  lightBoxHandlerAdmin(true, index);
                }}>
                  <img
                    key={item.index}
                    src={item.image}
                    alt={item.title}
                    style={{ maxHeight:  "80vh", maxWidth:  "50vw" }}
                  />
                </button>
              </div>
            ))}
            <LightBox
              state={toggleAdmin}
              event={lightBoxHandlerAdmin}
              data={pretensionsStore.imagesAdminList}
              imageWidth="60vw"
              imageHeight="70vh"
              thumbnailHeight={50}
              thumbnailWidth={50}
              setImageIndex={setSIndexAdmin}
              imageIndex={sIndexAdmin}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PretensionViewImages;
