import React, {useEffect} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import ReactPaginate from "react-paginate";
import Preloader from "../Preloader/Preloader";
import PretensionItem from "./PretensionItem";
import pretensionsActions from '../../store/actions/pretensions';
import StatusDropdown from "./StatusDropdown";
import facturesActions from "../../store/actions/factures";
import documentsActions from "../../store/actions/documents";
import CountList from "../CountList/CountList";
import menuActions from "../../store/actions/menu";


const PretensionsList = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const configStore    = useSelector((store) => store.configStore);
  const deliveryStore  = useSelector((store) => store.deliveryStore);
  const dispatch       = useDispatch();


  const headingAnswer = {
    ua : 'Відповідь',
    ru : 'Ответ',
  };
  const headingSum = {
    ua : 'Сума, грн',
    ru : 'Сумма, грн',
  }
  const headingUpdated = {
    ua : 'Оновлений',
    ru : 'Обновлен',
  }
  const filterBtn = {
    ua : 'Фільтр',
    ru : 'Фильтр',
  }

  const listPretensions = pretensionsStore.pretensionsList.map((elem) => (
    <PretensionItem
      key={elem.uid}
      number={elem.docnum}
      date={elem.date}
      facture={elem.invoicenum}
      hasphoto={elem.hasphoto}
      changed={elem.changed}
      edits={elem.diff}
      answer={elem.answer}
      sum={elem.amount}
      updated={elem.changeddate}
      status={elem.status}
      pretensionUid={elem.uid}
      pretensionItem={elem}
      changeddate={elem.changeddate}
    />
  ));

  const sortPretensions = (column) => {
    let direction = pretensionsStore.pretensionsSortDirection;
    if (direction === 'DESC' || direction === '') {
      direction = 'ASC';
      dispatch(pretensionsActions.setPretensionsSortDirection('ASC'));
    } else {
      direction = 'DESC';
      dispatch(pretensionsActions.setPretensionsSortDirection('DESC'));
    }
    dispatch(pretensionsActions.setPretensionsSortColumn(column));
    dispatch(
      pretensionsActions.fetchPretensions(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.dateStart,
        pretensionsStore.dateEnd,
        pretensionsStore.searchFactureNum,
        pretensionsStore.searchPretensionStatus,
        column,
        direction,
        pretensionsStore.perPage,
        1,
      ),
    );
  };

  const changePerPage = (num) => {
    dispatch(pretensionsActions.setCurrentPage(1));
    dispatch(pretensionsActions.setPerPage(num));
  };

  const pageChange = (data) => {
    dispatch(pretensionsActions.setCurrentPage(data.selected + 1));
  };

  useEffect(() => {
    if (deliveryStore.selectCityUid !== '') {
      dispatch(
        pretensionsActions.fetchPretensions(
          configStore.isCurrentLangUa,
          deliveryStore.selectCityUid,
          pretensionsStore.dateStart,
          pretensionsStore.dateEnd,
          pretensionsStore.searchFactureNum,
          pretensionsStore.searchPretensionStatus,
          pretensionsStore.pretensionsSortColumn,
          pretensionsStore.pretensionsSortDirection,
          pretensionsStore.perPage,
          pretensionsStore.currentPage,
        ),
      )
    }
  }, [
    configStore.isCurrentLangUa,
    deliveryStore.selectCityUid,
    pretensionsStore.perPage,
    pretensionsStore.currentPage,
  ]);

  useEffect(() => {
    dispatch(menuActions.fetchMenu(configStore.isCurrentLangUa, deliveryStore.selectCityUid));
  }, [
    deliveryStore.selectCityUid,
  ]);

  return (
    <div className="pretensions-list">
      <Preloader/>
      <div className="pretensions-list__header">
        <div className="pretensions-list__header-item col-number">
          <button type="button"
                  className="pretensions-list__header-item-text"
                  onClick={() => sortPretensions('docnum')}>
            №
          </button>
        </div>
        <div className="pretensions-list__header-item col-date">
          <button type="button"
                  className="pretensions-list__header-item-text"
                  onClick={() => sortPretensions('date')}>
            Дата
          </button>
        </div>
        <div className="pretensions-list__header-item col-facture">
          <button type="button"
                  className="pretensions-list__header-item-text"
                  onClick={() => sortPretensions('invoicenum')}>
            Фактура
          </button>
        </div>
        <div className="pretensions-list__header-item col-photo">
          <div className="pretensions-list__header-item-text">
            Фото
          </div>
        </div>
        <div className="pretensions-list__header-item col-edits">
          <div className="pretensions-list__header-item-text">
            Правки
          </div>
        </div>
        <div className="pretensions-list__header-item col-answer">
          <div className="pretensions-list__header-item-text">
            {configStore.isCurrentLangUa ? headingAnswer.ua : headingAnswer.ru}
          </div>
        </div>
        <div className="pretensions-list__header-item col-sum">
          <button type="button"
                  className="pretensions-list__header-item-text"
                  onClick={() => sortPretensions('amount')}>
            {configStore.isCurrentLangUa ? headingSum.ua : headingSum.ru}
          </button>
        </div>
        <div className="pretensions-list__header-item col-updated">
          <button type="button"
                  className="pretensions-list__header-item-text"
                  onClick={() => sortPretensions('StatusDate')}>
            {configStore.isCurrentLangUa ? headingUpdated.ua : headingUpdated.ru}
          </button>
        </div>
        <div className="pretensions-list__header-item col-status">
          <button type="button"
                  className="pretensions-list__header-item-text"
                  onClick={() => sortPretensions('status')}>
            Статус
          </button>
        </div>
        <div className="pretensions-list__header-item col-btn">
          <StatusDropdown/>
        </div>
      </div>


      <ul className="pretensions-list__list">
        {listPretensions}
      </ul>
      <div className="pretensions-list__bottom-nav">
        <CountList onClick={changePerPage} currentPerPage={pretensionsStore.perPage} countOf="pretensions"/>
        <ReactPaginate
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pretensionsStore.pageCount}
          forcePage={pretensionsStore.currentPage - 1}
          containerClassName="pager"
          pageLinkClassName="pager__button"
          activeLinkClassName="pager__button--active"
          previousLinkClassName="icon-arrow pager__arrow-prev"
          nextLinkClassName="icon-arrow pager__arrow-next"
          onPageChange={pageChange}
        />
      </div>
    </div>
  );
};

export default PretensionsList;
