import React, {useState, useEffect, useRef} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import pretensionsActions from "../../store/actions/pretensions";

const StatusDropdown = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();
  const [isOpen, changeDropdownState] = useState(false);
  const activeItem = pretensionsStore.searchPretensionStatus;

  const filter = {
    ua : 'Фільтр',
    ru : 'Фильтр',
  };
  const all = {
    ua : 'Усі',
    ru : 'Все',
  };
  const active = {
    ua : 'В обробці',
    ru : 'В обработке',
  };
  const drafts = {
    ua : 'Чернетки',
    ru : 'Черновики',
  };
  const archive = {
    ua : 'Закрита',
    ru : 'Закрыта',
  }

  const rootEl = useRef(null);

  useEffect(() => {
    const onClick = e => rootEl.current.contains(e.target) || changeDropdownState(false);
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);


  const openFilter = () => {
    if (!isOpen) {
      changeDropdownState(true)
    } else {
      changeDropdownState(false)
    }
  }


  const changeFilter = (status) => {
    changeDropdownState(false);
    dispatch(pretensionsActions.setSearchPretensionStatus(status));
    dispatch(pretensionsActions.setCurrentPage(1));
    dispatch(
      pretensionsActions.fetchPretensions(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.dateStart,
        pretensionsStore.dateEnd,
        pretensionsStore.searchFactureNum,
        status,
        pretensionsStore.pretensionsSortColumn,
        pretensionsStore.pretensionsSortDirection,
        pretensionsStore.perPage,
        1
      ),
    );
  }


  return (
    <div className="filter-dropdown" ref={rootEl}>
      <button type="button"
              className="filter-dropdown__button"
              onClick={() => openFilter()}>
        <i className="icon-filter-1"/>
        <span>{configStore.isCurrentLangUa ? filter.ua : filter.ru}</span>
        <i className="icon-chevron-down-mid"/>
      </button>
      <div className={`filter-dropdown__box ${isOpen ? 'open' : ''}`}>
        <div className="filter-dropdown__list">
          <button
            className={`filter-dropdown__item ${activeItem === 'ALL' ? 'active' : ''}`}
            type="button"
            onClick={() => changeFilter('ALL')}>
            {configStore.isCurrentLangUa ? all.ua : all.ru}
          </button>
          <button
            className={`filter-dropdown__item ${activeItem === 'ACTIVE' ? 'active' : ''}`}
            type="button"
            onClick={() => changeFilter('ACTIVE')}>
            {configStore.isCurrentLangUa ? active.ua : active.ru}
          </button>
          <button
            className={`filter-dropdown__item ${activeItem === 'DRAFTS' ? 'active' : ''}`}
            type="button"
            onClick={() => changeFilter('DRAFTS')}>
            {configStore.isCurrentLangUa ? drafts.ua : drafts.ru}
          </button>
          <button
            className={`filter-dropdown__item ${activeItem === 'ARCHIVE' ? 'active' : ''}`}
            type="button"
            onClick={() => changeFilter('ARCHIVE')}>
            {configStore.isCurrentLangUa ? archive.ua : archive.ru}
          </button>
        </div>
      </div>
    </div>
  )
};

export default StatusDropdown;
