import React from 'react';
import { useSelector } from 'react-redux';

const lkText = {
  ua: 'Особистий кабінет клієнта',
  ru: 'Личный кабинет клиента',
};

const PersonalArea = () => {
  const configState = useSelector((store) => store.configStore);

  return (
    <div className="personal-area">
      <div className="personal-area__text">
        <p className="personal-area__headline">
          {configState.isCurrentLangUa ? lkText.ua : lkText.ru}
        </p>
        <p className="personal-area__user-name">{configState.userName}</p>
      </div>
    </div>
  );
};

export default PersonalArea;
