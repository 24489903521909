import certificatesTypes from '../types/certificates';
import configActions from './config';
import api from '../../api/api';
import documentsActions from "./documents";

const setCertificatesList = (array) => ({
  type: certificatesTypes.SET_CERTIFICATES_LIST,
  array,
});

const setSelectedList = (array) => ({
  type: certificatesTypes.SET_CERTIFICATE_SELECTED_LIST,
  array,
});

const toggleAllItems = (array) => ({
  type: certificatesTypes.SET_CERTIFICATE_SELECTED_LIST,
  array,
});

const removeItemSelect = (array) => ({
  type: certificatesTypes.SET_CERTIFICATE_SELECTED_LIST,
  array,
});

const setRightPreloader = (value) => ({
  type: certificatesTypes.SET_RIGHT_PRELOADER,
  value,
});

const setCertSearchErrorNum = (num) => ({
  type: certificatesTypes.SET_CERT_SEARCH_ERROR_NUM,
  num,
});

const addItemSelect = (array) => ({ type: certificatesTypes.SET_CERTIFICATE_SELECTED_LIST, array });

const checkAllItemsBool = (array1, array2) => {
  const array = array2.map((elem) => elem.uid);
  const bool =
    array.every((item) => array1.includes(item)) && array1.every((item) => array.includes(item));

  return {
    type: certificatesTypes.SET_ALL_ITEMS_CHECKED,
    bool,
  };
};

const addCheck = (uid, array, initArray) => async (dispatch) => {
  const array1 = array.map((elem) => elem);
  array1.push(uid);

  await dispatch(addItemSelect(array1));
  await dispatch(checkAllItemsBool(array1, initArray));
};

const removeCheck = (uid, array, initArray) => async (dispatch) => {
  const array1 = array.map((elem) => elem);
  const index = array1.indexOf(uid);
  if (index > -1) {
    array1.splice(index, 1);
  }

  await dispatch(removeItemSelect(array1));
  await dispatch(checkAllItemsBool(array1, initArray));
};

const toggleAllCertificates = (array1, array2) => async (dispatch) => {
  await dispatch(toggleAllItems(array1));
  dispatch(checkAllItemsBool(array1, array2));
};

const downloadAllCertificates = (uid, selectedNum) => async (dispatch) => {
  const dataCertificate = new FormData();

  dataCertificate.append('addr_uid', uid);
  dataCertificate.append('InvoiceNum', selectedNum);

  const responseCertificate = await api.post('ua/api/react/certificates', dataCertificate);

  if (responseCertificate.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  const arrayList = responseCertificate.data.result.list.map((elem) => elem.uid);

  const data = new FormData();

  data.append('addr_uid', uid);
  data.append('SeriesList', JSON.stringify(arrayList));

  const response = await api.post('ua/api/react/certificatefile', data, {
    responseType: 'blob',
  });

  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', 'file.zip');
  document.body.appendChild(link);
  link.click();
  link.remove();
  dispatch(documentsActions.setPreloader(false));
  return null;
};

const downloadCertificates = (uid, array) => async (dispatch) => {
  dispatch(setRightPreloader(true));
  const data = new FormData();

  data.append('addr_uid', uid);
  data.append('SeriesList', JSON.stringify(array));

  const response = await api.post('ua/api/react/certificatefile', data, {
    responseType: 'blob',
  });

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', 'file.zip');
  document.body.appendChild(link);
  link.click();
  link.remove();

  dispatch(setSelectedList([]));
  dispatch(checkAllItemsBool([], [1]));
  dispatch(setRightPreloader(false));
  return null;
};

const fetchCertificates = (lang, uid, selectedNum, dateRaw) => async (dispatch) => {
  await dispatch(setCertSearchErrorNum(0));
  dispatch(setRightPreloader(true));
  const data = new FormData();

  data.append('addr_uid', uid);
  data.append('InvoiceNum', selectedNum);

  if (dateRaw) {
    let formatedDate;
    if (typeof dateRaw === 'object') {
      formatedDate = `${dateRaw.getDate()}-${dateRaw.getMonth() + 1}-${dateRaw.getFullYear()}`
    } else {
      const dArr = dateRaw.split("-");
      formatedDate = `${dArr[2] }-${ dArr[1] }-${ dArr[0]}`;
    }
    data.append('date', formatedDate);
  }


  const response = await api.post(`${lang ? 'ua' : ''}/api/react/certificates`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error) {
    await dispatch(setCertificatesList([]));
    await dispatch(setSelectedList([]));
    await dispatch(checkAllItemsBool([], []));
  }else{
    await dispatch(setCertificatesList(response.data.result.list));
    await dispatch(setSelectedList([]));
    await dispatch(checkAllItemsBool([], response.data.result.list));
  }

  dispatch(setRightPreloader(false));
  return null;
};

const searchCertificatesOld = (lang, uid, selectedNum, dateRaw) => async (dispatch) => {
  dispatch(setRightPreloader(true));
  const data = new FormData();

  data.append('addr_uid', uid);
  data.append('InvoiceNum', selectedNum);

  if (dateRaw) {
    let formatedDate;
    if (typeof dateRaw === 'object') {
      formatedDate = `${dateRaw.getDate()}-${dateRaw.getMonth() + 1}-${dateRaw.getFullYear()}`
    } else {
      const dArr = dateRaw.split("-");
      formatedDate = `${dArr[2] }-${ dArr[1] }-${ dArr[0]}`;
    }
    data.append('date', formatedDate);
  }


  const response = await api.post(`${lang ? 'ua' : ''}/api/react/certificates`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error) {
    await dispatch(setCertSearchErrorNum(1));
    await dispatch(setCertificatesList([]));
    await dispatch(setSelectedList([]));
    await dispatch(checkAllItemsBool([], []));
  }else{
    await dispatch(setCertSearchErrorNum(0));
    await dispatch(setCertificatesList(response.data.result.list));
    await dispatch(setSelectedList([]));
    await dispatch(checkAllItemsBool([], response.data.result.list));
  }

  dispatch(setRightPreloader(false));
  return null;
}

export default {
  setCertificatesList,
  setSelectedList,
  fetchCertificates,
  downloadAllCertificates,
  toggleAllCertificates,
  toggleAllItems,
  removeItemSelect,
  addItemSelect,
  removeCheck,
  addCheck,
  downloadCertificates,
  checkAllItemsBool,
  setRightPreloader,
  searchCertificatesOld,
};
