import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import mailsActions from '../../store/actions/mails';

const title = {
  ru: 'Отзывы и Браки, инструкция VentaLTD',
  ua: 'Відгуки та Браки, інструкція VentaLTD',
};
const button = {
  ru: 'Назад',
  ua: 'Назад',
};

const MailsInfo = () => {
  const mailsStore = useSelector((state) => state.mailsStore);
  const dispatch = useDispatch();
  const configStore = useSelector((store) => store.configStore);
  const history = useHistory();

  useEffect(() => {
    dispatch(mailsActions.fetchInfoPage(configStore.isCurrentLangUa));
  }, [configStore.isCurrentLangUa]);

  return (
    <>
      <Helmet>
        <title>{configStore.isCurrentLangUa ? title.ua : title.ru}</title>
      </Helmet>
      <div className="certificates-info">
        {/* eslint-disable-next-line react/button-has-type */}
        <button className="button-back" onClick={() => history.goBack()}>
          {configStore.isCurrentLangUa ? button.ua : button.ru}
        </button>
        <h1 className="certificates-info__headline">{mailsStore.infoPageTitle}</h1>
        <div dangerouslySetInnerHTML={{ __html: mailsStore.infoPage }} />
      </div>
    </>
  );
};

export default MailsInfo;
