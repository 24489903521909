import React from 'react';
import { useSelector } from 'react-redux';

const CountList = (props) => {
  const configStore = useSelector((store) => store.configStore);

  // eslint-disable-next-line
  const { currentPerPage, onClick, countOf } = props;
  let countText = {}
  if (countOf === 'factures') {
    countText = {
      ua: 'Показувати фактури по:',
      ru: 'Показывать фактуры по:',
    };
  } else if (countOf === 'pretensions') {
    countText = {
      ua: 'Показувати претензії по:',
      ru: 'Показывать претензии по:',
    };
  } else if (countOf === 'products') {
    countText = {
      ua: 'Показувати позиції по:',
      ru: 'Показывать позиции по:',
    };
  }


  return (
    <div className="count-list">
      <p className="count-list__text">
        {configStore.isCurrentLangUa ? countText.ua : countText.ru}
      </p>
      <button
        onClick={() => onClick(10)}
        type="button"
        className={`count-list__button ${
          currentPerPage === 10 ? 'count-list__button--active' : ''
        }`}
      >
        По 10
      </button>
      <button
        onClick={() => onClick(20)}
        type="button"
        className={`count-list__button ${
          currentPerPage === 20 ? 'count-list__button--active' : ''
        }`}
      >
        По 20
      </button>
    </div>
  );
};

export default CountList;
