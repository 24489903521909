import menuTypes from '../types/menu';

const initialStore = {
  menuList: [],
};


const menuStore = (state = initialStore, action) => {
  switch (action.type) {
    case menuTypes.SET_MENU_LIST:
      return {
        ...state,
        menuList: action.array,
      };
    default:
      return state;
  }
};

export default menuStore;
