import React, {useState} from 'react';

const ImageItem = (props) => {
  const { image, onClick } = props;
  const [file, setFile] = useState(null);
  const reader = new FileReader();

  reader.onload = function(e) {
    setFile(e.target.result);
  };

  reader.readAsDataURL(image);

  return (
    <div className="image-item">
      <button type="button" className="image-item__remove" onClick={onClick}>
        <i className="icon-close-thin"/>
      </button>
      <img src={file} alt=""/>
    </div>
  );
};

export default ImageItem;
