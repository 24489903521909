import React from 'react';
import { useSelector } from 'react-redux';
import fenixSvg from '../../accets/images/fenix.svg';

const copy = {
  ua: 'Всі права захищені © 2021 ТОВ «Вента. ЛТД »',
  ru: 'Все права защищены © 2021 ООО «Вента. ЛТД »',
};
const dev = {
  ua: 'Розробка сайту:',
  ru: 'Разработка сайта:',
};

const Footer = () => {
  const configStore = useSelector((store) => store.configStore);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__container">
          <p>{configStore.isCurrentLangUa ? copy.ua : copy.ru}</p>
          <a rel="noreferrer" href="https://fnx.dp.ua/ua" target="_blank">
            <span>{configStore.isCurrentLangUa ? dev.ua : dev.ru}</span>
            <img src={fenixSvg} alt="fenix icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
