const SET_HEADLINE = 'SET_HEADLINE';
const SET_UTP = 'SET_UTP';
const SET_FORM_HEADLINE = 'SET_FORM_HEADLINE';
const SET_FORM_TEXT = 'SET_FORM_TEXT';
const SET_BOTTOM_TEXT = 'SET_BOTTOM_TEXT';
const SET_REMEMBER_CHECK = 'SET_REMEMBER_CHECK';
const SET_FORM_ERROR = 'SET_FORM_ERROR';
const SET_LOGIN_VALUE = 'SET_LOGIN_VALUE';
const SET_LOGIN_PRELOADER = 'SET_LOGIN_PRELOADER';
const SET_PASSWORD_VALUE = 'SET_PASSWORD_VALUE';

const combineConst = {
  SET_HEADLINE,
  SET_UTP,
  SET_BOTTOM_TEXT,
  SET_FORM_HEADLINE,
  SET_FORM_TEXT,
  SET_LOGIN_PRELOADER,
  SET_LOGIN_VALUE,
  SET_FORM_ERROR,
  SET_PASSWORD_VALUE,
  SET_REMEMBER_CHECK,
};

export default combineConst;
