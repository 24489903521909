import mailsTypes from "../types/mails";
import api from "../../api/api";
import configActions from "./config";


const setMailsError = (str) => ({
  type : mailsTypes.SET_MAILS_ERROR,
  str,
});
const setSearchNum = (str) => ({
  type : mailsTypes.SET_SEARCH_NUM,
  str,
});
const setDateStart = (str) => ({
  type : mailsTypes.SET_DATE_START,
  str,
});
const setDateEnd = (str) => ({
  type : mailsTypes.SET_DATE_END,
  str,
});
const setDateStartDisplay = (str) => ({
  type : mailsTypes.SET_DATE_START_DISPLAY,
  str,
});
const setDateEndDisplay = (str) => ({
  type : mailsTypes.SET_DATE_END_DISPLAY,
  str,
});
const setMailsList = (arr) => ({
  type : mailsTypes.SET_MAILS_LIST,
  arr,
});
const setMailsDownloadList = (arr) => ({
  type : mailsTypes.SET_MAILS_DOWNLOAD_LIST,
  arr,
});
const setMailsPreloader = (bool) => ({
  type : mailsTypes.SET_MAILS_PRELOADER,
  bool,
});
const setMailsType = (str) => ({
  type : mailsTypes.SET_MAILS_TYPE,
  str,
});
const setMailsOnlyNew = (num) => ({
  type : mailsTypes.SET_MAILS_ONLY_NEW,
  num,
});
const setMailsSortColumn = (str) => ({
  type : mailsTypes.SET_MAILS_SORT_COLUMN,
  str,
});
const setMailsSortDirection = (str) => ({
  type : mailsTypes.SET_MAILS_SORT_DIRECTION,
  str,
});
const setMailsPerPage = (num) => ({
  type : mailsTypes.SET_MAILS_PER_PAGE,
  num,
});
const setMailsPage = (num) => ({
  type : mailsTypes.SET_MAILS_PAGE,
  num,
});
const setMailsPageCount = (num) => ({
  type : mailsTypes.SET_MAILS_PAGE_COUNT,
  num,
});
const setMailsTotalCount = (num) => ({
  type : mailsTypes.SET_MAILS_TOTAL_COUNT,
  num,
});
const setCurrentMailUid = (str) => ({
  type : mailsTypes.SET_CURRENT_MAIL_UID,
  str,
});
const setFacturesPreloader = (bool) => ({
  type : mailsTypes.SET_FACTURES_PRELOADER,
  bool,
});
const setFacturesList = (arr) => ({
  type : mailsTypes.SET_MAIL_FACTURES_LIST,
  arr,
});
const setFacturesSortColumn = (str) => ({
  type : mailsTypes.SET_FACTURES_SORT_COLUMN,
  str,
});
const setFacturesSortDirection = (str) => ({
  type : mailsTypes.SET_FACTURES_SORT_DIRECTION,
  str,
});
const setFacturesPerPage = (num) => ({
  type : mailsTypes.SET_FACTURES_PER_PAGE,
  num,
});
const setFacturesPage = (num) => ({
  type : mailsTypes.SET_FACTURES_PAGE,
  num,
});
const setFacturesPageCount = (num) => ({
  type : mailsTypes.SET_FACTURES_PAGE_COUNT,
  num,
});
const setFacturesTotalCount = (num) => ({
  type : mailsTypes.SET_FACTURES_TOTAL_COUNT,
  num,
});
const setInfoPage = (str) => ({
  type : mailsTypes.SET_INFO_PAGE,
  str,
});
const setInfoPageTitle = (str) => ({
  type : mailsTypes.SET_INFO_PAGE_TITLE,
  str,
});
const setInfo = (str) => ({
  type : mailsTypes.SET_INFO,
  str,
});


const checkAllItemsBool = (array1, array2) => {
  const array = array2.map((elem) => elem.uid);
  const bool =
    array.every((item) => array1.includes(item)) && array1.every((item) => array.includes(item));

  return {
    type: mailsTypes.SET_ALL_CHECKED,
    bool,
  };
};

const addCheck = (uid, array, initArray) => async (dispatch) => {
  const array1 = array.map((elem) => elem);
  array1.push(uid);
  await dispatch(setMailsDownloadList(array1));
  await dispatch(checkAllItemsBool(array1, initArray));
};

const removeCheck = (uid, array, initArray) => async (dispatch) => {
  const array1 = array.map((elem) => elem);
  const index = array1.indexOf(uid);
  if (index > -1) {
    array1.splice(index, 1);
  }

  await dispatch(setMailsDownloadList(array1));
  await dispatch(checkAllItemsBool(array1, initArray));
};

const toggleAllMails = (array1, array2) => async (dispatch) => {
  await dispatch(setMailsDownloadList(array1));
  dispatch(checkAllItemsBool(array1, array2));
};


const downloadMails = (uid) => async (dispatch) => {
  dispatch(setMailsPreloader(true));
  const data = new FormData();

  data.append('ObjectUID', uid);
  data.append('type', 'BRAKLETTER');

  const response = await api.post('ua/api/react/documentfiles', data, {
    responseType : 'blob',
  });

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link        = document.createElement('a');
  link.href         = downloadUrl;
  link.setAttribute('download', 'file.pdf');
  document.body.appendChild(link);
  link.click();
  link.remove();
  dispatch(setMailsPreloader(false));
  return null;
};


const fetchMails = (
  lang,
  AutoDestUid,
  dateStart,
  dateEnd,
  searchNum,
  StatusType,
  SortColumn,
  SortDirection,
  perPage,
  page,
  onlyNew,
) => async (dispatch) => {
  dispatch(setMailsPreloader(true));
  // dispatch(setMailsError(''));
  const data = new FormData();

  let dateStartChecked = dateStart;
  let dateEndChecked = dateEnd;

  if (dateStartChecked === '' || !dateStartChecked) {
    dateStartChecked = new Date();
    dateStartChecked.setMonth(dateStartChecked.getMonth() - 3);
    dispatch(setDateStart(dateStartChecked));
  }
  if (dateEndChecked === '' || !dateEndChecked) {
    dateEndChecked = new Date();
    dispatch(setDateEnd(dateEndChecked));
  }

  let dayStart = dateStartChecked.getDate();
  let monthStart = dateStartChecked.getMonth() + 1;
  const yearStart = dateStartChecked.getFullYear();

  let dayEnd = dateEndChecked.getDate();
  let monthEnd = dateEndChecked.getMonth() + 1;
  const yearEnd = dateEndChecked.getFullYear();

  if (dayStart < 10) {
    dayStart = `0${dayStart}`;
  }
  if (monthStart < 10) {
    monthStart = `0${monthStart}`;
  }
  if (dayEnd < 10) {
    dayEnd = `0${dayEnd}`;
  }
  if (monthEnd < 10) {
    monthEnd = `0${monthEnd}`;
  }

  dateStartChecked = `${yearStart}-${monthStart}-${dayStart}`;
  dateEndChecked = `${yearEnd}-${monthEnd}-${dayEnd}`;

  dispatch(setDateStartDisplay(`${dayStart}.${monthStart}.${yearStart}`));
  dispatch(setDateEndDisplay(`${dayEnd}.${monthEnd}.${yearEnd}`));

  data.append('DateStart', dateStartChecked);
  data.append('DateEnd', dateEndChecked);
  data.append('AutoDestUID', AutoDestUid);
  // data.append('LetterType', StatusType);
  //
  if (searchNum && searchNum.length < 3) {
    dispatch(setMailsError('short_search_num'));
  } else if (searchNum) {
    data.append('InvoiceNum', searchNum);
  }
  if (SortColumn && SortColumn !== '') {
    data.append('SortColumn', SortColumn);
  }
  if (SortDirection && SortDirection !== '') {
    data.append('SortDirection', SortDirection);
  }
  if (perPage) {
    data.append('PageSize', perPage);
  }
  if (page) {
    data.append('page', page);
  }
  data.append('OnlyNew', onlyNew);
  const response = await api.post(`${lang ? 'ua' : ''}/api/react/mails/letters`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error || response.data.result === undefined) {
    await dispatch(setMailsList([]));
    await dispatch(setMailsDownloadList([]));
    await dispatch(checkAllItemsBool([], [1]));
    await dispatch(setMailsPage(1));
    await dispatch(setMailsPageCount(1));
    await dispatch(setMailsTotalCount(0));
    dispatch(setMailsPreloader(false));
    return null;
  }

  await dispatch(setMailsList(response.data.result.list));
  await dispatch(setMailsDownloadList([]));
  await dispatch(checkAllItemsBool([], response.data.result.list));
  await dispatch(setMailsPage(response.data.result.page));
  await dispatch(setMailsPageCount(response.data.result.total_pages));
  await dispatch(setMailsTotalCount(response.data.result.total_count));
  dispatch(setMailsPreloader(false));
  return null;
};

const fetchMailFactures = (
  lang,
  mailUid,
  SortColumn,
  SortDirection,
  perPage,
  page,
) => async (dispatch) => {
  dispatch(setFacturesPreloader(true));
  // dispatch(setMailsError(''));
  const data = new FormData();

  if (mailUid) {
    data.append('BrakLetterUID', mailUid);
  }
  if (SortColumn && SortColumn !== '') {
    data.append('SortColumn', SortColumn);
  }
  if (SortDirection && SortDirection !== '') {
    data.append('SortDirection', SortDirection);
  }
  if (perPage) {
    data.append('PageSize', perPage);
  }
  if (page) {
    data.append('page', page);
  }
  const response = await api.post(`${lang ? 'ua' : ''}/api/react/mails/letter`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error || response.data.result === undefined) {
    await dispatch(setFacturesList([]));
    await dispatch(setFacturesPage(1));
    await dispatch(setFacturesPageCount(1));
    await dispatch(setFacturesTotalCount(0));
    dispatch(setFacturesPreloader(false));
    return null;
  }

  await dispatch(setFacturesList(response.data.result.list));
  await dispatch(setFacturesPage(response.data.result.page));
  await dispatch(setFacturesPageCount(response.data.result.total_pages));
  await dispatch(setFacturesTotalCount(response.data.result.total_count));
  dispatch(setFacturesPreloader(false));
  return null;
};

const fetchInfo = (lang) => async (dispatch) => {
  const data = new FormData();
  data.append('sys_title', 'mailsinfo');
  const response = await api.post(`${lang ? 'ua' : ''}/api/react/getblock`, data);
  await dispatch(setInfo(response.data.result.block.content));
};

const fetchInfoPage = (lang) => async (dispatch) => {
  const data = new FormData();

  data.append('url_key', 'mails-help');

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/getpage`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  dispatch(setInfoPage(response.data.structure.content));
  dispatch(setInfoPageTitle(response.data.structure.title));
  return null;
};

export default {
  downloadMails,
  addCheck,
  removeCheck,
  toggleAllMails,
  setMailsError,
  setSearchNum,
  setDateStart,
  setDateEnd,
  setDateStartDisplay,
  setDateEndDisplay,
  setMailsList,
  setMailsDownloadList,
  setMailsPreloader,
  setMailsType,
  setMailsOnlyNew,
  setMailsSortColumn,
  setMailsSortDirection,
  setMailsPerPage,
  setMailsPage,
  setMailsPageCount,
  setMailsTotalCount,
  setCurrentMailUid,
  setFacturesPreloader,
  setFacturesList,
  setFacturesSortColumn,
  setFacturesSortDirection,
  setFacturesPerPage,
  setFacturesPage,
  setFacturesPageCount,
  setInfoPage,
  setInfoPageTitle,
  fetchMails,
  fetchMailFactures,
  fetchInfo,
  fetchInfoPage,
}
