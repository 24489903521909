import documentsTypes from '../types/documents';

const initialStore = {
  documentsList: [],
  currentPage: 1,
  perPage: 10,
  invoiceNum: '',
  pageCount: 0,
  searchFactureNum: '',
  rightHeadline: '',
  rightCheckbox: false,
  rightContent: '',
  infoPage: '',
  rightCheckboxError: false,
  infoPageTitle: '',
  searchErrorNum: 0,
  preloader: false,
  documentsSortColumn: '',
  documentsSortDirection: '',
};

const documentsStore = (state = initialStore, action) => {
  switch (action.type) {
    case documentsTypes.SET_DOCUMENTS_LIST:
      return {
        ...state,
        documentsList: action.array,
      };
    case documentsTypes.SET_RIGHT_CHECKBOX:
      return {
        ...state,
        rightCheckbox: action.bool,
      };
    case documentsTypes.SET_RIGHT_CHECKBOX_ERROR:
      return {
        ...state,
        rightCheckboxError: action.bool,
      };
    case documentsTypes.SET_DOCUMENTS_RIGHT_CONTENT:
      return {
        ...state,
        rightContent: action.text,
      };
    case documentsTypes.SET_DOCUMENTS_RIGHT_HEADLINE:
      return {
        ...state,
        rightHeadline: action.text,
      };
    case documentsTypes.SET_SEARCH_NUM:
      return {
        ...state,
        searchFactureNum: action.num,
      };
    case documentsTypes.SET_INFO_PAGE_TITLE:
      return {
        ...state,
        infoPageTitle: action.title,
      };
    case documentsTypes.SET_INFO_PAGE:
      return {
        ...state,
        infoPage: action.str,
      };
    case documentsTypes.SET_PER_PAGE:
      return {
        ...state,
        perPage: action.num,
      };
    case documentsTypes.SET_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.num,
      };
    case documentsTypes.SET_INVOICE_NUM:
      return {
        ...state,
        invoiceNum: action.num,
      };
    case documentsTypes.SET_PAGE:
      return {
        ...state,
        currentPage: action.num,
      };
    case documentsTypes.SET_SEARCH_ERROR_NUM:
      return {
        ...state,
        searchErrorNum: action.num,
      };
    case documentsTypes.SET_PRELOADER:
      return {
        ...state,
        preloader: action.value,
      };
    case documentsTypes.SET_DOCUMENTS_SORT_COL:
      return {
        ...state,
        documentsSortColumn: action.value,
      };
    case documentsTypes.SET_DOCUMENTS_SORT_DIR:
      return {
        ...state,
        documentsSortDirection: action.value,
      };
    default:
      return state;
  }
};

export default documentsStore;
