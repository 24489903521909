import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mailsActions from "../../store/actions/mails";


const MailsRight = () => {
  const mailsStore = useSelector((store) => store.mailsStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mailsActions.fetchInfo(configStore.isCurrentLangUa));
  }, [configStore.isCurrentLangUa]);

  return (

    <div className="mails-right">
      <div className="mails-right__content">
        <div dangerouslySetInnerHTML={{ __html: mailsStore.info }} />
      </div>
    </div>
  );
};

export default MailsRight;
