import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from "react-router";
import documentsActions from '../../store/actions/documents';

const title = {
  ru: 'Документы инструкция VentaLTD',
  ua: 'Документи інструкція VentaLTD',
};
const button = {
    ru: 'Назад',
    ua: 'Назад',
};

const DocumentsInfo = () => {
  const documentsStore = useSelector((state) => state.documentsStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(documentsActions.fetchDocumentsInfo(configStore.isCurrentLangUa));
  }, [configStore.isCurrentLangUa]);

  return (
    <>
      <Helmet>
        <title>{configStore.isCurrentLangUa ? title.ua : title.ru}</title>
      </Helmet>
      <div className="certificates-info">
          {/* eslint-disable-next-line react/button-has-type */}
          <button className="button-back" onClick={() => history.goBack()}>
              {configStore.isCurrentLangUa ? button.ua : button.ru}
          </button>
        <h1 className="certificates-info__headline">{documentsStore.infoPageTitle}</h1>
        <div dangerouslySetInnerHTML={{ __html: documentsStore.infoPage }} />
      </div>
    </>
  );
};

export default DocumentsInfo;
