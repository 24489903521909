import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../Checkbox/Checkbox';
import loginActions from '../../store/actions/login';
import LoaderForm from './LoaderForm';

const rememberMeText = {
  ua: "Запам'ятати мене",
  ru: 'Запомнить меня',
};
const enterText = {
  ua: 'Увійти до кабінету',
  ru: 'Войти в кабинет',
};
const loginText = {
  ua: 'Ваш логин',
  ru: 'Ваш логін',
};

const LoginAuth = () => {
  const loginStore = useSelector((store) => store.loginStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();

  const changeRememberCheck = () => {
    dispatch(loginActions.setRememberCheck(loginStore.isRememberChecked));
  };

  const loginHandler = (event) => {
    dispatch(loginActions.setLoginValue(event.target.value));
  };

  const passwordHandler = (event) => {
    dispatch(loginActions.setPasswordValue(event.target.value));
  };

  const submitLoginForm = (event) => {
    event.preventDefault();

    dispatch(loginActions.submitForm(loginStore.formLogin, loginStore.formPassword));
  };

  return (
    <div className="login__right">
      <p className="login__right-headline">{loginStore.formHeadline}</p>
      {/* eslint-disable-next-line */}
      <p className="login__right-text" dangerouslySetInnerHTML={{ __html: loginStore.formText }} />
      <form onSubmit={submitLoginForm} className="login__right-form">
        <label htmlFor="loginInput" className="login__form-label">
          Логин:
          <input
            id="loginInput"
            className={`login__form-input ${
              loginStore.formError !== '' ? 'login__form-input--error' : ''
            }`}
            type="text"
            value={loginStore.formLogin}
            onChange={loginHandler}
            placeholder={configStore.isCurrentLangUa ? loginText.ua : loginText.ru}
          />
        </label>
        <label htmlFor="loginPassword" className="login__form-label">
          Пароль:
          <input
            id="loginPassword"
            className={`login__form-input ${
              loginStore.formError !== '' ? 'login__form-input--error' : ''
            }`}
            type="password"
            value={loginStore.formPassword}
            onChange={passwordHandler}
            placeholder="Ваш пароль"
          />
        </label>
        <p className="login__error-text">{loginStore.formError}</p>
        <div className="login__remember">
          <Checkbox
            id="loginCheckbox"
            checkedState={loginStore.isRememberChecked}
            checkedAction={() => changeRememberCheck()}
            text={configStore.isCurrentLangUa ? rememberMeText.ua : rememberMeText.ru}
          />
        </div>
        <input
          className="login__submit-form"
          type="submit"
          value={configStore.isCurrentLangUa ? enterText.ua : enterText.ru}
        />
      </form>
      <LoaderForm />
    </div>
  );
};

export default LoginAuth;
