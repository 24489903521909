import pretensionsTypes from "../types/pretensions";
import api from "../../api/api";
import configActions from "./config";
import certificatesActions from "./certificates";
import documentsTypes from "../types/documents";
import pretensionsStore from "../reducer/pretensions";


const setPretensionsList = (array) => ({
  type : pretensionsTypes.SET_PRETENSIONS_LIST,
  array,
});

const setFacturesList = (array) => ({
  type : pretensionsTypes.SET_FACTURES_LIST,
  array,
});

const setProductsList = (array) => ({
  type : pretensionsTypes.SET_PRODUCTS_LIST,
  array,
});

const setEditedList = (array) => ({
  type : pretensionsTypes.SET_PRODUCTS_EDITED_LIST,
  array,
});

const setImagesList = (array) => ({
  type : pretensionsTypes.SET_IMAGES_LIST,
  array,
});

const setImagesAdminList = (array) => ({
  type : pretensionsTypes.SET_IMAGES_ADMIN_LIST,
  array,
});

const setImagesClientList = (array) => ({
  type : pretensionsTypes.SET_IMAGES_CLIENT_LIST,
  array,
});

const setPretensionComment = (text) => ({
  type : pretensionsTypes.SET_PRETENSION_COMMENT,
  text,
});

const setPretensionsSection = (number) => ({
  type : pretensionsTypes.SET_PRETENSIONS_SECTION,
  number,
});

const setPretensionsStep = (number) => ({
  type : pretensionsTypes.SET_PRETENSIONS_STEP,
  number,
});

const setPretensionFactureNum = (number) => ({
  type : pretensionsTypes.SET_PRETENSION_FACTURE_NUM,
  number,
});

const setPretensionFactureDate = (date) => ({
  type : pretensionsTypes.SET_PRETENSION_FACTURE_DATE,
  date,
});

const setPretensionFactureUid = (uid) => ({
  type : pretensionsTypes.SET_PRETENSION_FACTURE_UID,
  uid,
});

const setCurrentPretensionUid = (uid) => ({
  type : pretensionsTypes.SET_CURRENT_PRETENSION_UID,
  uid,
});

const setCurrentPretension = (object) => ({
  type : pretensionsTypes.SET_CURRENT_PRETENSION,
  object,
});

const setCurrentPretensionDialog = (list) => ({
  type : pretensionsTypes.SET_CURRENT_PRETENSION_DIALOG,
  list,
});

const setCurrentPretensionMessage = (text) => ({
  type : pretensionsTypes.SET_CURRENT_PRETENSION_MESSAGE,
  text,
});

const setDateStart = (text) => ({
  type : pretensionsTypes.SET_SEARCH_DATE_START,
  text,
});

const setDateEnd = (text) => ({
  type : pretensionsTypes.SET_SEARCH_DATE_END,
  text,
});

const setSearchFactureNum = (text) => ({
  type : pretensionsTypes.SET_SEARCH_FACTURE_NUM,
  text,
});

const setSearchPretensionStatus = (text) => ({
  type : pretensionsTypes.SET_SEARCH_STATUS,
  text,
});

const setPretensionsSortDirection = (text) => ({
  type : pretensionsTypes.SET_PRETENSIONS_SORT_DIRECTION,
  text,
});

const setPretensionsSortColumn = (text) => ({
  type : pretensionsTypes.SET_PRETENSIONS_SORT_COLUMN,
  text,
});

const setErrorNum = (number) => ({
  type : pretensionsTypes.SET_ERROR_NUM,
  number,
});

const setPreloader = (value) => ({
  type : pretensionsTypes.SET_PRELOADER,
  value,
});

const setListPreloader = (value) => ({
  type : pretensionsTypes.SET_LIST_PRELOADER,
  value,
});

const setCurrentPage = (num) => ({
  type : pretensionsTypes.SET_PAGE,
  num,
});

const setPageCount = (num) => ({
  type : pretensionsTypes.SET_PAGE_COUNT,
  num,
});

const setPerPage = (num) => ({
  type : pretensionsTypes.SET_PER_PAGE,
  num,
});

const setTabs = (num) => ({
  type : pretensionsTypes.SET_TABS,
  num,
});

const setProductsSearchName = (text) => ({
  type : pretensionsTypes.SET_PRODUCTS_SEARCH_NAME,
  text,
});

const setProductsSortDirection = (text) => ({
  type : pretensionsTypes.SET_PRODUCTS_SORT_DIRECTION,
  text,
});

const setProductsSortColumn = (text) => ({
  type : pretensionsTypes.SET_PRODUCTS_SORT_COLUMN,
  text,
});

const setProductsPerPage = (num) => ({
  type : pretensionsTypes.SET_PRODUCTS_PER_PAGE,
  num,
});

const setProductsPageCount = (num) => ({
  type : pretensionsTypes.SET_PRODUCTS_PAGE_COUNT,
  num,
});

const setProductsPage = (num) => ({
  type : pretensionsTypes.SET_PRODUCTS_PAGE,
  num,
});

const setPretensionsError = (text) => ({
  type : pretensionsTypes.SET_PRETENSIONS_ERROR,
  text,
});

const setPretensionsInfoColumn = (text) => ({
  type : pretensionsTypes.SET_PRETENSIONS_INFO_COLUMN,
  text,
});

const setInfoPage = (str) => ({
  type : pretensionsTypes.SET_INFO_PAGE,
  str,
});

const setInfoPageTitle = (title) => ({
  type : pretensionsTypes.SET_INFO_PAGE_TITLE,
  title,
});

const setTotalImagesClient = (array) => ({
  type : pretensionsTypes.SET_TOTAL_IMAGES_CLIENT,
  array,
});
const fetchPretensionsInfo = (lang) => async (dispatch) => {
  const data = new FormData();

  data.append('url_key', 'instruction-pretensions');

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/getpage`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  dispatch(setInfoPage(response.data.structure.content));
  dispatch(setInfoPageTitle(response.data.structure.title));
  return null;
};

const fetchPretensions = (
  lang,
  AutoDestUid,
  dateStart,
  dateEnd,
  searchFactureNum,
  StatusType,
  SortColumn,
  SortDirection,
  perPage,
  page,
) => async (dispatch) => {
  dispatch(setPreloader(true));
  dispatch(setErrorNum(0));
  const data = new FormData();

  let dateStartChecked = dateStart;
  let dateEndChecked = dateEnd;
  if (dateStartChecked === '' || !dateStartChecked) {
    dateStartChecked = new Date();
    dateStartChecked.setMonth(dateStartChecked.getMonth() - 3);
  }
  if (dateEndChecked === '' || !dateEndChecked) {
    dateEndChecked = new Date();
  }
  const yearStart = dateStartChecked.getFullYear();
  const monthStart = dateStartChecked.getMonth() + 1;
  const dayStart = dateStartChecked.getDate();
  dateStartChecked = `${yearStart}-${monthStart}-${dayStart}`;
  const yearEnd = dateEndChecked.getFullYear();
  const monthEnd = dateEndChecked.getMonth() + 1;
  const dayEnd = dateEndChecked.getDate();
  dateEndChecked = `${yearEnd}-${monthEnd}-${dayEnd}`;

  data.append('DateStart', dateStartChecked);
  data.append('DateEnd', dateEndChecked);
  data.append('AutoDestUID', AutoDestUid);
  data.append('StatusType', StatusType);

  if (searchFactureNum && searchFactureNum.length < 3) {
    dispatch(setPretensionsError('short_search_facture_num'));
  } else if (searchFactureNum) {
    data.append('InvoiceNum', searchFactureNum);
  }
  if (SortColumn && SortColumn !== '') {
    data.append('SortColumn', SortColumn);
  }
  if (SortDirection && SortDirection !== '') {
    data.append('SortDirection', SortDirection);
  }
  if (perPage) {
    data.append('per_page', perPage);
  }
  if (page) {
    data.append('page', page);
  }
  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/pretensylist`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error || response.data.result === undefined) {
    await dispatch(setPretensionsList([]));
    dispatch(setErrorNum(1));
    dispatch(setPreloader(false));
    console.log(response.data.error_code);
    return null;
  }

  await dispatch(setPretensionsList(response.data.result.list));
  await dispatch(setPageCount(response.data.result.total_pages));
  dispatch(setPreloader(false));
  return null;
};

const fetchFactures = (
  lang,
  autoDestUid,
  searchFactureNum,
  SortColumn,
  SortDirection,
  perPage,
  page,
  ) => async (dispatch) => {
  dispatch(setPreloader(true));
  dispatch(setPretensionsError(''));
  const data = new FormData();

  data.append('AutoDestUID', autoDestUid);
  if (searchFactureNum) {
    if (searchFactureNum.length < 3) {
      dispatch(setPretensionsError('short_facture_num'));
    } else {
      data.append('DocNum', searchFactureNum);
    }
  }
  if (SortColumn && SortColumn !== '') {
    data.append('SortColumn', SortColumn);
  }
  if (SortDirection && SortDirection !== '') {
    data.append('SortDirection', SortDirection);
  }
  if (perPage) {
    data.append('per_page', perPage);
  }
  if (page) {
    data.append('page', page);
  }

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/factures`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error) {
    await dispatch(setFacturesList([]));
    dispatch(setPretensionsError('no such facture'));
    dispatch(setPreloader(false));
    console.log(response.data.error_code)
    return null;
  }

  await dispatch(setFacturesList(response.data.result.list));
  await dispatch(setPageCount(response.data.result.total_pages));
  dispatch(setPreloader(false));
  return null;
};


const fetchPretensionsInfoColumn = (lang) => async (dispatch) => {
  const data = new FormData();

  data.append('url_key', 'pretension-info-col');

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/getpage`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  dispatch(setPretensionsInfoColumn(response.data.structure.content));
  return null;
};


const fetchProducts = (
  lang, // required
  autoDestUid, // required
  invoiceUid, // required
  editedList,
  searchProductName,
  productsSortColumn,
  productsSortDirection,
  productsPerPage,
  productsPageCurrent,
  tabs,
  ) => async (dispatch) => {
  dispatch(setPreloader(true));
  dispatch(setListPreloader(true));
  const data = new FormData();

  let pageCount = 0;

  data.append('AutoDestUID', autoDestUid);
  data.append('InvoiceUID', invoiceUid);
  if (searchProductName) {
    data.append('GoodName', searchProductName);
  }
  if (productsSortColumn && productsSortColumn !== '') {
    data.append('SortColumn', productsSortColumn);
  }
  if (productsSortDirection && productsSortDirection !== '') {
    data.append('SortDirection', productsSortDirection);
  }

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/facture`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    dispatch(setPreloader(false));
    return null;
  }

  if (response.data.error) {
    dispatch(setPretensionsError('pretension_no_search_result'));
    dispatch(setPreloader(false));
    dispatch(setListPreloader(false));
    return null;
  }

  if (response.data.result.lines) {
    const receivedList = response.data.result.lines;
    const editedListArrObj = Array.from(editedList);
    const editedListArr = [];

    editedListArrObj.forEach((elem) => {
      editedListArr.push(elem[1])
    });

    if (editedListArr.length > 0) {

      receivedList.forEach(receivedProduct => {

        if (editedListArr.some((element) => element.seriesuid === receivedProduct.seriesuid)) {

          editedListArr.forEach((elem) => {

            if (elem.seriesuid === receivedProduct.seriesuid) {
              // eslint-disable-next-line no-param-reassign
              receivedProduct.lack = elem.deficit;
              // eslint-disable-next-line no-param-reassign
              receivedProduct.broken = elem.breakage;
              // eslint-disable-next-line no-param-reassign
              receivedProduct.defect = elem.waste;
              // eslint-disable-next-line no-param-reassign
              receivedProduct.overage = elem.surplus;
              // eslint-disable-next-line no-param-reassign
              receivedProduct.edited = true;
            }

          })

        }

      })

    }
    const viewList = [];

    let receivedListFiltered = [];
    if (tabs === 0) {
      receivedListFiltered = receivedList;
    } else {
      receivedList.forEach((item) => {
        if (item.edited){
          receivedListFiltered.push(item);
        }
      });
    }

    const totalProducts = response.data.result.totalproduct;
    pageCount = Math.ceil(totalProducts / productsPerPage);
    const startCount = (productsPageCurrent - 1) * productsPerPage
    receivedListFiltered.forEach((item, index) => {
      if (index >= startCount && index < startCount + productsPerPage) {
        viewList.push(item);
      }
    });
    await dispatch(setProductsList(viewList));
  } else {
    dispatch(setListPreloader(false));
  }



  await dispatch(setProductsPageCount(pageCount));
  await dispatch(setSearchFactureNum(response.data.result.docnum));
  dispatch(setPreloader(false));
  dispatch(setListPreloader(false));

  return null;
};



const addEditedPretension = (
  productsList,
  editedList,
  series,
  lack,
  broken,
  defect,
  overage,
  price,
  seriesuid,
  ) => async (dispatch) => {

  let lackValue = lack;
  let brokenValue = broken;
  let defectValue = defect;
  let overageValue = overage;

  if (!lackValue) {
    lackValue = ""
  }
  if (!brokenValue) {
    brokenValue = ""
  }
  if (!defectValue) {
    defectValue = ""
  }
  if (!overageValue) {
    overageValue = ""
  }

  const pretensionItem = {
    'seriesuid': seriesuid,
    'deficit': lackValue,
    'breakage': brokenValue,
    'waste': defectValue,
    'surplus': overageValue,
    'price': price,
  };

  const pretensionList = editedList;
  const productsArr = productsList;

  let editIndex = 0;
  productsArr.forEach((elem) => {
    if (elem.seriesuid === seriesuid){
      editIndex = productsArr.indexOf(elem);
    }
  });

  productsArr[editIndex].lack = lackValue;
  productsArr[editIndex].broken = brokenValue;
  productsArr[editIndex].defect = defectValue;
  productsArr[editIndex].overage = overageValue;

  const sumValues = lackValue + brokenValue + defectValue + overageValue

  if (sumValues < 1){
    pretensionList.delete(seriesuid);
  } else {
    pretensionList.set(seriesuid, pretensionItem);
  }

  await dispatch(setEditedList(pretensionList));
  await dispatch(setProductsList(productsArr));

  return null;
};



const fillPretensionColumn = (
  lang, // required
  autoDestUid, // required
  invoiceUid, // required
  editedList,
  col,
  productsList
) => async (dispatch) => {
  dispatch(setListPreloader(true));
  const data = new FormData();

  data.append('AutoDestUID', autoDestUid);
  data.append('InvoiceUID', invoiceUid);

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/facture`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    await dispatch(setListPreloader(false));
    return null;
  }

  const productsArr = productsList;
  const productsListAll = response.data.result.lines;
  const pretensionList = editedList;

  let lackValue = "";
  let brokenValue = "";
  let defectValue = "";
  let overageValue = "";
  let pretensionItem;

  productsListAll.forEach((elem) => {
    if (!elem.invalid) {
      if (pretensionList.get(elem.seriesuid)) {
        overageValue = pretensionList.get(elem.seriesuid).surplus;
      } else {
        overageValue = "";
      }

      switch (col) {
        case 'lack' :
          lackValue = elem.amount;
          break;
        case 'broken' :
          brokenValue = elem.amount;
          break;
        case 'defect' :
          defectValue = elem.amount;
          break;
        default: break;
      }

      pretensionItem = {
        'seriesuid': elem.seriesuid,
        'deficit': lackValue,
        'breakage': brokenValue,
        'waste': defectValue,
        'surplus': overageValue,
        'price': elem.price,
      };

      let editIndex;

      productsArr.forEach((productElem) => {
        if (productElem.seriesuid === elem.seriesuid){
          editIndex = productsArr.indexOf(productElem);
        }
      });

      if (editIndex !== undefined) {
        productsArr[editIndex].lack = lackValue;
        productsArr[editIndex].broken = brokenValue;
        productsArr[editIndex].defect = defectValue;
        productsArr[editIndex].overage = overageValue;
      }

      pretensionList.set(elem.seriesuid, pretensionItem);
    }
  });

  await dispatch(setEditedList(pretensionList));
  await dispatch(setListPreloader(false));
  return null;
};



const addImage = (list, image, imagesClientList, _totalClientList) => async (dispatch) => {
  const imagesList = list; // already uploaded
  let isDuplicate = false;
  let isOutOfFormat = false;
  let isOutOfSize = false;

  list.forEach(element => {
    if (element.name === image.name){
      isDuplicate = true;
    }
  });

  if (image.type !== "image/jpeg"
    && image.type !== "image/png"
    && image.type !== "image/PNG"
  ){
    isOutOfFormat = true;
  }
  if (image.size > 819200){
    isOutOfSize = true;
  }


  const maxImagesLength = 5 - imagesClientList.length;
  if (isDuplicate) {
    await dispatch(setPretensionsError('image_duplicate'));
  } else if (isOutOfSize) {
    await dispatch(setPretensionsError('image_out_of_size'));
  } else if (isOutOfFormat) {
    await dispatch(setPretensionsError('image_out_of_format'));
  } else if (imagesList.length >= maxImagesLength) {
    await dispatch(setPretensionsError('image_out_of_limit'));
  } else {
    imagesList.push(image);

    // const imagesClientLinked = imagesClientList;
    //
    // const reader  = new FileReader();
    //
    // reader.onloadend = function () {
    //   const itemImage = {
    //     id:  _totalClientList.length,
    //     image: reader.result,
    //     title: image.title
    //   }
    //
    //   dispatch(setTotalImagesClient(imagesClientLinked));
    // }
    //
    // reader.readAsDataURL(image);

    await dispatch(setImagesList(imagesList));
  }

  return null;
}

const fetchImages = (lang, autoDestUid, pretensionUid) => async (dispatch) => {
  const data = new FormData();

  data.append('ObjectUID', pretensionUid); // pretensionUid
  data.append('AutoDestUID', autoDestUid); // autoDestUid

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/images`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error) {
    console.log(response.data.error_code);
    return null;
  }

  const imagesClientLinked = [];

  if (response.data.result) {
    if (response.data.result.client) {

      response.data.result.client.forEach((item, i) => {

        const itemImageClient = {
          id: i,
          image: `${lang ? 'ua' : ''}/api/react/pretensy/photo?PhotoUID=${item.uid}&AutoDestUID=${autoDestUid}`,
          title: item.desc,
          uid: item.uid,
        }
        imagesClientLinked.push(itemImageClient);
      })
    }

    if (response.data.result.venta) {

      const imagesAdminLinked = [];

      response.data.result.venta.forEach((item, i) => {
        const itemImageAdmin = {
          id: i,
          image: `${lang ? 'ua' : ''}/api/react/pretensy/photo?PhotoUID=${item.uid}&AutoDestUID=${autoDestUid}`,
          title: item.desc,
        }
        imagesAdminLinked.push(itemImageAdmin);
      })
      await dispatch(setImagesAdminList(imagesAdminLinked));

    }
  }

  await dispatch(setImagesClientList(imagesClientLinked));
  await dispatch(setTotalImagesClient(imagesClientLinked));


  return null;
}

const deleteUploadedImage = (lang, autoDestUid, pretensionUid, imageUid) => async (dispatch) => {
  dispatch(setPreloader(true));
  const data = new FormData();

  data.append('PretensyUID', pretensionUid); // pretensionUid
  data.append('AutoDestUID', autoDestUid); // autoDestUid
  data.append('PhotoUID', imageUid); // imagetUid

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/deleteimage`, data);

  if (response.data && response.data.error){
    dispatch(setPretensionsError('delete_image_error'));
  } else {
    dispatch(setPretensionsError('delete_image_success'));
    await dispatch(fetchImages (lang, autoDestUid, pretensionUid));
  }

  dispatch(setPreloader(false));
  return null;
}

const deleteImage = (list, image) => async (dispatch) => {
  const imagesList = list.filter(listImage => listImage.name !== image.name);
  await dispatch(setImagesList(imagesList));

  return null;
}







const formalizePretension = (
    lang,
    autoDestUid,
    invoiceUid,
    productsEditedList,
    imagesList,
    comment,
    sendType,
    searchFactureNum,
    pretensionDraftUid,
  ) => async (dispatch) => {

  dispatch(setPreloader(true));
  dispatch(setListPreloader(true));
  const data = new FormData();

  let productsListConverted = Array.from(productsEditedList, ([name, values]) => ({ name, values }));
  productsListConverted = JSON.stringify(productsListConverted);

  data.append('AutoDestUID', autoDestUid);
  data.append('InvoiceUID', invoiceUid);
  data.append('statuscode', sendType);
  data.append('products', productsListConverted);

  // if (productsEditedList.size > 0) {
  //   data.append('products', productsListConverted);
  // } else {
  //   dispatch(setPretensionsError('pretension_no_products'));
  //   dispatch(setPreloader(false));
  //   dispatch(setListPreloader(false));
  //   return null;
  // }

  if (comment || comment.length > 0) {
    data.append('condition', comment);
  } else {
    dispatch(setPretensionsError('pretension_no_comment'));
    dispatch(setPreloader(false));
    dispatch(setListPreloader(false));
    return null;
  }

  if (pretensionDraftUid) {
    data.append('PretensyUID', pretensionDraftUid);
  }

  let response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/setpretensy`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error) {
    dispatch(setPretensionsError('pretension_creation_error'));
    dispatch(setPreloader(false));
    dispatch(setListPreloader(false));
    console.log(response.data.error_code);
    return null;
  }

  if (response.data.result.uid) {
    const pretensionUid = response.data.result.uid;
    let successFlag = true;

    imagesList.map((image) => {
      const formData = new FormData();
      formData.append('PretensyUID', pretensionUid);
      formData.append('AutoDestUID', autoDestUid);
      formData.append('image', image);
      response = api.post(`${lang ? 'ua' : ''}/api/react/pretensy/addimages`, formData);

      response.then((value) => {
        if (value.data.result) {
          successFlag = false;
        }
      });

      dispatch(setPreloader(false));
      dispatch(setListPreloader(false));
      return null;
    });

    if (successFlag) {
      if (sendType === 'SENT') {
        dispatch(setPretensionsError('pretension_success_send'));
      }
      if (sendType === 'DRAFT') {
        dispatch(setPretensionsError('pretension_success_draft'));
      }
      dispatch(setPretensionFactureNum(searchFactureNum));
      dispatch(setPretensionsSection(1));
      dispatch(setPretensionsStep(1));
    }
  }

  dispatch(setPreloader(false));
  dispatch(setListPreloader(false));
  return null;
}

const deletePretension = (
  lang,
  autoDestUid,
  pretensionUid,
) => async (dispatch) => {

  dispatch(setPreloader(true));
  dispatch(setListPreloader(true));
  const data = new FormData();

  data.append('AutoDestUID', autoDestUid);
  data.append('PretensyUID', pretensionUid);

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/deletepretensy`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.DeletePretensyResult === 200) {
    dispatch(setPretensionsError('pretension_delete_success'));
    await dispatch(setPreloader(false));
    await dispatch(setListPreloader(false));
    await dispatch(setPretensionsSection(1));
    await dispatch(setPretensionsStep(1));
    return null;
  }

  dispatch(setPretensionsError('pretension_delete_error'));
  await dispatch(setPreloader(false));
  await dispatch(setListPreloader(false));
  return null;
}

const AddMoreImages = (
  lang,
  autoDestUid,
  pretensionUid,
  imagesList,
) => async (dispatch) => {

  let response;
  let successFlag = true;
  const promiseArr = [];

  imagesList.map((image) => {
    const formData = new FormData();
    formData.append('PretensyUID', pretensionUid);
    formData.append('AutoDestUID', autoDestUid);
    formData.append('image', image);
    response = api.post(`${lang ? 'ua' : ''}/api/react/pretensy/addimages`, formData);
    promiseArr.push(response);

    return null;
  });

  Promise.all(promiseArr).then(values => {
    values.forEach((value) => {
      if (value.data.error) {
        successFlag = false;
      }
    })
    if (!successFlag) {
      dispatch(setPretensionsError('images_upload_error'));
      dispatch(setImagesList([]));
    } else {
      dispatch(setPretensionsError('view_save_success'));
      dispatch(setPretensionsSection(1));
    }
  });




  return null;
}




const fetchCurrentPretension = (
  lang,
  autoDestUid,
  pretensionUid,
  searchProductName,
  productsSortColumn,
  productsSortDirection,
  productsPerPage,
  productsPageCurrent) => async (dispatch) => {

  dispatch(setPreloader(true));
  dispatch(setListPreloader(true));
  const data = new FormData();

  data.append('AutoDestUID', autoDestUid);
  data.append('PretensyUID', pretensionUid);
  if (searchProductName) {
    data.append('GoodName', searchProductName);
  }
  if (productsSortColumn && productsSortColumn !== '') {
    data.append('SortColumn', productsSortColumn);
  }
  if (productsSortDirection && productsSortDirection !== '') {
    data.append('SortDirection', productsSortDirection);
  }
  if (productsPerPage) {
    data.append('PageSize', productsPerPage);
  }
  if (productsPageCurrent) {
    data.append('page', productsPageCurrent);
  }

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/pretensy`, data);

  if (response.data.error) {
    alert(response.data.error_code);
    dispatch(setPreloader(false));
    return null;
  }

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    dispatch(setPreloader(false));
    return null;
  }

  if (response.data.result) {
    await dispatch(setCurrentPretension(response.data.result));
    // opening pretension for view or edit
    if (response.data.result.status === 'DRAFT') {
      dispatch(setPretensionFactureUid(response.data.result.invoiceoutuid));

      const editedList = response.data.result.lines;

      if (editedList) {
        const editedListCustomer = new Map();
        let pretensionItem = {};
        let seriesuid;
        let lackValue;
        let brokenValue;
        let defectValue;
        let overageValue;
        let priceValue;

        editedList.forEach((elem) => {
          seriesuid = elem.customer_info.seriesuid;
          lackValue = elem.customer_info.deficit;
          brokenValue = elem.customer_info.breakage;
          defectValue = elem.customer_info.waste;
          overageValue = elem.customer_info.surplus;
          priceValue = elem.customer_info.price;
          pretensionItem = {
            'seriesuid': seriesuid,
            'deficit': lackValue,
            'breakage': brokenValue,
            'waste': defectValue,
            'surplus': overageValue,
            'price': priceValue,
          };
          editedListCustomer.set(seriesuid, pretensionItem);
          dispatch(setEditedList(editedListCustomer));
          dispatch(setPretensionComment(response.data.result.condition));
        });
      }

      await dispatch(setPretensionsSection(2));
      await dispatch(setPretensionsStep(2));
    } else {
      await dispatch(setPretensionsSection(3));
    }
  } else {
    await dispatch(setCurrentPretension({}));
  }

  const factureData = new FormData();

  factureData.append('AutoDestUID', autoDestUid);
  factureData.append('InvoiceUID', response.data.result.invoiceoutuid);

  const factureResponse = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/facture`, factureData);

  await dispatch(setPretensionFactureNum(factureResponse.data.result.docnum));
  await dispatch(setPretensionFactureDate(factureResponse.data.result.date));

  dispatch(setPreloader(false));
  if (response.data.result.status !== 'DRAFT') {
    dispatch(setListPreloader(false));
  }


  return null;
};



const getMessage = (lang, pretensionUid) => async (dispatch) => {
  const data = new FormData();

  data.append('uid', pretensionUid); // pretension uid

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/getmessage`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error) {
    alert(response.data.error_code);
    return null;
  }

  if (response.data.result) {
    await dispatch(setCurrentPretensionDialog(response.data.result));
  } else {
    await dispatch(setCurrentPretensionDialog([]));
  }

  return null;
}

const sendMessage = (lang, pretensionUid, dialog, text) => async (dispatch) => {
  const data = new FormData();

  data.append('ObjectUID', pretensionUid); // pretension uid

  if (text && text !== '') {
    data.append('Text', text);
  } else {
    return null
  }

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/sendmessage`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error) {
    alert(response.data.error_code);
    return null;
  }

  await dispatch(getMessage(lang, pretensionUid));

  return null;
}

export default {
  setPretensionsList,
  setFacturesList,
  setProductsList,
  fetchPretensions,
  fetchFactures,
  fetchProducts,
  setPretensionsSection,
  setPretensionsStep,
  setPretensionFactureNum,
  setPretensionFactureDate,
  setPretensionFactureUid,
  setEditedList,
  setImagesList,
  setImagesAdminList,
  setImagesClientList,
  addImage,
  deleteImage,
  deleteUploadedImage,
  fetchImages,
  setPretensionComment,
  addEditedPretension,
  formalizePretension,
  deletePretension,
  setCurrentPretensionUid,
  fetchCurrentPretension,
  setCurrentPretension,
  setCurrentPretensionDialog,
  setCurrentPretensionMessage,
  getMessage,
  sendMessage,
  setDateStart,
  setDateEnd,
  setSearchFactureNum,
  setSearchPretensionStatus,
  setPretensionsSortDirection,
  setPretensionsSortColumn,
  setErrorNum,
  setPreloader,
  setListPreloader,
  setCurrentPage,
  setPageCount,
  setPerPage,
  setTabs,
  setProductsSearchName,
  setProductsSortDirection,
  setProductsSortColumn,
  setProductsPerPage,
  setProductsPageCount,
  setProductsPage,
  AddMoreImages,
  setPretensionsError,
  fetchPretensionsInfoColumn,
  setPretensionsInfoColumn,
  fetchPretensionsInfo,
  fillPretensionColumn,
  setTotalImagesClient
}
