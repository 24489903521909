import reportTypes from "../types/report";
import api from "../../api/api";
import configActions from "./config";

const setModalPreloader = (value) => ({
  type : reportTypes.SET_MODAL_PRELOADER,
  value,
});

const setReportModal = (bool) => ({
  type : reportTypes.SET_REPORT_MODAL,
  bool,
});

const setReportModalStep = (num) => ({
  type : reportTypes.SET_REPORT_MODAL_STEP,
  num,
});

const setDateStart = (text) => ({
  type : reportTypes.SET_REPORT_DATE_START,
  text,
});

const setDateEnd = (text) => ({
  type : reportTypes.SET_REPORT_DATE_END,
  text,
});

const setReportDataTypes = (array) => ({
  type : reportTypes.SET_REPORT_DATA_TYPES,
  array,
});

const setReportAddresses = (array) => ({
  type : reportTypes.SET_REPORT_ADDRESSES,
  array,
});

const setReportAddressesChecked = (array) => ({
  type : reportTypes.SET_REPORT_ADDRESSES_CHECKED,
  array,
});

const setReportAddressesAll = (bool) => ({
  type : reportTypes.SET_REPORT_ADDRESSES_ALL,
  bool,
});

const setReportError = (text) => ({
  type : reportTypes.SET_REPORT_ERROR,
  text,
});

const changeDataType = (id, _list) => async (dispatch) => {
  const list = _list;
  list.forEach((el) => {
    if (el.id === id) {
      // eslint-disable-next-line no-param-reassign
      el.checked = !el.checked;
    }
  });
  dispatch(setReportDataTypes(list));
  return null;
}

const changeReportAddressCheck = (_elem, _addressList, _addressListChecked) => async (dispatch) => {
  dispatch(setReportAddressesAll(false));
  const addressList = _addressList;
  const addressListChecked = [];
  addressList.forEach((el) => {
    if (el.autodestname === _elem.autodestname) {
      // eslint-disable-next-line no-param-reassign
      el.checked = !el.checked;
    }
  });
  addressList.forEach((el) => {
    if (el.checked){
      addressListChecked.push(el);
    }
  })
  dispatch(setReportAddresses(addressList));
  dispatch(setReportAddressesChecked(addressListChecked));
  return null;
}

const checkAllAddresses = (_addressList, isChecked) => async (dispatch) => {
  const addressList = _addressList;
  if (isChecked) {
    addressList.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.checked = true;
    });
    dispatch(setReportAddressesChecked(addressList));
  } else {
    addressList.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.checked = false;
    });
    dispatch(setReportAddressesChecked([]));
  }
  dispatch(setReportAddresses(addressList));
  return null;
}

const formalizeReport = (lang, _dateStart, _dateEnd, _dataType, _addressList) => async (dispatch) => {
  dispatch(setModalPreloader(true));
  const data = new FormData();
  let dateStartChecked = _dateStart;
  let dateEndChecked = _dateEnd;

  const autodestsList = [];
  _addressList.forEach((el) => {
    autodestsList.push(el.uid);
  });

  if (dateStartChecked === '' || !dateStartChecked) {
    dateStartChecked = new Date();
    dateStartChecked.setMonth(dateStartChecked.getMonth() - 3);
  }

  if (dateEndChecked === '' || !dateEndChecked) {
    dateEndChecked = new Date();
  }

  const yearStart = dateStartChecked.getFullYear();
  const monthStart = dateStartChecked.getMonth() + 1;
  const dayStart = dateStartChecked.getDate();
  const dateStartFormated = `${yearStart}-${monthStart}-${dayStart}`;

  const yearEnd = dateEndChecked.getFullYear();
  const monthEnd = dateEndChecked.getMonth() + 1;
  const dayEnd = dateEndChecked.getDate();
  const dateEndFormated = `${yearEnd}-${monthEnd}-${dayEnd}`;

  if (_dataType) {
    console.log(_dataType);
  }
  data.append('autodests', autodestsList);
  data.append('DateStart', dateStartFormated);
  data.append('DateEnd', dateEndFormated);

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/pretensy/export`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    dispatch(setModalPreloader(false));
    return null;
  }

  if (response.data.error) {
    dispatch(setReportError('no-results'));
    dispatch(setModalPreloader(false));
    return null;
  }

  const link = document.createElement('a');
  link.href = response.data.result.file;
  link.setAttribute('download', 'file.xlsx');
  document.body.appendChild(link);
  link.click();
  link.remove();


  dispatch(setModalPreloader(false));
  return null;

}


export default {
  setReportModal,
  setReportModalStep,
  setDateStart,
  setDateEnd,
  setReportDataTypes,
  changeDataType,
  changeReportAddressCheck,
  setReportAddresses,
  setReportAddressesChecked,
  setReportAddressesAll,
  checkAllAddresses,
  formalizeReport,
  setModalPreloader,
  setReportError
}
