import React, {useEffect} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {LightBox} from 'react-lightbox-pack';
import "react-lightbox-pack/dist/index.css";
import ReactPaginate from "react-paginate";
import pretensionsActions from "../../store/actions/pretensions";
import PretensionProductItem from "./PretensionProductItem";
import StepNav from "./StepNav";
import ImagesUpload from "./ImagesUpload";
import SearchProducts from "./SearchProducts";
import CountList from "../CountList/CountList";
import ListPreloader from "../Preloader/ListPreloader";
import Preloader from "../Preloader/Preloader";


const factureNum = {
  ru : 'Фактура № ',
  ua : 'Фактура № ',
};
const from = {
  ru : ' от ',
  ua : ' від ',
};
const detailsTitle = {
  ru : 'Комментарий к претензии',
  ua : 'Коментар до претензії',
};

const textComment = {
  ru : 'Текст комментария к претензии',
  ua : 'Текст коментаря до претензії',
};
const textPhotos = {
  ru : 'Ваши фото',
  ua : 'Ваші фото',
};
const photosDescription = {
  ru : 'Форматы jpeg, jpg, png. Не более 5 штук. Размер не более 800 КБ каждая.',
  ua : 'Формати jpeg, jpg, png. Не більше ніж 5 штук. Розмір не більше ніж 800 КБ кожна.',
};
const buttonAll = {
  ru : 'Выбрать все',
  ua : 'Обрати усі',
};
const titleName = {
  ru : 'Наименование товара',
  ua : 'Найменування товару',
};
const titleSeries = {
  ru : 'Серия',
  ua : 'Серія',
};
const titleAmount = {
  ru : 'Кол-во',
  ua : 'Кіл-ть',
};
const titlePrice = {
  ru : 'Цена',
  ua : 'Ціна',
};
const titleCaution = {
  ru : 'Внимание',
  ua : 'Увага',
};
const titleLack = {
  ru : 'Недостача',
  ua : 'Нестача',
};
const titleBroken = {
  ru : 'Бой',
  ua : 'Бій',
};
const titleDefect = {
  ru : 'Брак',
  ua : 'Брак',
};
const titleOverage = {
  ru : 'Излишки',
  ua : 'Надлишки',
};
const textRemoveAll = {
  ru : 'Удалить все',
  ua : 'Видалити всі',
};
const textBtnDraft = {
  ru : 'Сохранить как черновик',
  ua : 'Зберегти як чернетку',
};
const textBtnDelete = {
  ru : 'Удалить черновик',
  ua : 'Видалити чернетку',
};
const textBtnSend = {
  ru : 'Оформить претензию',
  ua : 'Оформити претензію',
};



const StepFormalizePretension = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const configStore = useSelector((store) => store.configStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const dispatch = useDispatch();

  // State
  const [toggle, setToggle] =  React.useState(false);
  const [sIndex, setSIndex] =  React.useState(0);

  // eslint-disable-next-line no-shadow
  const  lightBoxHandler  = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  const listProducts = pretensionsStore.productsList.map((elem) => (
    <PretensionProductItem
      key={elem.uid}
      item={elem}
      name={configStore.isCurrentLangUa ? elem.localgood : elem.good}
      series={elem.series}
      amount={elem.amount}
      price={elem.price}
      seriesuid={elem.seriesuid}
      lack={elem.lack}
      broken={elem.broken}
      defect={elem.defect}
      overage={elem.overage}
      invalid={elem.invalid}
    />
  ));


  const pretensionSubmit = (sendType) => {
    dispatch(pretensionsActions.setCurrentPage('1'));
    dispatch(pretensionsActions.setDateStart(''));
    dispatch(pretensionsActions.setDateEnd(''));
    dispatch(
      pretensionsActions.formalizePretension(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.pretensionFactureUid,
        pretensionsStore.editedList,
        pretensionsStore.imagesList,
        pretensionsStore.pretensionComment,
        sendType,
        pretensionsStore.searchFactureNum,
        pretensionsStore.currentPretensionUid,
      )
    );
  }

  const pretensionDelete = () => {
    dispatch(pretensionsActions.setCurrentPage('1'));
    dispatch(pretensionsActions.setDateStart(''));
    dispatch(pretensionsActions.setDateEnd(''));
    dispatch(
      pretensionsActions.deletePretension(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.currentPretensionUid,
      )
    );
  }

  const writeComment = (value) => {
    let str = value;
    str = str.substring(0, 1000);
    dispatch(pretensionsActions.setPretensionComment(str));
  }


  const changePerPage = (num) => {
    dispatch(pretensionsActions.setProductsPage(1));
    dispatch(pretensionsActions.setProductsPerPage(num));
  };

  const pageChange = (data) => {
    dispatch(pretensionsActions.setProductsPage(data.selected + 1));
  };

  const sortProducts = (column) => {
    let direction = pretensionsStore.productsSortDirection;
    if (direction === 'DESC' || direction === '') {
      direction = 'ASC';
      dispatch(pretensionsActions.setProductsSortDirection('ASC'));
    } else {
      direction = 'DESC';
      dispatch(pretensionsActions.setProductsSortDirection('DESC'));
    }
    dispatch(pretensionsActions.setProductsSortColumn(column));
    dispatch(
      pretensionsActions.fetchProducts(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.pretensionFactureUid,
        pretensionsStore.editedList,
        pretensionsStore.productsSearchName,
        column,
        direction,
        pretensionsStore.productsPerPage,
        pretensionsStore.productsPageCurrent,
        pretensionsStore.tabs,
      ),
    );
  };

  const fillAll = (col) => {
    dispatch(pretensionsActions.fillPretensionColumn(
      configStore.isCurrentLangUa,
      deliveryStore.selectCityUid,
      pretensionsStore.pretensionFactureUid,
      pretensionsStore.editedList,
      col,
      pretensionsStore.productsList,
    ))
  }
  const clearAll = () => {
    dispatch(pretensionsActions.setEditedList(new Map()));
    dispatch(pretensionsActions.setTabs(0));
  }

  const imageUploadedRemove = (item) => {
    dispatch(pretensionsActions.deleteUploadedImage(
      configStore.isCurrentLangUa,
      deliveryStore.selectCityUid,
      pretensionsStore.currentPretensionUid,
      item.uid
    ));
  }


  useEffect(() => {
    dispatch(
      pretensionsActions.fetchProducts(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.pretensionFactureUid,
        pretensionsStore.editedList,
        pretensionsStore.productsSearchName,
        pretensionsStore.productsSortColumn,
        pretensionsStore.productsSortDirection,
        pretensionsStore.productsPerPage,
        pretensionsStore.productsPageCurrent,
        pretensionsStore.tabs,
      )
    );
  }, [
    configStore.isCurrentLangUa,
    deliveryStore.selectCityUid,
    pretensionsStore.productsPerPage,
    pretensionsStore.productsPageCurrent,
    pretensionsStore.tabs,
    pretensionsStore.editedList,
  ]);

  return (
    <div className="step-create create-formalize">
      <StepNav/>
      <div className="pretension-formalize">
        <div className="pretension-formalize__top">
          <Preloader/>
          <div className="pretension-formalize__heading">
            {configStore.isCurrentLangUa ? factureNum.ua : factureNum.ru}
            {pretensionsStore.pretensionFactureNum}
            {configStore.isCurrentLangUa ? from.ua : from.ru}
            {pretensionsStore.pretensionFactureDate}
          </div>
          <div className="pretension-formalize__details">
            <div className="details-block comment">
              <div className="details-block__heading">
                <div className="details-block__title">
                  {configStore.isCurrentLangUa ? detailsTitle.ua : detailsTitle.ru}
                </div>
              </div>
              <div className="input-group details-block__comment">
              <textarea
                onInput={(ev) => writeComment(ev.target.value)}
                cols="30"
                rows="8"
                value={pretensionsStore.pretensionComment}
                placeholder={`${configStore.isCurrentLangUa ? textComment.ua : textComment.ru}`}
              />
              </div>
            </div>
            <div className="details-block images">
              <div className="details-block__heading">
                <div className="details-block__title">
                  {configStore.isCurrentLangUa ? textPhotos.ua : textPhotos.ru}
                </div>
                <div className="details-block__description">
                  {configStore.isCurrentLangUa ? photosDescription.ua : photosDescription.ru}
                </div>
              </div>
              <div className="images-wrapper">
                <div className="images-upload static">
                  <div className="images-list">
                    {pretensionsStore.imagesClientList.map((item, index) => (
                      <div className="image-item uploaded">
                        <button className="image-item__open" type="button" onClick={() => {
                          lightBoxHandler(true, index);
                        }}>
                          <img
                            key={item.id}
                            src={item.image}
                            alt={item.title}
                            style={{ maxHeight:  "80vh", maxWidth:  "50vw" }}
                          />
                        </button>
                        <button type="button" className="image-item__remove" onClick={() => imageUploadedRemove(item)}>
                          <i className="icon-close-thin"/>
                        </button>
                      </div>
                    ))}
                    <LightBox
                      state={toggle}
                      event={lightBoxHandler}
                      data={pretensionsStore.totalImagesClient}
                      imageWidth="60vw"
                      imageHeight="70vh"
                      thumbnailHeight={50}
                      thumbnailWidth={50}
                      setImageIndex={setSIndex}
                      imageIndex={sIndex}
                    />
                  </div>
                </div>
                <ImagesUpload/>
              </div>
            </div>
          </div>
        </div>
        <div className="pretension-formalize__bottom">
          <ListPreloader/>
          <SearchProducts/>
          <div className="pretension-formalize__search-list">
            <div className="pretension-formalize__list-header">
              <div className="pretension-formalize__header-item col-name">
                <button type="button"
                        className="pretension-formalize__header-item-text"
                        onClick={() => sortProducts('good')}>
                  {configStore.isCurrentLangUa ? titleName.ua : titleName.ru}
                </button>
              </div>
              <div className="pretension-formalize__header-item col-series">
                <button type="button"
                        className="pretension-formalize__header-item-text"
                        onClick={() => sortProducts('series')}>
                  {configStore.isCurrentLangUa ? titleSeries.ua : titleSeries.ru}
                </button>
              </div>
              <div className="pretension-formalize__header-item col-amount">
                <button type="button"
                        className="pretension-formalize__header-item-text"
                        onClick={() => sortProducts('amount')}>
                  {configStore.isCurrentLangUa ? titleAmount.ua : titleAmount.ru}
                </button>
              </div>
              <div className="pretension-formalize__header-item col-price">
                <button type="button"
                        className="pretension-formalize__header-item-text"
                        onClick={() => sortProducts('price')}>
                  {configStore.isCurrentLangUa ? titlePrice.ua : titlePrice.ru}
                </button>
              </div>
              <div className="pretension-formalize__header-item col-caution">
                <button type="button"
                        className="pretension-formalize__header-item-text">
                  {configStore.isCurrentLangUa ? titleCaution.ua : titleCaution.ru}
                </button>
              </div>
              <div className="pretension-formalize__header-item col-lack">
                <div className="pretension-formalize__header-item-text">
                  {configStore.isCurrentLangUa ? titleLack.ua : titleLack.ru}
                </div>
                <div className="fill-button">
                  <button type="button" onClick={() => fillAll('lack')}>
                    <i className="icon-clipboard"/>
                  </button>
                  <div className="fill-button__info">
                    {configStore.isCurrentLangUa ? buttonAll.ua : buttonAll.ru}
                  </div>
                </div>
              </div>
              <div className="pretension-formalize__header-item col-broken">
                <div className="pretension-formalize__header-item-text">
                  {configStore.isCurrentLangUa ? titleBroken.ua : titleBroken.ru}
                </div>
                <div className="fill-button">
                  <button type="button" onClick={() => fillAll('broken')}>
                    <i className="icon-clipboard"/>
                  </button>
                  <div className="fill-button__info">
                    {configStore.isCurrentLangUa ? buttonAll.ua : buttonAll.ru}
                  </div>
                </div>
              </div>
              <div className="pretension-formalize__header-item col-defect">
                <div className="pretension-formalize__header-item-text">
                  {configStore.isCurrentLangUa ? titleDefect.ua : titleDefect.ru}
                </div>
                <div className="fill-button">
                  <button type="button" onClick={() => fillAll('defect')}>
                    <i className="icon-clipboard"/>
                  </button>
                  <div className="fill-button__info">
                    {configStore.isCurrentLangUa ? buttonAll.ua : buttonAll.ru}
                  </div>
                </div>
              </div>
              <div className="pretension-formalize__header-item col-overage">
                <div className="pretension-formalize__header-item-text">
                  {configStore.isCurrentLangUa ? titleOverage.ua : titleOverage.ru}
                </div>
              </div>
              <div className="pretension-formalize__header-item col-indicate">
                {pretensionsStore.tabs !== 1 ? '' : (
                  <div className="fill-button">
                    <button type="button" onClick={() => clearAll()}>
                      <i className="icon-garbage"/>
                    </button>
                    <div className="fill-button__info">
                      {configStore.isCurrentLangUa ? textRemoveAll.ua : textRemoveAll.ru}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ul className="pretension-formalize__list">
              {listProducts}
            </ul>
            <div className="pretension-formalize__bottom-nav">
              <CountList onClick={changePerPage} currentPerPage={pretensionsStore.productsPerPage} countOf="products"/>
              <ReactPaginate
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={pretensionsStore.productsPageCount}
                forcePage={pretensionsStore.productsPageCurrent - 1}
                containerClassName="pager"
                pageLinkClassName="pager__button"
                activeLinkClassName="pager__button--active"
                previousLinkClassName="icon-arrow pager__arrow-prev"
                nextLinkClassName="icon-arrow pager__arrow-next"
                onPageChange={pageChange}
              />
              <div className="pretension-formalize__bottom-nav-buttons">
                {pretensionsStore.currentPretension.status !== 'DRAFT' ? '' : (
                  <button className="pretension-formalize__delete"
                          type="button" onClick={pretensionDelete}>
                    <i className="icon-status-error"/>
                    {configStore.isCurrentLangUa ? textBtnDelete.ua : textBtnDelete.ru}
                  </button>
                )}
                <button className="pretension-formalize__submit btn-draft"
                        type="button" onClick={() => pretensionSubmit('DRAFT')}>
                  <i className="icon-document"/>
                  {configStore.isCurrentLangUa ? textBtnDraft.ua : textBtnDraft.ru}
                </button>
                <button className="pretension-formalize__submit btn-send"
                        type="button" onClick={() => pretensionSubmit('SENT')}>
                  <i className="icon-hammer"/>
                  {configStore.isCurrentLangUa ? textBtnSend.ua : textBtnSend.ru}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StepNav/>
    </div>
  );
};

export default StepFormalizePretension;
