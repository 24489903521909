import React, {useEffect} from 'react';

const PretensionChatMessage = (props) => {

  const {messageInfo} = props

  return (
    <div className={`chat-message
      ${messageInfo.client === '1' ? 'client' : ''}
      ${messageInfo.isnew === '1' ? 'isnew' : ''}
    `}>
      <div className="chat-message__content">
        <div className="chat-message__name">
          {messageInfo.author}
        </div>
        <div className="chat-message__text">
          {messageInfo.text}
        </div>
        <i className="icon-dialog-nose"/>
      </div>
      <div className="chat-message__footer">
        {messageInfo.date}
      </div>
    </div>
  );
}


export default PretensionChatMessage;
