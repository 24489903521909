import React, {useEffect, useRef} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import DatePicker from "react-datepicker";
import uk from "date-fns/locale/uk";
import ru from "date-fns/locale/ru";
import pretensionsActions from "../../store/actions/pretensions";
import reportActions from "../../store/actions/report";
import facturesActions from "../../store/actions/factures";
import certificatesAction from "../../store/actions/certificates";

const SearchPretensions = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const documentsStore = useSelector((state) => state.documentsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();
  const datepickerStart    = useRef(null);
  const datepickerEnd    = useRef(null);
  const searchText = {
    ua : 'Пошук',
    ru : 'Поиск',
  };
  const floatPretensionsInfo = {
    ua : 'Введіть 3 або більше символів. Допустимі символи: "-", "/" та цифри.',
    ru : 'Введите 3 или более символов. Допустимые символы: "-", "/" и цифры.',
  };
  const placeholderPretensions = {
    ua : '№ фактури, № інтернет-замовлення',
    ru : '№ фактуры, № интернет-заказа',
  }
  const btnCreate = {
    ua : 'Створити претензію',
    ru : 'Создать претензию',
  }
  const btnReport = {
    ua : 'Сформувати звіт',
    ru : 'Сформировать отчет',
  }
  const dateTitle = {
    ua : 'За період з',
    ru : 'За период с',
  }

  const submitFormPretensions = (ev) => {
    ev.preventDefault();
    dispatch(
      pretensionsActions.fetchPretensions(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.dateStart,
        pretensionsStore.dateEnd,
        pretensionsStore.searchFactureNum,
        pretensionsStore.searchPretensionStatus,
        pretensionsStore.pretensionsSortColumn,
        pretensionsStore.pretensionsSortDirection,
        pretensionsStore.perPage,
        pretensionsStore.currentPage,
      ),
    );
  };


  const stepCreate = () => {
    dispatch(pretensionsActions.setPretensionsSection(2));
    dispatch(pretensionsActions.setSearchFactureNum(''));
    dispatch(pretensionsActions.setImagesList([]));
    dispatch(pretensionsActions.setImagesAdminList([]));
    dispatch(pretensionsActions.setImagesClientList([]));
  }
  const openReportModal = () => {
    dispatch(reportActions.setReportModal(true));
  }

  const setPretensionNum = (ev, num) => {
    ev.preventDefault();
    const regexp = new RegExp('^[0-9/-]+$');

    if (num.match(regexp) !== null || num === '') {
      dispatch(pretensionsActions.setSearchFactureNum(num));
    }
  };

  const resetPretensionNum = () => {
    dispatch(pretensionsActions.setSearchFactureNum(''));
    dispatch(
      pretensionsActions.fetchPretensions(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.dateStart,
        pretensionsStore.dateEnd,
        '',
        pretensionsStore.searchPretensionStatus,
        pretensionsStore.pretensionsSortColumn,
        pretensionsStore.pretensionsSortDirection,
        pretensionsStore.perPage,
        pretensionsStore.currentPage,
      ),
    );
  };

  const setDateStart = (date) => {
    let dateRawStart = date;
    const dateRawEnd = pretensionsStore.dateEnd;
    if (dateRawEnd && dateRawEnd !== ''){
      if (dateRawStart > dateRawEnd) {
        dateRawStart = dateRawEnd
      }
    }

    dispatch(pretensionsActions.setDateStart(dateRawStart));
  };

  const setDateEnd = (date) => {
    let dateRawEnd = date;
    const dateRawStart = pretensionsStore.dateStart;
    if (dateRawStart && dateRawStart !== ''){
      if (dateRawEnd < dateRawStart) {
        dateRawEnd = dateRawStart
      }
    }

    dispatch(pretensionsActions.setDateEnd(dateRawEnd));
  };

  const handleChangeRaw = (ev, value) => {
    const regexp = new RegExp(
      /[a-zA-Zа-яА-Я!@#$%^&*()_+=?,/]/g
    );
    if (regexp.test(value) || value.length > 10) {
      ev.preventDefault();
    }
  };

  const focusHiddenDatepickerStart = () => {
    datepickerStart.current.input.focus();
  };
  const focusHiddenDatepickerEnd = () => {
    datepickerEnd.current.input.focus();
  };

  const resetDateStart = () => {
    dispatch(pretensionsActions.setDateStart(undefined));
    dispatch(
      pretensionsActions.fetchPretensions(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        undefined,
        pretensionsStore.dateEnd,
        pretensionsStore.searchFactureNum,
        pretensionsStore.searchPretensionStatus,
        pretensionsStore.pretensionsSortColumn,
        pretensionsStore.pretensionsSortDirection,
        pretensionsStore.perPage,
        pretensionsStore.currentPage,
      ),
    );
  };
  const resetDateEnd = () => {
    dispatch(pretensionsActions.setDateEnd(undefined));
    dispatch(
      pretensionsActions.fetchPretensions(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.dateStart,
        undefined,
        pretensionsStore.searchFactureNum,
        pretensionsStore.searchPretensionStatus,
        pretensionsStore.pretensionsSortColumn,
        pretensionsStore.pretensionsSortDirection,
        pretensionsStore.perPage,
        pretensionsStore.currentPage,
      ),
    );
  };

  return (
    <div className="search-pretensions">
      <p className="search-pretensions__headline">
        {configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
      </p>
      <form className="search-pretensions__form"
            onSubmit={(ev) => submitFormPretensions(ev)}>
        <div className="search-pretensions__form-wrapper">
          <div className="search-pretensions__input-product-wrapper">
						<span className="float-info">
							{configStore.isCurrentLangUa ? floatPretensionsInfo.ua : floatPretensionsInfo.ru}
						</span>
            <input
              pattern="^[0-9/-]+$"
              className={`search-pretensions__input
						${documentsStore.searchErrorNum !== 0 ? 'input-error' : ''}
						`}
              type="text"
              placeholder={configStore.isCurrentLangUa ? placeholderPretensions.ua : placeholderPretensions.ru}
              onInput={(ev) => setPretensionNum(ev, ev.target.value)}
              value={pretensionsStore.searchFactureNum}
            />
            {pretensionsStore.searchFactureNum && pretensionsStore.searchFactureNum.length > 0 && (
              <button
                onClick={resetPretensionNum}
                type="button"
                className="search-certificate__button-reset search-certificate__button-reset-facture">
                <i className="icon-cross"/>
              </button>
            )}
          </div>
          <span>{configStore.isCurrentLangUa ? dateTitle.ua : dateTitle.ru}</span>
          <div className='search-pretensions__calendar'>
            <div className="react-datepicker-wrapper">
              <div className="react-datepicker__input-container">
                <DatePicker
                  locale={configStore.isCurrentLangUa ? uk : ru}
                  ref={datepickerStart}
                  placeholderText="Дата"
                  selected={pretensionsStore.dateStart}
                  onChange={(date) => setDateStart(date)}
                  dateFormat="dd.MM.yyyy"
                  onChangeRaw={event => handleChangeRaw(event, event.target.value)}
                />
              </div>
            </div>
            {pretensionsStore.dateStart === undefined ? (
              <button
                onClick={focusHiddenDatepickerStart}
                type="button"
                className="search-certificate__calendar-icon"
              >
                <i className="icon-calendar"/>
              </button>
            ) : (
              <button
                onClick={resetDateStart}
                type="button"
                className="search-certificate__button-reset"
              >
                <i className="icon-cross"/>
              </button>
            )}
          </div>
          <span>по</span>
          <div className='search-pretensions__calendar'>
            <div className="react-datepicker-wrapper">
              <div className="react-datepicker__input-container">
                <DatePicker
                  locale={configStore.isCurrentLangUa ? uk : ru}
                  ref={datepickerEnd}
                  placeholderText="Дата"
                  selected={pretensionsStore.dateEnd}
                  onChange={(date) => setDateEnd(date)}
                  dateFormat="dd.MM.yyyy"
                  onChangeRaw={event => handleChangeRaw(event, event.target.value)}
                />
              </div>
            </div>
            {pretensionsStore.dateEnd === undefined ? (
              <button
                onClick={focusHiddenDatepickerEnd}
                type="button"
                className="search-certificate__calendar-icon"
              >
                <i className="icon-calendar"/>
              </button>
            ) : (
              <button
                onClick={resetDateEnd}
                type="button"
                className="search-certificate__button-reset"
              >
                <i className="icon-cross"/>
              </button>
            )}
          </div>
        </div>
        <button type="submit" className="search-pretensions__submit">
          <i className="icon-search search-pretensions__submit-icon"/>
          <span className="search-pretensions__submit-text">
						{configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
					</span>
        </button>
      </form>
      <div className="search-pretensions__buttons-wrap">
        <button type="button" className="btn-report" onClick={() => openReportModal()}>
          <i className="icon-report"/>
          {configStore.isCurrentLangUa ? btnReport.ua : btnReport.ru}
        </button>
        <button type="button" className="btn-pretensy-create" onClick={() => stepCreate()}>
          <i className="icon-hammer"/>
          {configStore.isCurrentLangUa ? btnCreate.ua : btnCreate.ru}
        </button>
      </div>
    </div>
  );
};

export default SearchPretensions;
