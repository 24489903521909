import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import pretensionsActions from "../../store/actions/pretensions";

const PretensionProductItem = (props) => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();
  const { name, series, amount, price, seriesuid, lack, broken, defect, overage, invalid } = props;


  let valuesSum = Number(lack) +
    Number(broken) +
    Number(defect) +
    Number(overage);
  if (Number.isNaN(valuesSum)) {
    valuesSum = 0;
  }


  const onInputFunc = (event) => {

    const enteredValue = Number(event.target.value);
    const amountNum = Number(amount);

    let inputLackVal = lack || 0;
    let inputBrokenVal = broken || 0;
    let inputDefectVal = defect || 0;
    let inputOverageVal = overage || 0;
    let validatedValue;

    switch (event.target.name) {
      case 'lack' :
        valuesSum =
          Number(inputBrokenVal) +
          Number(inputDefectVal);
        if (amountNum < valuesSum + enteredValue) {
          validatedValue = amountNum - valuesSum;
          inputLackVal = validatedValue;
        } else {
          inputLackVal = enteredValue;
        }
        break;
      case 'broken' :
        valuesSum =
          Number(inputLackVal) +
          Number(inputDefectVal);
        if (amountNum < valuesSum + enteredValue) {
          validatedValue = amountNum - valuesSum;
          inputBrokenVal = validatedValue;
        } else {
          inputBrokenVal = enteredValue;
        }
        break;
      case 'defect' :
        valuesSum =
          Number(inputLackVal) +
          Number(inputBrokenVal);
        if (amountNum < valuesSum + enteredValue) {
          validatedValue = amountNum - valuesSum;
          inputDefectVal = validatedValue;
        } else {
          inputDefectVal = enteredValue;
        }
        break;
      case 'overage' :
        inputOverageVal = enteredValue;
        break;
      default:
        break;
    }

    dispatch(pretensionsActions.addEditedPretension(
      pretensionsStore.productsList,
      pretensionsStore.editedList,
      series,
      inputLackVal,
      inputBrokenVal,
      inputDefectVal,
      inputOverageVal,
      price,
      seriesuid,
    ))
  }

  const invalidBadge = () => {
    if (invalid) {
      return (
        <div className="invalid-badge">
          <i className="icon-caution"/>
          <div className="invalid-badge__tick">
            Претензия №{invalid}
          </div>
        </div>
      )
    }
    return null;
  };


  const itemRemove = () => {
    dispatch(
      pretensionsActions.addEditedPretension(
      pretensionsStore.productsList,
      pretensionsStore.editedList,
      series,
      "",
      "",
      "",
      "",
      price,
      seriesuid,
    ));
  }



  if (pretensionsStore.tabs === 1 && valuesSum <= 0) {
    return null;
  }
  return (
    <li className={`pretension-product__item
    ${pretensionsStore.tabs === 1 && valuesSum <= 0 ? 'hidden' : ''}`}>
      <div className="pretension-product__item-col col-name">
        {name}
      </div>
      <div className="pretension-product__item-col col-series">
        {series}
      </div>
      <div className="pretension-product__item-col col-amount">
        {amount}
      </div>
      <div className="pretension-product__item-col col-price">
        {price} грн
      </div>
      <div className="pretension-product__item-col col-caution">
        {invalidBadge()}
      </div>
      <div className="pretension-product__item-col col-lack">
        <input type="text"
               value={lack || ""}
               name="lack"
               disabled={invalid || false}
               onInput={(ev) => onInputFunc(ev)}/>
      </div>
      <div className="pretension-product__item-col col-broken">
        <input type="text"
               value={broken || ""}
               name="broken"
               disabled={invalid || false}
               onInput={(ev) => onInputFunc(ev)}/>
      </div>
      <div className="pretension-product__item-col col-defect">
        <input type="text"
               value={defect || ""}
               name="defect"
               disabled={invalid || false}
               onInput={(ev) => onInputFunc(ev)}/>
      </div>
      <div className="pretension-product__item-col col-overage">
        <input type="text"
               value={overage || ""}
               name="overage"
               disabled={invalid || false}
               onInput={(ev) => onInputFunc(ev)}/>
      </div>
      <div className="pretension-product__item-col col-indicate">
        {pretensionsStore.tabs === 0 ? (
          <i className={`icon-status-done ${valuesSum > 0 ? "active" : ""}`}/>
        ) : (
          <button type="button" className="pretension-product__item-button-remove" onClick={itemRemove}>
            <i className="icon-close-thin"/>
          </button>
        )}
      </div>
    </li>
  );
};

export default PretensionProductItem;
