import factureTypes from '../types/facture';

const initialStore = {
  certificateList: [],
  currentPage: 1,
  perPage: 10,
  invoiceNum: '',
  currentFacture: '',
  currentFactureDate: '',
  currentFactureDateRaw: '',
  currentFactureUid: '',
  pageCount: 0,
  searchDate: undefined,
  searchFactureNum: '',
  searchErrorNum: 0,
  goodSearchText: '',
  goodSearchSeries: '',
  goodSearchHeadline: '',
  goodSearchError: false,
  infoPage: '',
  infoPageTitle: '',
  preloader: false,
  facturesSortColumn: '',
  facturesSortDirection: '',
};

const factureStore = (state = initialStore, action) => {
  switch (action.type) {
    case factureTypes.SET_CERTIFICATE_LIST:
      return {
        ...state,
        certificateList: action.array,
      };
    case factureTypes.SET_CURRENT_FACTURE_DATE:
      return {
        ...state,
        currentFactureDate: action.date,
      };
    case factureTypes.SET_CURRENT_FACTURE_DATE_RAW:
      return {
        ...state,
        currentFactureDateRaw: action.date,
      };
    case factureTypes.SET_GOOD_HEADLINE:
      return {
        ...state,
        goodSearchHeadline: action.text,
      };
    case factureTypes.SET_GOOD_SEARCH:
      return {
        ...state,
        goodSearchText: action.str,
      };
    case factureTypes.SET_GOOD_SEARCH_SERIES:
      return {
        ...state,
        goodSearchSeries: action.str,
      };
    case factureTypes.SET_GOOD_SEARCH_ERROR:
      return {
        ...state,
        goodSearchError: action.bool,
      };
    case factureTypes.SET_SEARCH_ERROR_NUM:
      return {
        ...state,
        searchErrorNum: action.num,
      };
    case factureTypes.SET_SEARCH_DATE:
      return {
        ...state,
        searchDate: action.date,
      };
    case factureTypes.SET_SEARCH_FACTURE_NUM:
      return {
        ...state,
        searchFactureNum: action.num,
      };
    case factureTypes.SET_CURRENT_FACTURE_UID:
      return {
        ...state,
        currentFactureUid: action.uid,
      };
    case factureTypes.SET_INFO_PAGE_TITLE:
      return {
        ...state,
        infoPageTitle: action.title,
      };
    case factureTypes.SET_INFO_PAGE:
      return {
        ...state,
        infoPage: action.str,
      };
    case factureTypes.SET_FACTURES_PER_PAGE:
      return {
        ...state,
        perPage: action.num,
      };
    case factureTypes.SET_FACTURES_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.num,
      };
    case factureTypes.SET_CURRENT_FACTURE:
      return {
        ...state,
        currentFacture: action.value,
      };
    case factureTypes.SET_INVOICE_NUM:
      return {
        ...state,
        invoiceNum: action.num,
      };
    case factureTypes.SET_FACTURES_PAGE:
      return {
        ...state,
        currentPage: action.num,
      };
    case factureTypes.SET_PRELOADER:
      return {
        ...state,
        preloader: action.value,
      };
    case factureTypes.SET_FACTURES_SORT_COL:
      return {
        ...state,
        facturesSortColumn: action.value,
      };
    case factureTypes.SET_FACTURES_SORT_DIR:
      return {
        ...state,
        facturesSortDirection: action.value,
      };
    default:
      return state;
  }
};

export default factureStore;
