
const SET_REPORT_MODAL = 'SET_REPORT_MODAL';
const SET_REPORT_MODAL_STEP = 'SET_REPORT_MODAL_STEP';
const SET_REPORT_DATE_START = 'SET_REPORT_DATE_START';
const SET_REPORT_DATE_END = 'SET_REPORT_DATE_END';
const SET_REPORT_DATA_TYPES = 'SET_REPORT_DATA_TYPES';
const SET_REPORT_ADDRESSES = 'SET_REPORT_ADDRESSES';
const SET_REPORT_ADDRESSES_CHECKED = 'SET_REPORT_ADDRESSES_CHECKED';
const SET_REPORT_ADDRESSES_ALL = 'SET_REPORT_ADDRESSES_ALL';
const SET_MODAL_PRELOADER = 'SET_MODAL_PRELOADER';
const SET_REPORT_ERROR = 'SET_REPORT_ERROR';

const combineConst = {
  SET_REPORT_MODAL,
  SET_REPORT_MODAL_STEP,
  SET_REPORT_DATE_START,
  SET_REPORT_DATE_END,
  SET_REPORT_DATA_TYPES,
  SET_REPORT_ADDRESSES,
  SET_REPORT_ADDRESSES_CHECKED,
  SET_REPORT_ADDRESSES_ALL,
  SET_MODAL_PRELOADER,
  SET_REPORT_ERROR
};

export default combineConst;

