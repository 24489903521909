// made from pretensions List
import React, {useEffect} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import ReactPaginate from "react-paginate";
import Preloader from "../Preloader/Preloader";
import MailItem from "./MailItem";
import mailsActions from '../../store/actions/mails';
import MailsFilter from "./MailsFilter";
import CountList from "../CountList/CountList";
import menuActions from "../../store/actions/menu";
import Checkbox from "../Checkbox/Checkbox";



const MailsList = () => {
  const mailsStore = useSelector((state) => state.mailsStore);
  const configStore    = useSelector((store) => store.configStore);
  const deliveryStore  = useSelector((store) => store.deliveryStore);
  const dispatch       = useDispatch();


  const textPeriod = {
    ua : 'За період з',
    ru : 'За период с',
  };
  const textFound = {
    ua : 'знайдено листів',
    ru : 'найдено писем:',
  }
  const noResults = {
    ua : 'Відсутні результати за даними параметрами пошуку',
    ru : 'Нет результатов по данным параметрам поиска',
  }
  const btnNumber = {
    ua : '№ листа',
    ru : '№ письма',
  }
  const btnType = {
    ua : 'Тип листа',
    ru : 'Тип письма',
  }
  const chooseAll = {
    ua : 'Обрати усі',
    ru : 'Выбрать все',
  }
  const labelChosen = {
    ua : 'Обрано листів:',
    ru : 'Выбрано писем:',
  }
  const btnDownload = {
    ua : 'Завантажити',
    ru : 'Загрузить',
  }

  const listMails = mailsStore.mailsList.map((elem) => (
    <MailItem
      key={elem.uid}
      uid={elem.uid}
      docnum={elem.docnum}
      date={elem.date}
      type={elem.type}
      isnew={elem.isnew}
    />
  ));

  const sortMails = (column) => {
    let direction = mailsStore.mailsSortDirection;
    if (direction === 'DESC' || direction === '') {
      direction = 'ASC';
      dispatch(mailsActions.setMailsSortDirection('ASC'));
    } else {
      direction = 'DESC';
      dispatch(mailsActions.setMailsSortDirection('DESC'));
    }
    dispatch(mailsActions.setMailsSortColumn(column));
    dispatch(
      mailsActions.fetchMails(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        mailsStore.dateStart,
        mailsStore.dateEnd,
        mailsStore.searchNum,
        mailsStore.mailsType,
        column,
        direction,
        mailsStore.mailsPerPage,
        1,
        mailsStore.mailsOnlyNew,
      ),
    );
  };

  const changePerPage = (num) => {
    dispatch(mailsActions.setMailsPage(1));
    dispatch(mailsActions.setMailsPerPage(num));
  };

  const pageChange = (data) => {
    dispatch(mailsActions.setMailsPage(data.selected + 1));
  };

  const checkAllMails = () => {
    const array = mailsStore.mailsList.map((elem) => elem.uid);
    if (mailsStore.allChecked) {
      dispatch(mailsActions.toggleAllMails([], mailsStore.mailsList));
    } else {
      dispatch(mailsActions.toggleAllMails(array, mailsStore.mailsList));
    }
  };

  // const downloadAllMails = () => {
  //   mailsStore.mailsDownloadList.map((elem, index) => (
  //     setTimeout(dispatch(mailsActions.downloadMails(elem, mailsStore.mailsList)), index*5000)
  //   ));
  // };

  const downloadAllMails = () => {
    mailsStore.mailsDownloadList.forEach((elem, i) => {
      setTimeout(() => {
        dispatch(mailsActions.downloadMails(elem, mailsStore.mailsList))
      }, i * 1000);
    });
  };


  useEffect(() => {
    if (deliveryStore.selectCityUid !== '') {
      dispatch(
        mailsActions.fetchMails(
          configStore.isCurrentLangUa,
          deliveryStore.selectCityUid,
          mailsStore.dateStart,
          mailsStore.dateEnd,
          mailsStore.searchNum,
          mailsStore.mailsType,
          mailsStore.mailsSortColumn,
          mailsStore.mailsSortDirection,
          mailsStore.mailsPerPage,
          mailsStore.mailsPage,
          mailsStore.mailsOnlyNew,
        ),
      )
    }
  }, [
    configStore.isCurrentLangUa,
    deliveryStore.selectCityUid,
    mailsStore.mailsPerPage,
    mailsStore.mailsPage,
  ]);

  useEffect(() => {
    dispatch(menuActions.fetchMenu(configStore.isCurrentLangUa, deliveryStore.selectCityUid));
  }, [
    deliveryStore.selectCityUid,
  ]);

  return (
    <div className={`mails-list ${mailsStore.mailsTotalCount > 0 ? '' : 'empty'}`}>
      <Preloader/>
      <div className="mails-list__notification">
        <i className="icon-info"/>
        <span className="has-results">
          {configStore.isCurrentLangUa ? textPeriod.ua : textPeriod.ru} {mailsStore.dateStartDisplay}{' '}
          по {mailsStore.dateEndDisplay}{' '}
          {configStore.isCurrentLangUa ? textFound.ua : textFound.ru} {mailsStore.mailsTotalCount}</span>
        <span className="no-results">{configStore.isCurrentLangUa ? noResults.ua : noResults.ru}</span>
      </div>
      <div className="mails-list__header">
        <div className="mails-list__header-item col-check">
          <Checkbox
            checkedState={mailsStore.allChecked}
            id="rightListCheck"
            checkedAction={() => checkAllMails()}
          />
        </div>
        <div className="mails-list__header-item col-number">
          <button type="button"
                  className="mails-list__header-item-text"
                  onClick={() => sortMails('DOCNUM')}>
            {configStore.isCurrentLangUa ? btnNumber.ua : btnNumber.ru}
          </button>
        </div>
        <div className="mails-list__header-item col-date">
          <button type="button"
                  className="mails-list__header-item-text"
                  onClick={() => sortMails('date')}>
            Дата
          </button>
        </div>
        <div className="mails-list__header-item col-type">
          <button type="button"
                  className="mails-list__header-item-text"
                  onClick={() => sortMails('type')}>
            {configStore.isCurrentLangUa ? btnType.ua : btnType.ru}
          </button>
        </div>
        <div className="mails-list__header-item col-btn">
          <MailsFilter/>
        </div>
      </div>
      <ul className="mails-list__list">
        {listMails}
      </ul>
      <div className="mails-list__footer">
        <div className="mails-list__footer-check">
          <Checkbox
            checkedState={mailsStore.allChecked}
            id="rightListCheck"
            checkedAction={() => checkAllMails()}
          />
          {configStore.isCurrentLangUa ? chooseAll.ua : chooseAll.ru}
        </div>
        <div className="mails-list__footer-checked">
          {configStore.isCurrentLangUa ? labelChosen.ua : labelChosen.ru}
          <div className="footer-checked__val">
            <span>
              {mailsStore.mailsDownloadList.length}
            </span>
          </div>
        </div>
        <div className="mails-list__footer-download">
          <button
            type="button"
            className="footer-download__button"
            onClick={() => downloadAllMails()}
          >
            <i className="icon-download"/>
            {configStore.isCurrentLangUa ? btnDownload.ua : btnDownload.ru}
          </button>
        </div>
      </div>
      <div className="mails-list__bottom-nav">
        <CountList onClick={changePerPage} currentPerPage={mailsStore.mailsPerPage} countOf="mails"/>
        <ReactPaginate
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={mailsStore.mailsPageCount}
          forcePage={mailsStore.mailsPage - 1}
          containerClassName="pager"
          pageLinkClassName="pager__button"
          activeLinkClassName="pager__button--active"
          previousLinkClassName="icon-arrow pager__arrow-prev"
          nextLinkClassName="icon-arrow pager__arrow-next"
          onPageChange={pageChange}
        />
      </div>
    </div>
  );
};

export default MailsList;
