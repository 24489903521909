import loginTypes from '../types/login';

const initialStore = {
  headline: '',
  bottomText: '',
  pageUtp: [],
  formHeadline: '',
  isRememberChecked: true,
  formText: '',
  formLogin: '',
  formPassword: '',
  formError: '',
  loginPreloader: false,
};

const loginStore = (state = initialStore, action) => {
  switch (action.type) {
    case loginTypes.SET_UTP:
      return {
        ...state,
        pageUtp: action.utpArray,
      };
    case loginTypes.SET_REMEMBER_CHECK:
      return {
        ...state,
        isRememberChecked: action.check,
      };
    case loginTypes.SET_LOGIN_VALUE:
      return {
        ...state,
        formLogin: action.value,
      };
    case loginTypes.SET_LOGIN_PRELOADER:
      return {
        ...state,
        loginPreloader: action.value,
      };
    case loginTypes.SET_FORM_ERROR:
      return {
        ...state,
        formError: action.errorText,
      };
    case loginTypes.SET_PASSWORD_VALUE:
      return {
        ...state,
        formPassword: action.value,
      };
    case loginTypes.SET_FORM_HEADLINE:
      return {
        ...state,
        formHeadline: action.headline,
      };
    case loginTypes.SET_FORM_TEXT:
      return {
        ...state,
        formText: action.text,
      };
    case loginTypes.SET_HEADLINE:
      return {
        ...state,
        headline: action.headline,
      };
    case loginTypes.SET_BOTTOM_TEXT:
      return {
        ...state,
        bottomText: action.text,
      };
    default:
      return state;
  }
};

export default loginStore;
