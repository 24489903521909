import React, {
	useEffect,
	useRef
} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import uk from 'date-fns/locale/uk';
import facturesActions from '../../store/actions/factures';
import certificatesAction from "../../store/actions/certificates";

const searchText       = {
	ua : 'Пошук',
	ru : 'Поиск',
};
const numFactureText   = {
	ua : '№ фактури',
	ru : '№ фактуры',
};
const andOrText        = {
	ua : 'і/або',
	ru : 'и/или',
};
const nameText         = {
	ua : 'Найменування товару',
	ru : 'Название товара',
};
const floatProductInfo = {
	ua : 'Введіть 3 або більше символів',
	ru : 'Введите 3 или более символов',
};
const seriesText       = {
	ua : 'Серія',
	ru : 'Серия',
};
const floatFactureInfo = {
	ua : 'Введіть 3 або більше символів. Допустимі символи: "-", "/" та цифри.',
	ru : 'Введите 3 или более символов. Допустимые символы: "-", "/" и цифры.',
};

const SearchCertificates = () => {
	const factureStore  = useSelector((state) => state.factureStore);
  const certificatesStore  = useSelector((state) => state.certificatesStore);
	const deliveryStore = useSelector((store) => store.deliveryStore);
	const configStore   = useSelector((store) => store.configStore);
	const dispatch      = useDispatch();
	const datepicker    = useRef(null);
	useEffect(
		() => () => {
			dispatch(facturesActions.setSearchDate(undefined));
			dispatch(facturesActions.setSearchNum(''));
			dispatch(facturesActions.setGoodSearchText(''));
			dispatch(facturesActions.setGoodSearchSeries(''));
		},
		[],
	);

	const focusHiddenDatepicker = () => {
		datepicker.current.input.focus();
	};

	const setDate = (date) => {
		dispatch(facturesActions.setGoodSearchText(''));
		dispatch(facturesActions.setGoodSearchSeries(''));
		dispatch(facturesActions.setSearchDate(date));
	};

	const setFactureNum = (ev, num) => {
		dispatch(facturesActions.setGoodSearchText(''));
		dispatch(facturesActions.setGoodSearchSeries(''));
		ev.preventDefault();
		const regexp = new RegExp('^[0-9/-]+$');

		if (num.match(regexp) !== null || num === '') {
			dispatch(facturesActions.setSearchNum(num));
		}
	};

	const submitFormProduct = (ev) => {
		ev.preventDefault();
		if (factureStore.searchFactureNum && factureStore.searchDate ){
      dispatch(
        certificatesAction.searchCertificatesOld(
          configStore.isCurrentLangUa,
          deliveryStore.selectCityUid,
          factureStore.searchFactureNum,
          factureStore.searchDate,
        ),
      );
      dispatch(
        facturesActions.fetchFactures(
          configStore.isCurrentLangUa,
          deliveryStore.selectCityUid,
          factureStore.perPage,
          factureStore.page,
          factureStore.searchFactureNum,
          undefined,
        ),
      );
    } else {
      dispatch(
        facturesActions.fetchFactures(
          configStore.isCurrentLangUa,
          deliveryStore.selectCityUid,
          factureStore.perPage,
          factureStore.page,
          factureStore.searchFactureNum,
          factureStore.searchDate,
        ),
      );
      dispatch(
        certificatesAction.fetchCertificates(
          configStore.isCurrentLangUa,
          deliveryStore.selectCityUid,
          '',
          '',
        ),
      );
    }
	};

	const resetFactureNum = () => {
		dispatch(facturesActions.setSearchNum(''));
		dispatch(
			facturesActions.fetchFactures(
				configStore.isCurrentLangUa,
				deliveryStore.selectCityUid,
				factureStore.perPage,
				factureStore.currentPage,
				'',
				factureStore.searchDate,
			),
		);
    dispatch(
      certificatesAction.fetchCertificates(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        '',
        factureStore.searchDate,
      ),
    );
	};

	const resetDate = () => {
		dispatch(facturesActions.setSearchDate(undefined));
		dispatch(
			facturesActions.fetchFactures(
				configStore.isCurrentLangUa,
				deliveryStore.selectCityUid,
				factureStore.perPage,
				factureStore.currentPage,
				factureStore.searchFactureNum,
				undefined,
			),
		);
    dispatch(
      certificatesAction.fetchCertificates(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        factureStore.searchFactureNum,
        '',
      ),
    );
	};

	const resetGoodText = () => {
		dispatch(facturesActions.setGoodSearchText(''));
		dispatch(facturesActions.setGoodSearchSeries(''));
	};
	const resetSeries = () => {
		dispatch(facturesActions.setGoodSearchSeries(''));
	};

	const setGoodProduct = (ev, text) => {
		dispatch(facturesActions.setSearchNum(''));
		dispatch(facturesActions.setSearchDate(undefined));
		ev.preventDefault();
		if (text === '') {
			dispatch(facturesActions.setGoodSearchSeries(''));
		}
		dispatch(facturesActions.setGoodSearchText(text));
	};

	const setGoodSeries = (ev, text) => {
		dispatch(facturesActions.setSearchNum(''));
		dispatch(facturesActions.setSearchDate(undefined));
		ev.preventDefault();
		dispatch(facturesActions.setGoodSearchSeries(text));
	};

	const submitGoodForm = (ev) => {
		dispatch(facturesActions.setSearchDate(undefined));
		dispatch(facturesActions.setSearchNum(''));
		dispatch(facturesActions.setPerPage(10));
		dispatch(facturesActions.setCurrentPage(1));
		dispatch(facturesActions.setSearchErrorNum(0));
		dispatch(facturesActions.setCurrentFacture(''));
		dispatch(facturesActions.setCurrentFactureDate(''));
		dispatch(facturesActions.setCurrentFactureUid(''));
		ev.preventDefault();
		if (factureStore.goodSearchText === '') {
			return;
		}
		dispatch(
			facturesActions.searchGood(
				configStore.isCurrentLangUa,
				deliveryStore.selectCityUid,
				factureStore.goodSearchText,
				factureStore.goodSearchSeries,
			),
		);
	};

	const handleChangeRaw = (ev, value) => {
		const regexp = new RegExp(
			/[a-zA-Zа-яА-Я!@#$%^&*()_+=?,/]/g
		);
		if (regexp.test(value) || value.length > 10) {
			ev.preventDefault();
		}
	};

	let searchError;
	if (factureStore.searchFactureNum && factureStore.searchDate) {
	  if (certificatesStore.certSearchErrorNum > 0) {
      searchError = true;
    } else {
      searchError = false;
    }
  } else if (factureStore.searchErrorNum > 1) {
      searchError = true;
  } else {
    searchError = false;
  }

	return (
		<div className="search-certificate">
			<p className="search-certificate__headline">
				{configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
			</p>
			<form onSubmit={(ev) => submitFormProduct(ev)}
			      className="search-certificate__form-product">
				<div className="search-certificate__input-product-wrapper">
					<span className="float-info">
						{configStore.isCurrentLangUa ? floatFactureInfo.ua : floatFactureInfo.ru}
					</span>
					<input
						pattern="^[0-9/-]+$"
						className={`search-certificate__input-product
						${searchError ? 'search-certificate__input-product-error' : ''}
						`}
						type="text"
						placeholder={configStore.isCurrentLangUa
							? numFactureText.ua : numFactureText.ru}
						onInput={(ev) => setFactureNum(ev, ev.target.value)}
						value={factureStore.searchFactureNum}
					/>
					{factureStore.searchFactureNum !== '' && (
						<button
							onClick={resetFactureNum}
							type="button"
							className="search-certificate__button-reset search-certificate__button-reset-facture">
							<i className="icon-cross"/>
						</button>
					)}
				</div>
				<div
					className={`search-certificate__calendar ${
            searchError ? 'search-certificate__input-product-error' : ''
						}`}
				>
					<div className="react-datepicker-wrapper">
						<div className="react-datepicker__input-container">
							<DatePicker
								locale={configStore.isCurrentLangUa ? uk : ru}
								ref={datepicker}
								placeholderText="Дата"
								selected={factureStore.searchDate}
								onChange={(date) => setDate(date)}
								dateFormat="dd.MM.yyyy"
								onChangeRaw={event => handleChangeRaw(event, event.target.value)}
							/>
						</div>
					</div>
					{factureStore.searchDate === undefined ? (
						<button
							onClick={focusHiddenDatepicker}
							type="button"
							className="search-certificate__calendar-icon"
						>
							<i className="icon-calendar"/>
						</button>
					) : (
						<button
							onClick={resetDate}
							type="button"
							className="search-certificate__button-reset"
						>
							<i className="icon-cross"/>
						</button>
					)}
				</div>
				<button type="submit" className="search-certificate__form-submit">
					<i className="icon-search search-certificate__form-submit-icon"/>
					<span className="search-certificate__form-submit-text">
						{configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
					</span>
				</button>
			</form>
			<span className="search-certificate__or-text">
				{configStore.isCurrentLangUa ? andOrText.ua : andOrText.ru}
			</span>
			<form onSubmit={(ev) => submitGoodForm(ev)} className="search-certificate__form-product">
				<div className="search-certificate__input-product-wrapper">
					<span className="float-info">
						{configStore.isCurrentLangUa ? floatProductInfo.ua : floatProductInfo.ru}
					</span>
					<div className="input-wrapper">
						{factureStore.goodSearchText !== '' && (
							<button
								onClick={resetGoodText}
								type="button"
								className="search-certificate__button-reset search-certificate__button-reset-facture">
								<i className="icon-cross"/>
							</button>
						)}
						<input
							className={`search-certificate__input-product ${
								factureStore.goodSearchError ? 'search-certificate__input-product-error' : ''
								}`}
							type="text"
							placeholder={configStore.isCurrentLangUa ? nameText.ua : nameText.ru}
							onInput={(event) => setGoodProduct(event, event.target.value)}
							value={factureStore.goodSearchText}
						/>
					</div>
				</div>
				<div className="input-wrapper series">
					{factureStore.goodSearchSeries !== '' && (
						<button
							onClick={resetSeries}
							type="button"
							className="search-certificate__button-reset search-certificate__button-reset-facture">
							<i className="icon-cross"/>
						</button>
					)}
					<input
						className={`search-certificate__input-series ${
							factureStore.goodSearchError ? 'search-certificate__input-product-error' : ''
							}`}
						type="text"
						placeholder={configStore.isCurrentLangUa ? seriesText.ua : seriesText.ru}
						onInput={(event) => setGoodSeries(event, event.target.value)}
						value={factureStore.goodSearchSeries}
						disabled={factureStore.goodSearchText === ''}
					/>
				</div>
				<button type="submit" className="search-certificate__form-submit">
					<i className="icon-search search-certificate__form-submit-icon"/>
					<span className="search-certificate__form-submit-text">
						{configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
					</span>
				</button>
			</form>
		</div>
	);
};

export default SearchCertificates;
