import React, {useEffect, useRef, useState} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import pretensionsActions from "../../store/actions/pretensions";



const PretensionModal = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore   = useSelector((store) => store.configStore);
  const dispatch      = useDispatch();

  const titleCaution = {
    ru : 'Внимание!',
    ua : 'Увага!',
  }

  let isOpen = false;

  let modalText  = {};
  if (pretensionsStore.pretensionsError === 'image_duplicate') {
    modalText = {
      ru : 'Изображение уже добавлено',
      ua : 'Зображення вже добавлене',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'image_out_of_limit') {
    modalText = {
      ru : 'Превышен лимит загружаемых изображений',
      ua : 'Перевищено ліміт завантажуваних зображень',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'image_out_of_format') {
    modalText = {
      ru : 'Недопустимый формат изображения',
      ua : 'Неприпустимий формат зображення',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'image_out_of_size') {
    modalText = {
      ru : 'Превышен размер загружаемого изображения',
      ua : 'Перевищено розмір завантажуваного зображення',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'images_upload_error') {
    modalText = {
      ru : 'Не удалось загрузить изображения',
      ua : 'Неможливо завантажити зображення',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'view_save_success') {
    modalText = {
      ru : 'Изменения успешно сохранены',
      ua : 'Зміни успішно збережені',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'short_facture_num') {
    modalText = {
      ru : 'Для поиска по номеру фактуры, введите в поисковое поле не менее трех символов.',
      ua : 'Для пошуку за номером фактури введіть у пошукове поле не менше трьох символів.',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'short_search_facture_num') {
    modalText = {
      ru : 'Для поиска претензии по номеру фактуры, введите в поисковое поле не менее трех символов.',
      ua : 'Для пошуку претензії за номером фактури введіть у пошукове поле не менше трьох символів.',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'pretension_creation_error') {
    modalText = {
      ru : 'При создании претензии произошел сбой. Обратитесь с проблемой к своему менеджеру компании Вента.',
      ua : 'Під час створення претензії стався збій. Зверніться з проблемою до свого менеджера компанії Вента.',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'pretension_delete_error') {
    modalText = {
      ru : 'При удалении черновика произошел сбой. Обратитесь с проблемой к своему менеджеру компании Вента.',
      ua : 'Під час видалення чернетки стався збій. Зверніться з проблемою до свого менеджера компанії Вента.',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'pretension_delete_success') {
    modalText = {
      ru : 'Черновик успешно удален.',
      ua : 'Чернетка успішно видалена.',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'pretension_success_send') {
    modalText = {
      ru : 'Данные о претензии отправлены. Для просмотра созданной претензии дождитесь ее статуса "В обработке".',
      ua : 'Дані про претензію надіслано. Щоб переглянути створену претензію, дочекайтеся її статусу "В обробці".',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'still_in_status_sent') {
    modalText = {
      ru : 'Претензия все еще в статусе "Отправлено". ' +
        'Для просмотра созданной претензии дождитесь ее статуса "В обработке".',
      ua : 'Претензія все ще у статусі "Відправлено". ' +
        'Щоб переглянути створену претензію, дочекайтеся її статусу "В обробці".',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'still_in_status_error') {
    modalText = {
      ru : 'Невозможно просмотреть ошибочную претензию',
      ua : 'Неможливо переглянути помилкову претензію',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'pretension_success_draft') {
    modalText = {
      ru : 'Претензия сохранена как черновик. Вы можете найти и отредактировать ее в списке претензий.',
      ua : 'Претензія збережена як чернетка. Ви можете знайти та відредагувати її у списку претензій.',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'pretension_no_comment') {
    modalText = {
      ru : 'Для оформления претензии, пожалуйста, заполните поле "Комментарий к претензии".',
      ua : 'Для оформлення претензії, будь ласка, заповніть поле "Коментар до претензії".',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'pretension_no_products') {
    modalText = {
      ru : 'Для оформления претензии, пожалуйста, заполните не менее одной позиции в списке продуктов фактуры.',
      ua : 'Для оформлення претензії, будь ласка, заповніть щонайменше одну позицію у списку продуктів фактури.',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'delete_image_success') {
    modalText = {
      ru : 'Загруженное ранее изображение успешно удалено',
      ua : 'Завантажене раніше зображення успішно видалено',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'delete_image_error') {
    modalText = {
      ru : 'Не удалось удалить изображение',
      ua : 'Неможливо видалити зображення',
    }
    isOpen = true;
  }
  if (pretensionsStore.pretensionsError === 'pretension_no_search_result') {
    modalText = {
      ru : 'Поиск по наименованию товара не дал результатов',
      ua : 'Пошук за найменуванням товару не дав результатів',
    }
    isOpen = true;
  }

  const closeModal = () => {
    isOpen = false;
    dispatch(pretensionsActions.setPretensionsError(''));
  }

  return (
    <div className={`pretension-modal ${isOpen ? 'open' : ''}`}>
      <div className="pretension-modal__block">
        <button type="button" className="pretension-modal__close" onClick={closeModal}>
          <i className="icon-close-thin"/>
        </button>
        <div className="pretension-modal__block-content">
          <div className="pretension-modal__block-title">
            <i className="icon-caution"/>
            {configStore.isCurrentLangUa ? titleCaution.ua : titleCaution.ru}
          </div>
          <div className="pretension-modal__block-content">
            {configStore.isCurrentLangUa ? modalText.ua : modalText.ru}
          </div>
        </div>
      </div>
    </div>
  );
}


export default PretensionModal;
