import React from 'react';
import { useSelector } from 'react-redux';
import preloader from '../../accets/images/loading.svg';

const Preloader = () => {
	const documentsStore = useSelector((store) => store.documentsStore);
	const factureStore = useSelector((store) => store.factureStore);
  const pretensionsStore = useSelector((store) => store.pretensionsStore);
  const mailsStore = useSelector((store) => store.mailsStore);

	let isActive = false;
	if (documentsStore.preloader
    || factureStore.preloader
    || pretensionsStore.preloader
    || mailsStore.mailsPreloader) {
		isActive = true;
	}

	return (
		<div
			className={`main_preloader ${isActive? 'main_preloader--active' : ''}`}
		>
			<img src={preloader} alt="" />
		</div>
	);
};



export default Preloader;
