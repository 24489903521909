import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import loginActions from '../../store/actions/login';

const Logout = () => {
  const title = 'Выход из кабинета VentaLTD';
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginActions.logout());
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="login__content" />
      <Redirect to="/" />
    </>
  );
};

export default Logout;
