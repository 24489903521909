import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import pretensionsActions from "../../store/actions/pretensions";

const StepNav = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore   = useSelector((store) => store.configStore);
  const dispatch      = useDispatch();

  const searchFacture = {
    ua : 'Пошук фактури',
    ru : 'Поиск фактуры',
  };
  const formPretension = {
    ua : 'Оформлення претензії',
    ru : 'Оформление претензии',
  };
  const cancel = {
    ua : 'Відміна',
    ru : 'Отмена',
  };

  const backToFacturesList = () => {
    dispatch(pretensionsActions.setPretensionsStep(1));
    dispatch(pretensionsActions.setSearchFactureNum(''));
    dispatch(pretensionsActions.setEditedList(new Map()));
    dispatch(pretensionsActions.setImagesList([]));
  }
  const backToChoose = () => {
    dispatch(pretensionsActions.setSearchFactureNum(''));
    dispatch(pretensionsActions.setEditedList(new Map()));
    dispatch(pretensionsActions.setCurrentPage(1));
    dispatch(pretensionsActions.setPretensionsSection(1))
    dispatch(pretensionsActions.setPretensionsStep(1))
  }

  return (
    <div className="step-nav">
      <button type="button" className={`step-nav__btn step1
              ${pretensionsStore.pretensionsStep === 1 ? 'active' : ''}`}
              onClick={() => backToFacturesList()}>
        <div className="number">1</div>
        <span>{configStore.isCurrentLangUa ? searchFacture.ua : searchFacture.ru}</span>
      </button>
      <div className={`step-nav__btn step2
              ${pretensionsStore.pretensionsStep === 2 ? 'active' : ''}`}>
        <div className="number">2</div>
        <span>{configStore.isCurrentLangUa ? formPretension.ua : formPretension.ru}</span>
      </div>
      <button type="button" className="step-nav__btn-close" onClick={() => backToChoose()}>
        <i className="icon-close-thin"/>
        <span>{configStore.isCurrentLangUa ? cancel.ua : cancel.ru}</span>
      </button>
    </div>
  );
}


export default StepNav;
