import React, {useEffect, useState} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import pretensionsActions from "../../store/actions/pretensions";
import ImageItem from "./ImageItem";

const ImagesUpload = (props) => {
  const configStore   = useSelector((store) => store.configStore);
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const dispatch      = useDispatch();

  const uploadButton = {
    ru : 'Добавить фото',
    ua : 'Додати фото',
  };

  const addImage = (event) => {
    const filesList = Array.from(event.target.files);
    filesList.forEach( file => {
      dispatch(pretensionsActions.addImage(
        pretensionsStore.imagesList,
        file,
        pretensionsStore.imagesClientList,
        pretensionsStore.totalImagesClient,
      ))
    });
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
  }

  const deleteImage = (elem) => {
    dispatch(pretensionsActions.deleteImage(
      pretensionsStore.imagesList,
      elem,
    ))
  }

  const listImages = pretensionsStore.imagesList.map((image) => (
    <ImageItem
      key={image.name}
      image={image}
      onClick={() => deleteImage(image)}
    />
  ));

  return (
    <div className="images-upload">
      <div className="images-list">
        {listImages}
      </div>
      <div className="images-input">
        <label htmlFor="upload-image">
          <i className="icon-photo-camera"/>
          <span>{configStore.isCurrentLangUa ? uploadButton.ua : uploadButton.ru}</span>
          <input type="file" id="upload-image" onChange={addImage} multiple/>
        </label>
      </div>
    </div>
  );
};

export default ImagesUpload;

