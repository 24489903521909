import React from 'react';
import {useDispatch, useSelector} from "react-redux";

const MailStatus = (props) => {
  const mailsStore = useSelector((state) => state.mailsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore    = useSelector((store) => store.configStore);
  const dispatch       = useDispatch();
  // eslint-disable-next-line
  const { status } = props;

  const statusBrak = {
    ua : 'Брак',
    ru : 'Брак',
  };
  const statusStop = {
    ua : 'Стоп',
    ru : 'Стоп',
  };
  const statusRecontrol = {
    ua : 'Переконтроль',
    ru : 'Переконтроль',
  };
  const statusResponse = {
    ua : 'Відклик',
    ru : 'Отзыв',
  };

  const mailStatus = () => {
    switch (status) {
      case 'Brak':
        return (
          <div className='mail-status status-brak'>
            <i className="icon-status-error"/>
            <span>{configStore.isCurrentLangUa ? statusBrak.ua : statusBrak.ru}</span>
          </div>
        );
      case 'Stop':
        return (
          <div className='mail-status status-stop'>
            <i className="icon-stop"/>
            <span>{configStore.isCurrentLangUa ? statusStop.ua : statusStop.ru}</span>
          </div>
        );
      case 'Recontrol':
        return (
          <div className='mail-status status-recontrol'>
            <i className="icon-search"/>
            <span>{configStore.isCurrentLangUa ? statusRecontrol.ua : statusRecontrol.ru}</span>
          </div>
        );
      case 'Response':
        return (
          <div className='mail-status status-response'>
            <i className="icon-return-1"/>
            <span>{configStore.isCurrentLangUa ? statusResponse.ua : statusResponse.ru}</span>
          </div>
        );
      default:
        return '';
    }
  };

  return (
    mailStatus()
  );
};

export default MailStatus;
