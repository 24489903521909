import React from 'react';
import { useSelector } from 'react-redux';
import Logo from './Logo';
import Email from './Email';
import LanguageChanger from './LanguageChanger';
import LogoutLink from './LogoutLink';
import Delivery from './Delivery';
import background from '../../accets/images/headerBackground.jpg';
import PersonalArea from './PersonalArea';

const Login = () => {
  const configState = useSelector((store) => store.configStore);

  return (
    <header className="header" style={{ backgroundImage: `url(${background})` }}>
      <div className="header__container container">
        <Logo />
        <Email />
        {configState.isUserAuth && <Delivery />}
        {configState.isUserAuth && <PersonalArea />}
        <LanguageChanger />
        <LogoutLink />
      </div>
    </header>
  );
};

export default Login;
