import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router';
import Login from './pages/Login/Login';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Logout from './pages/Logout/Logout';
import Certificates from './pages/Certificates/Certificates';
import Navigation from './components/Navigation/Navigation';
import navBack from './accets/images/navBackground.jpg';
import CertificatesInfo from './pages/Certificates/CertificatesInfo';
import PretensionsInfo from './pages/Pretensions/PretensionsInfo';
import Documents from './pages/Documents/Documents';
import DocumentsInfo from './pages/Documents/DocumentsInfo';
import Pretensions from "./pages/Pretensions/Pretensions";
import PretensionCreate from "./components/Pretensions/PretensionCreate";
import Mails from "./pages/Mails/Mails";
import MailsInfo from "./pages/Mails/MailsInfo";

const App = () => {
	const configState = useSelector((store) => store.configStore);

	return (
		<Router>
			<div className="App" style={{backgroundImage : `url(${navBack})`}}>
				<Header/>
				{!configState.isUserAuth ? (
					<div>
						<Switch>
							<Route path="*">
								<Login/>
							</Route>
						</Switch>
					</div>
				) : (
					<div>
						<div className="content-container">
							<Navigation/>
							<Switch>
								<Route exact path="/certificates">
									<Certificates/>
								</Route>
								<Route exact path="/certificates-help">
									<CertificatesInfo/>
								</Route>
								<Route exact path="/documents-help">
									<DocumentsInfo/>
								</Route>
                <Route exact path="/pretensions-help">
                  <PretensionsInfo/>
                </Route>
								<Route exact path="/documents">
									<Documents/>
								</Route>
                <Route exact path="/pretensy">
                  <Pretensions/>
                </Route>
                <Route exact path="/mails">
                  <Mails/>
                </Route>
                <Route exact path="/mails-help">
                  <MailsInfo/>
                </Route>
								<Route exact path="/">
									<Redirect to="/certificates"/>
								</Route>
								<Route path="/logout">
									<Logout/>
								</Route>
							</Switch>
						</div>
					</div>
				)}
				<Footer/>
			</div>
		</Router>
	);
};

export default App;
