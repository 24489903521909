import documentsTypes from '../types/documents';
import api from '../../api/api';
import configActions from './config';
import factureTypes from "../types/facture";

const setDocumentsList = (array) => ({
	type : documentsTypes.SET_DOCUMENTS_LIST,
	array,
});

const setCurrentPage = (num) => ({
	type : documentsTypes.SET_PAGE,
	num,
});

const setPageCount = (num) => ({
	type : documentsTypes.SET_PAGE_COUNT,
	num,
});

const setPerPage = (num) => ({
	type : documentsTypes.SET_PER_PAGE,
	num,
});

const setInvoiceNum = (num) => ({
	type : documentsTypes.SET_INVOICE_NUM,
	num,
});

const setInfoPage = (str) => ({
	type : documentsTypes.SET_INFO_PAGE,
	str,
});

const setInfoPageTitle = (title) => ({
	type : documentsTypes.SET_INFO_PAGE_TITLE,
	title,
});

const setSearchNum = (num) => ({
	type : documentsTypes.SET_SEARCH_NUM,
	num,
});

const setSearchErrorNum = (num) => ({
	type: factureTypes.SET_SEARCH_ERROR_NUM,
	num,
});

const setDocRightHeadline = (text) => ({
	type : documentsTypes.SET_DOCUMENTS_RIGHT_HEADLINE,
	text,
});

const setDocRightContent = (text) => ({
	type : documentsTypes.SET_DOCUMENTS_RIGHT_CONTENT,
	text,
});

const setDocRightCheckboxError = (bool) => ({
	type : documentsTypes.SET_RIGHT_CHECKBOX_ERROR,
	bool,
});

const setDocRightCheckbox = (bool) => {
	localStorage.setItem('documentsCheck', bool);
	return {
		type : documentsTypes.SET_RIGHT_CHECKBOX,
		bool,
	};
};
const setPreloader = (value) => ({
	type: documentsTypes.SET_PRELOADER,
	value,
});
const setDocumentsSortCol = (value) => ({
	type: documentsTypes.SET_DOCUMENTS_SORT_COL,
	value,
});
const setDocumentsSortDir = (value) => ({
	type: documentsTypes.SET_DOCUMENTS_SORT_DIR,
	value,
});

const fetchDocumentsRight = (lang) => async (dispatch) => {
  const data = new FormData();
  data.append('sys_title', 'docinfo')
	const response = await api.post(`${lang ? 'ua' : ''}/api/react/getblock`, data);

	await dispatch(setDocRightHeadline(response.data.result.block.title));
	await dispatch(setDocRightContent(response.data.result.block.content));
};

const fetchFactures = (lang, uid, perPage, page, docNum, sortColumn, sortDirection) => async (dispatch) => {
	dispatch(setPreloader(true));
	const data = new FormData();
	if (sortColumn && sortDirection) {
		data.append('SortColumn', sortColumn);
		data.append('SortDirection', sortDirection);
	}
	data.append('per_page', perPage);
	data.append('AutoDestUID', uid);
	if (page) {
		data.append('page', page);
	}
	if (docNum) {
		data.append('docnum', docNum);
	}

	const response = await api.post(`${lang ? 'ua' : ''}/api/react/documents`, data);

	if (response.data.login === false) {
		await dispatch(configActions.setLoginBool(false));
		return null;
	}

	if (response.data.error) {
		await dispatch(setSearchErrorNum(1));
	}else{
		if (docNum) {
			await dispatch(setSearchNum(docNum));
		}
		await dispatch(setDocumentsList(response.data.result.list));
		await dispatch(setPageCount(response.data.result.total_pages));
		await dispatch(setSearchErrorNum(0));
	}
    dispatch(setPreloader(false));
	return null;
};

const fetchDocumentsInfo = (lang) => async (dispatch) => {
	const data = new FormData();

	data.append('url_key', 'instruction-documents');

	const response = await api.post(`${lang ? 'ua' : ''}/api/react/getpage`, data);

	if (response.data.login === false) {
		await dispatch(configActions.setLoginBool(false));
		return null;
	}

	dispatch(setInfoPage(response.data.structure.content));
	dispatch(setInfoPageTitle(response.data.structure.title));
	return null;
};

const downloadDocuments = (uid) => async (dispatch) => {
	dispatch(setPreloader(true));
	const data = new FormData();

	data.append('ObjectUID', uid);

	const response = await api.post('ua/api/react/documentfiles', data, {
		responseType : 'blob',
	});

	if (response.data.login === false) {
		await dispatch(configActions.setLoginBool(false));
		return null;
	}

	const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
	const link        = document.createElement('a');
	link.href         = downloadUrl;
	link.setAttribute('download', 'file.pdf');
	document.body.appendChild(link);
	link.click();
	link.remove();
	dispatch(setPreloader(false));
	return null;
};

export default {
	fetchFactures,
	setInvoiceNum,
	setPerPage,
	setPageCount,
	setDocRightHeadline,
	setCurrentPage,
	setDocRightCheckbox,
	fetchDocumentsInfo,
	fetchDocumentsRight,
	setDocRightContent,
	setSearchNum,
	setSearchErrorNum,
	setDocRightCheckboxError,
	downloadDocuments,
	setDocumentsList,
	setPreloader,
	setDocumentsSortCol,
	setDocumentsSortDir,
};
