import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import pretensionsActions from "../../store/actions/pretensions";

const PretensionFactureItem = (props) => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();
  const { number, date, uid } = props;

  const formButton = {
    ua : 'Оформити претензію',
    ru : 'Оформить претензию',
  };

  const pretensionStepFormalize = () => {
    dispatch(pretensionsActions.setEditedList(new Map()));
    dispatch(pretensionsActions.setPretensionFactureNum(number));
    dispatch(pretensionsActions.setPretensionFactureDate(date));
    dispatch(pretensionsActions.setPretensionFactureUid(uid));
    dispatch(pretensionsActions.setPretensionComment(''));
    dispatch(pretensionsActions.setCurrentPretensionUid(''));
    dispatch(pretensionsActions.setCurrentPretension({}));
    dispatch(pretensionsActions.setPretensionsStep(2));
  };

  return (
    <li className="pretensions-facture__item">
      <div className="pretensions-facture__item-col col-facture">
        {number}
      </div>
      <div className="pretensions-facture__item-col col-date">
        {date}
      </div>
      <div className="pretensions-facture__content col-content">
        <button type="button" className="btn-pretension-create" onClick={() => pretensionStepFormalize({number})}>
          <i className="icon-hammer"/>
          {configStore.isCurrentLangUa ? formButton.ua : formButton.ru}
        </button>
      </div>
    </li>
  );
};

export default PretensionFactureItem;
