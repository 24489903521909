const SET_CERTIFICATES_LIST = 'SET_CERTIFICATES_LIST';
const SET_CERTIFICATE_DATE = 'SET_CERTIFICATE_DATE';
const SET_CERTIFICATE_SELECTED_LIST = 'SET_CERTIFICATE_SELECTED_LIST';
const SET_CERTIFICATE_NUM = 'SET_CERTIFICATE_NUM';
const SET_ALL_ITEMS_CHECKED = 'SET_ALL_ITEMS_CHECKED';
const SET_RIGHT_PRELOADER = 'SET_RIGHT_PRELOADER';
const SET_CERT_SEARCH_ERROR_NUM = 'SET_CERT_SEARCH_ERROR_NUM';

const combineConst = {
  SET_CERTIFICATES_LIST,
  SET_CERTIFICATE_SELECTED_LIST,
  SET_CERTIFICATE_NUM,
  SET_ALL_ITEMS_CHECKED,
  SET_CERTIFICATE_DATE,
  SET_RIGHT_PRELOADER,
  SET_CERT_SEARCH_ERROR_NUM,
};

export default combineConst;
