import React from 'react';
import { useDispatch } from 'react-redux';
import documentsActions from '../../store/actions/documents';

const DocumentItem = (props) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const { number, date, isActive, onClick, uid, price } = props;

  const downloadFiles = (event) => {
    event.stopPropagation();
    if (localStorage.getItem('documentsCheck') !== null && localStorage.getItem('documentsCheck')) {
      dispatch(documentsActions.downloadDocuments(uid));
      dispatch(documentsActions.setDocRightCheckboxError(false));
    } else {
      dispatch(documentsActions.setDocRightCheckboxError(true));
    }
  };

  return (
    <li className={`certificate-item ${isActive ? 'certificate-item--active' : ''}`}>
      <button onClick={onClick} className="certificate-item__button" type="button">
        <div className="certificate-item__number">
          <span className="certificate-item__number-text">{number}</span>
        </div>
        <div className="certificate-item__date">
          <span className="certificate-item__date-text">{date}</span>
        </div>
        <div className="certificate-item__price">
          <span className="certificate-item__price-text">{price}грн</span>
        </div>
        <div className="certificate-item__download">
          <span
            role="button"
            tabIndex={0}
            onClick={(ev) => downloadFiles(ev)}
            onKeyDown={(ev) => downloadFiles(ev)}
            className="certificate-item__download-link"
          >
            <i className="icon-download" />
          </span>
        </div>
      </button>
    </li>
  );
};

export default DocumentItem;
