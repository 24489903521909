import React, {
	useEffect,
	useRef
} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import CustomScroll from 'react-custom-scroll';
import deliveryAction from '../../store/actions/delivery';
import pretensionsAction from '../../store/actions/pretensions';

const deliveryPlace       = 'Пункт доставки';
const allDeliveryPlace    = {
	ua : 'Всі пункти доставки',
	ru : 'Все пункты доставки',
};
const searchDeliveryPlace = {
	ua : 'Пошук пункту доставки',
	ru : 'Поиск пункта доставки',
};

const Delivery = () => {
	const deliveryStore = useSelector((state) => state.deliveryStore);
	const configStore   = useSelector((store) => store.configStore);
	const dispatch      = useDispatch();
	const rootEl        = useRef(null);

	// Фильтр адресов
	const itemsList     = Array.from(document.getElementsByClassName('delivery__item'));
	let str             = '';
	const searchAddress = (value) => {
		itemsList.forEach((i) => {
			str = i.textContent.toLowerCase();
			if (str.indexOf(value.toLowerCase()) < 0) {
				i.parentNode.classList.add('hidden');
			} else {
				i.parentNode.classList.remove('hidden');
			}
		});
	};
	// Сброс фитьтра
	const clearSearchAddress = () => {
		const deliveryInput = document.getElementById('delivery-input');
		if (deliveryInput) {
      deliveryInput.value = '';
    }
		itemsList.forEach((i) => {
			i.parentNode.classList.remove('hidden');
		});
	};

	const changeMainUid = (uid, city) => {
		dispatch(deliveryAction.setCityUid(uid));
		dispatch(deliveryAction.setCity(city));
		dispatch(deliveryAction.togglePopUp(false));
    dispatch(pretensionsAction.setErrorNum(0));
		document.getElementById('delivery-input').value = '';
		clearSearchAddress();
	};

	const cityList = () =>
		deliveryStore.addresses.map((el) => (
			<li key={el.uid}>
				<button
					type="button"
					onClick={() => changeMainUid(el.uid, el.autodestname)}
					className={`delivery__item ${
						el.uid === deliveryStore.selectCityUid ? 'delivery__item-active' : ''
						}`}
				>
					{el.autodestname}
				</button>
			</li>
		));

	const closePopUp = () => {
		dispatch(deliveryAction.togglePopUp(false));
		clearSearchAddress();
	};

	const toggleOpenPopUp = () => {
		dispatch(deliveryAction.togglePopUp(!deliveryStore.isOpenPopUp));
		document.getElementById('delivery-input').focus();
	};

	const onClick = (e) => {
		if (rootEl.current !== null && rootEl.current.contains(e.target)) {
			return null;
		}
		closePopUp();
		return null;
	};

	const  addressSubmit = (event) => {
		event.preventDefault();
		if (document.getElementById('delivery-input').value.length > 0){
			for (let i = 0; i < itemsList.length; i += 1) {
				if (!itemsList[i].parentNode.classList.contains('hidden')) {
					itemsList[i].click();
					break;
				}
			}
		}
	};

	useEffect(() => {
		dispatch(deliveryAction.fetchAddresses(configStore.isCurrentLangUa));
	}, [configStore.isCurrentLangUa]);

	useEffect(() => {
		document.addEventListener('click', onClick);
		return () => document.removeEventListener('click', onClick);
	}, []);

	return (
		<div className="delivery__wrapper" ref={rootEl}>
			<button type="button" className="delivery__select" onClick={toggleOpenPopUp}>
				<i className="icon-pin delivery__pin"/>
				<div className="delivery__text-wrapper">
					<p className="delivery__select-headline">{deliveryPlace}</p>
					<p className="delivery__select-city">
						<span className="delivery__select-city-text">{deliveryStore.selectCity}</span>
						<i className="icon-arrow"/>
					</p>
				</div>
			</button>
			<div
				className={`delivery__pop-up ${deliveryStore.isOpenPopUp ? 'delivery__pop-up-open' : ''}`}
			>
				<div className="delivery__input-wrapper">
					<form onSubmit={addressSubmit}>
						<input
							className="delivery__input"
							id="delivery-input"
							type="text"
							placeholder={
								configStore.isCurrentLangUa ? searchDeliveryPlace.ua : searchDeliveryPlace.ru
							}
							onInput={(ev) => searchAddress(ev.target.value)}
							autoComplete="off"
						/>
						<button type="submit" className="delivery__input-submit">
							<i className="icon-search"/>
						</button>
					</form>
				</div>
				<div className="delivery__text-list">
					{configStore.isCurrentLangUa ? allDeliveryPlace.ua : allDeliveryPlace.ru}
				</div>
				<CustomScroll heightRelativeToParent="300px">
					<ul>{cityList()}</ul>
				</CustomScroll>
			</div>
		</div>
	);
};

export default Delivery;
