import React from 'react';
import { useDispatch } from 'react-redux';
import certificatesAction from '../../store/actions/certificates';
import documentsActions from "../../store/actions/documents";

const FactureItem = (props) => {
  const dispatch = useDispatch();
  const { number, date, isActive, onClick, uid } = props;

  const downloadFiles = (event) => {
    event.stopPropagation();
    dispatch(documentsActions.setPreloader(true));
    dispatch(certificatesAction.downloadAllCertificates(uid, number));
  };

  return (
    <li className={`certificate-item ${isActive ? 'certificate-item--active' : ''}`}>
      <button onClick={onClick} className="certificate-item__button" type="button">
        <div className="certificate-item__number">
          <span className="certificate-item__number-text">{number}</span>
        </div>
        <div className="certificate-item__date">
          <span className="certificate-item__date-text">{date}</span>
        </div>
        <div className="certificate-item__download">
          <span
            role="button"
            tabIndex={0}
            onClick={(ev) => downloadFiles(ev)}
            onKeyDown={(ev) => downloadFiles(ev)}
            className="certificate-item__download-link"
          >
            <i className="icon-download" />
          </span>
        </div>
      </button>
    </li>
  );
};

export default FactureItem;
