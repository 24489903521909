import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import configActions from '../../store/actions/config';

const Email = () => {
  const configStore = useSelector((state) => state.configStore);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(configActions.fetchEmail(configStore.isCurrentLangUa));
  }, [configStore.isCurrentLangUa, configStore.isUserAuth]);

  return (
    <span className="header__email-wrapper">
      <i className="icon-mail" />
      <a className="header__email-link" href={`mailto:${configStore.email}`}>
        {configStore.email}
      </a>
    </span>
  );
};

export default Email;
