import deliveryTypes from '../types/delivery';
import api from '../../api/api';

const setCity = (city) => ({
  type: deliveryTypes.SELECT_CITY,
  city,
});

const togglePopUp = (state) => ({
  type: deliveryTypes.TOGGLE_POP_UP,
  state,
});

const setCityUid = (uid) => ({
  type: deliveryTypes.SELECT_CITY_UID,
  uid,
});

const setAddresses = (addresses) => ({
  type: deliveryTypes.SET_ADDRESSES,
  addresses,
});

const fetchAddresses = (lang) => async (dispatch) => {
  const response = await api.get(`${lang ? 'ua' : ''}/api/react/addresses`);
  dispatch(setCity(response.data.firm.addresses[0].autodestname));
  dispatch(setCityUid(response.data.firm.addresses[0].uid));
  dispatch(setAddresses(response.data.firm.addresses));
};

export default { fetchAddresses, setCity, togglePopUp, setCityUid };
