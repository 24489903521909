import React, {useEffect} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import pretensionsActions from '../../store/actions/pretensions';

const SearchProducts = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();
  const tabsTitle = {
    ua : 'Позиції фактури',
    ru : 'Позиции фактуры',
  };
  const searchText = {
    ua : 'Пошук',
    ru : 'Поиск',
  };
  const floatDocumentInfo = {
    ua : 'Введіть 3 або більше символів. Допустимі символи: "-", "/" та цифри.',
    ru : 'Введите 3 или более символов. Допустимые символы: "-", "/" и цифры.',
  };
  const tabChosen = {
    ua : 'Обрані',
    ru : 'Выбранные',
  };
  const tabAll = {
    ua : 'Усі',
    ru : 'Все',
  };
  const goodName = {
    ua : 'Найменування товару',
    ru : 'Наименование товара',
  };


  const submitFormProducts = (ev) => {
    ev.preventDefault();
    dispatch(
      pretensionsActions.fetchProducts(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.pretensionFactureUid,
        pretensionsStore.editedList,
        pretensionsStore.productsSearchName,
        pretensionsStore.pretensionsSortColumn,
        pretensionsStore.pretensionsSortDirection,
        pretensionsStore.productsPerPage,
        '1',
        pretensionsStore.tabs,
      ),
    );
  };

  const setProductName = (ev, name) => {
    ev.preventDefault();
    dispatch(pretensionsActions.setProductsSearchName(name));
  };

  const resetSearch = () => {
    dispatch(pretensionsActions.setProductsSearchName(''));
    dispatch(pretensionsActions.setCurrentPage(1));
    dispatch(
      pretensionsActions.fetchProducts(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.pretensionFactureUid,
        pretensionsStore.editedList,
        '',
        pretensionsStore.pretensionsSortColumn,
        pretensionsStore.pretensionsSortDirection,
        pretensionsStore.productsPerPage,
        '1',
        pretensionsStore.tabs,
      ),
    );
  };

  const changeTabs = (num) => {
    dispatch(pretensionsActions.setTabs(num))
  }

  return (
    <div className="pretension-search-products">
      <p className="pretension-search-products__headline">
        {configStore.isCurrentLangUa ? tabsTitle.ua : tabsTitle.ru}
      </p>
      <div className="pretension-search-products__tabs">
        <button
          type="button"
          className={`tab-button ${pretensionsStore.tabs === 0 ? 'active' : ''}`}
          onClick={() => changeTabs(0)}>
          {configStore.isCurrentLangUa ? tabAll.ua : tabAll.ru}
        </button>
        <button
          type="button"
          className={`tab-button ${pretensionsStore.tabs === 1 ? 'active' : ''}`}
          onClick={() => changeTabs(1)}>
          {configStore.isCurrentLangUa ? tabChosen.ua : tabChosen.ru}
        </button>
      </div>
      <form className="pretension-search-factures__form" onSubmit={(ev) => submitFormProducts(ev)}>
        <div className="pretension-search-factures__form-wrapper">
          <div className="search-certificate__input-product-wrapper">
						<span className="float-info">
							{configStore.isCurrentLangUa ? floatDocumentInfo.ua : floatDocumentInfo.ru}
						</span>
            <input className={`pretension-search-factures__input
						${pretensionsStore.errorNum !== 0 ? 'input-error' : ''}
						`}
              type="text"
              onInput={(ev) => setProductName(ev, ev.target.value)}
              placeholder={`${configStore.isCurrentLangUa ? goodName.ua : goodName.ru}`}
              value={pretensionsStore.productsSearchName}
            />
            {pretensionsStore.productsSearchName !== '' && (
              <button
                onClick={resetSearch}
                type="button"
                className="search-certificate__button-reset search-certificate__button-reset-facture"
              >
                <i className="icon-cross"/>
              </button>
            )}
          </div>
        </div>
        <button type="submit" className="pretension-search-factures__submit">
          <i className="icon-search pretension-search-factures__submit-icon"/>
          <span className="pretension-search-factures__submit-text">
						{configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
					</span>
        </button>
      </form>
    </div>
  );
};

export default SearchProducts;
