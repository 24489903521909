import React from 'react';
import { useSelector } from 'react-redux';
import preloader from '../../accets/images/loading.svg';

const PreloaderRight = () => {
	const certificatesStore = useSelector((store) => store.certificatesStore);
	
	let isActive = false;
	if (certificatesStore.preloader) {
		isActive = true;
	}
	
	return (
		<div
			className={`main_preloader ${isActive? 'main_preloader--active' : ''}`}
		>
			<img src={preloader} alt="" />
		</div>
	);
};



export default PreloaderRight;