import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReactPaginate from "react-paginate";
import CustomScroll from "react-custom-scroll";
import Checkbox from "../Checkbox/Checkbox";
import mailsActions from "../../store/actions/mails";
import CountList from "../CountList/CountList";
import ListPreloader from "../Preloader/ListPreloader";
import MailStatus from "./MailStatus";



const MailItem = (props) => {
  const mailsStore = useSelector((store) => store.mailsStore);
  const configStore    = useSelector((store) => store.configStore);
  const dispatch       = useDispatch();
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const {uid, docnum, date, type, isnew} = props;

  const labelNum = {
    ua : '№ фактури',
    ru : '№ фактуры',
  };
  const labelFactures = {
    ua : 'Показувати фактури по:',
    ru : 'Показывать фактуры по:',
  };

  const changeMail = () => {
    if (mailsStore.mailsDownloadList.indexOf(uid) !== -1) {
      dispatch(
        mailsActions.removeCheck(
          uid,
          mailsStore.mailsDownloadList,
          mailsStore.mailsList,
        ),
      );
    } else {
      dispatch(
        mailsActions.addCheck(
          uid,
          mailsStore.mailsDownloadList,
          mailsStore.mailsList,
        ),
      );
    }
  };

  const downloadMails = () => {
    dispatch(mailsActions.downloadMails(uid, mailsStore.mailsList));
  }
  const toggleItem = (target) => {
    if (!target.classList.contains('icon-download')) {
      if (open) {
        setOpen(false)
      } else {
        dispatch(mailsActions.setCurrentMailUid(uid));
        dispatch(mailsActions.setFacturesSortDirection('ASC'));
        dispatch(mailsActions.setFacturesSortColumn(''));
        dispatch(
          mailsActions.fetchMailFactures(
            configStore.isCurrentLangUa,
            uid,
            '',
            'ASC',
            mailsStore.facturesPerPage,
            1,
          ),
        );
        setOpen(true);
      }
    }
  }

  const listMailFactures = mailsStore.mailFacturesList.map((elem) => (
    <div className="mail-facture">
      <div className="mail-facture__item col-number">{elem.docnum}</div>
      <div className="mail-facture__item col-date">{elem.date}</div>
    </div>
  ));

  const sortMailFactures = (column) => {
    let direction = mailsStore.facturesSortDirection;
    if (direction === 'DESC' || direction === '') {
      direction = 'ASC';
      dispatch(mailsActions.setFacturesSortDirection('ASC'));
    } else {
      direction = 'DESC';
      dispatch(mailsActions.setFacturesSortDirection('DESC'));
    }
    dispatch(mailsActions.setFacturesSortColumn(column));
    dispatch(
      mailsActions.fetchMailFactures(
        configStore.isCurrentLangUa,
        uid,
        column,
        direction,
        mailsStore.facturesPerPage,
        1,
      ),
    );
  };

  const changePerPage = (num) => {
    dispatch(mailsActions.setFacturesPage(1));
    dispatch(mailsActions.setFacturesPerPage(num));
    dispatch(mailsActions.fetchMailFactures(
      configStore.isCurrentLangUa,
      uid,
      mailsStore.facturesSortColumn,
      mailsStore.facturesSortDirection,
      num,
      1,
    ))
  };

  const pageChange = (data) => {
    const page = data.selected + 1;
    dispatch(mailsActions.setFacturesPage(page));
    dispatch(mailsActions.fetchMailFactures(
      configStore.isCurrentLangUa,
      uid,
      mailsStore.facturesSortColumn,
      mailsStore.facturesSortDirection,
      mailsStore.facturesPerPage,
      page,
    ))
  };

  useEffect(() => {
    if (mailsStore.currentMailUid !== uid) {
      setOpen(false);
    }
  }, [
    configStore.isCurrentLangUa,
    mailsStore.currentMailUid,
    mailsStore.facturesPerPage,
    mailsStore.facturesPage,
  ]);

  return (
    <li className={`mail-item ${isnew === '1' ? 'new' : ''}`} data-uid={uid} data-isnew={isnew}>
      <button type="button" className="mail-item__heading" onClick={(e) => toggleItem(e.target)}>
        <div className="mail-item__col col-check">
          <Checkbox
            checkedState={mailsStore.mailsDownloadList.indexOf(uid) !== -1}
            id={uid}
            checkedAction={() => changeMail(uid)}
          />
        </div>
        <div className="mail-item__col col-number">{docnum}</div>
        <div className="mail-item__col col-date">{date}</div>
        <div className="mail-item__col col-type">
          <MailStatus status={type}/>
        </div>
        <div className="mail-item__col col-btn">
          <button type="button"
                  className="mail-item__button-download"
                  onClick={() => downloadMails()}
          >
            <i className="icon-download"/>
          </button>
          <button type="button"
                  className="mail-item__button-open"
                  onClick={(e) => toggleItem(e.target)}
          >
            <i className="icon-chevron-right-bold"/>
          </button>
        </div>
      </button>
      <div className={`mail-item__content ${open ? 'open' : ''}`}>
        <ListPreloader/>
        <div className="mail-factures">
          <div className="mail-factures__heading">
            <div className="mails-list__header-item col-number">
              <button type="button"
                      className="mails-list__header-item-text"
                      onClick={() => sortMailFactures('DOCNUM')}>
                {configStore.isCurrentLangUa ? labelNum.ua : labelNum.ru}
              </button>
            </div>
            <div className="mails-list__header-item col-date">
              <button type="button"
                      className="mails-list__header-item-text"
                      onClick={() => sortMailFactures('DATE')}>
                Дата
              </button>
            </div>
          </div>
          <CustomScroll allowOuterScroll flex="1" heightRelativeToParent="520px">
            <div className="mail-factures__list">
              {listMailFactures}
            </div>
          </CustomScroll>
          <div className="mails-list__bottom-nav mail-factures__nav">
            <div className="mail-factures__total">Фактур: {mailsStore.facturesTotalCount}</div>
            <div className="mail-factures__paginate">
              <ReactPaginate
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={mailsStore.facturesPageCount}
                forcePage={mailsStore.facturesPage - 1}
                containerClassName="pager"
                pageLinkClassName="pager__button"
                activeLinkClassName="pager__button--active"
                previousLinkClassName="icon-arrow pager__arrow-prev"
                nextLinkClassName="icon-arrow pager__arrow-next"
                onPageChange={pageChange}
              />
            </div>
            <div className="mail-factures__per-page">
              <span className="mail-factures__per-page-label">
                {configStore.isCurrentLangUa ? labelFactures.ua : labelFactures.ru}
              </span>
              <CountList onClick={changePerPage} currentPerPage={mailsStore.facturesPerPage} countOf="mails"/>
            </div>
          </div>
        </div>
      </div>
    </li>

  );
};

export default MailItem;
