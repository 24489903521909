import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import pretensionsActions from "../../store/actions/pretensions";

const PretensionStatus = (props) => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore    = useSelector((store) => store.configStore);
  const dispatch       = useDispatch();
  // eslint-disable-next-line
  const { status } = props;

  const statusDraft = {
    ua : 'Збережено',
    ru : 'Сохранено',
  };
  const statusSent = {
    ua : 'Відправлено',
    ru : 'Отправлено',
  };
  const statusProcessing = {
    ua : 'В обробці',
    ru : 'В обработке',
  };
  const statusAnswered = {
    ua : 'Дано відповідь',
    ru : 'Дан ответ',
  };
  const statusError = {
    ua : 'Помилка створення',
    ru : 'Ошибка создания',
  };
  const statusDone = {
    ua : 'Оброблено',
    ru : 'Обработано',
  };
  const statusClosed = {
    ua : 'Закрита',
    ru : 'Закрыта',
  };

  const pretensionStatus = () => {
    switch (status) {
      case 'DRAFT':
        return (
          <div className='pretension-status status-draft'>
            <i className="icon-status-draft"/>
            <span>{configStore.isCurrentLangUa ? statusDraft.ua : statusDraft.ru}</span>
          </div>
        );
      case 'SENT':
        return (
          <div className='pretension-status status-sent'>
            <i className="icon-status-sent"/>
            <span>{configStore.isCurrentLangUa ? statusSent.ua : statusSent.ru}</span>
          </div>
        );
      case 'PROCESSING':
        return (
          <div className='pretension-status status-processing'>
            <i className="icon-status-processing"/>
            <span>{configStore.isCurrentLangUa ? statusProcessing.ua : statusProcessing.ru}</span>
          </div>
        );
      case 'ANSWERED':
        return (
          <div className='pretension-status status-answered'>
            <i className="icon-status-answered"/>
            <span>{configStore.isCurrentLangUa ? statusAnswered.ua : statusAnswered.ru}</span>
          </div>
        );
      case 'ERROR':
        return (
          <div className='pretension-status status-error'>
            <i className="icon-status-error"/>
            <span>{configStore.isCurrentLangUa ? statusError.ua : statusError.ru}</span>
          </div>
        );
      case 'DONE':
        return (
          <div className='pretension-status status-done'>
            <i className="icon-status-done"/>
            <span>{configStore.isCurrentLangUa ? statusDone.ua : statusDone.ru}</span>
          </div>
        );
      case 'CLOSED':
        return (
          <div className='pretension-status status-closed'>
            <i className="icon-lock-1"/>
            <span>{configStore.isCurrentLangUa ? statusClosed.ua : statusClosed.ru}</span>
          </div>
        );
      default:
        return '';
    }
  };

  return (
    pretensionStatus()
  );
};

export default PretensionStatus;
