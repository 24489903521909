import React from 'react';
import { useSelector } from 'react-redux';
import preloader from '../../accets/images/loading.svg';

const LoaderForm = () => {
  const loginStore = useSelector((store) => store.loginStore);

  return (
    <div
      className={`login__preloader ${loginStore.loginPreloader ? 'login__preloader--active' : ''}`}
    >
      <img src={preloader} alt="" />
    </div>
  );
};

export default LoaderForm;
