import mailsTypes from '../types/mails';

const initialStore = {
  mailsError: '',
  searchNum: '',
  dateStart: undefined,
  dateEnd: undefined,
  dateStartDisplay: '--.--.----',
  dateEndDisplay: '--.--.----',
  Display: undefined,
  mailsList: [],
  mailsDownloadList: [],
  mailsPreloader: false,
  allChecked: false,
  mailsType: 'RESPONSE',
  mailsOnlyNew: 0,
  mailsSortColumn: '',
  mailsSortDirection: 'ASC',
  mailsPerPage: 10,
  mailsPage: 1,
  mailsPageCount: undefined,
  mailsTotalCount: 0,
  currentMailUid: '',
  facturesPreloader: false,
  mailFacturesList: [],
  facturesSortColumn: '',
  facturesSortDirection: 'ASC',
  facturesPerPage: 10,
  facturesPage: 1,
  facturesPageCount: undefined,
  facturesTotalCount: 0,
  infoPage: undefined,
  infoPageTitle: undefined,
  info: undefined,
};

const mailsStore = (state = initialStore, action) => {
  switch (action.type) {
    case mailsTypes.SET_MAILS_ERROR:
      return {
        ...state,
        mailsError: action.str,
      };
    case mailsTypes.SET_SEARCH_NUM:
      return {
        ...state,
        searchNum: action.str,
      };
    case mailsTypes.SET_DATE_START:
      return {
        ...state,
        dateStart: action.str,
      };
    case mailsTypes.SET_DATE_END:
      return {
        ...state,
        dateEnd: action.str,
      };
    case mailsTypes.SET_DATE_START_DISPLAY:
      return {
        ...state,
        dateStartDisplay: action.str,
      };
    case mailsTypes.SET_DATE_END_DISPLAY:
      return {
        ...state,
        dateEndDisplay: action.str,
      };
    case mailsTypes.SET_MAILS_LIST:
      return {
        ...state,
        mailsList: action.arr,
      };
    case mailsTypes.SET_MAILS_DOWNLOAD_LIST:
      return {
        ...state,
        mailsDownloadList: action.arr,
      };
    case mailsTypes.SET_MAILS_PRELOADER:
      return {
        ...state,
        mailsPreloader: action.bool,
      };
    case mailsTypes.SET_ALL_CHECKED:
      return {
        ...state,
        allChecked: action.bool,
      };
    case mailsTypes.SET_MAILS_TYPE:
      return {
        ...state,
        mailsType: action.str,
      };
    case mailsTypes.SET_MAILS_ONLY_NEW:
      return {
        ...state,
        mailsOnlyNew: action.num,
      };
    case mailsTypes.SET_MAILS_SORT_COLUMN:
      return {
        ...state,
        mailsSortColumn: action.str,
      };
    case mailsTypes.SET_MAILS_SORT_DIRECTION:
      return {
        ...state,
        mailsSortDirection: action.str,
      };
    case mailsTypes.SET_MAILS_PER_PAGE:
      return {
        ...state,
        mailsPerPage: action.num,
      };
    case mailsTypes.SET_MAILS_PAGE:
      return {
        ...state,
        mailsPage: action.num,
      };
    case mailsTypes.SET_MAILS_PAGE_COUNT:
      return {
        ...state,
        mailsPageCount: action.num,
      };
    case mailsTypes.SET_MAILS_TOTAL_COUNT:
      return {
        ...state,
        mailsTotalCount: action.num,
      };
    case mailsTypes.SET_CURRENT_MAIL_UID:
      return {
        ...state,
        currentMailUid: action.str,
      };
    case mailsTypes.SET_FACTURES_PRELOADER:
      return {
        ...state,
        facturesPreloader: action.bool,
      };
    case mailsTypes.SET_MAIL_FACTURES_LIST:
      return {
        ...state,
        mailFacturesList: action.arr,
      };
    case mailsTypes.SET_FACTURES_SORT_COLUMN:
      return {
        ...state,
        facturesSortColumn: action.str,
      };
    case mailsTypes.SET_FACTURES_SORT_DIRECTION:
      return {
        ...state,
        facturesSortDirection: action.str,
      };
    case mailsTypes.SET_FACTURES_PER_PAGE:
      return {
        ...state,
        facturesPerPage: action.num,
      };
    case mailsTypes.SET_FACTURES_PAGE:
      return {
        ...state,
        facturesPage: action.num,
      };
    case mailsTypes.SET_FACTURES_PAGE_COUNT:
      return {
        ...state,
        facturesPageCount: action.num,
      };
    case mailsTypes.SET_FACTURES_TOTAL_COUNT:
      return {
        ...state,
        facturesTotalCount: action.num,
      };
    case mailsTypes.SET_INFO_PAGE:
      return {
        ...state,
        infoPage: action.str,
      };
    case mailsTypes.SET_INFO_PAGE_TITLE:
      return {
        ...state,
        infoPageTitle: action.str,
      };
    case mailsTypes.SET_INFO:
      return {
        ...state,
        info: action.str,
      };
    default:
      return state;
  }
};

export default mailsStore;
