import React, {useEffect} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import ReactPaginate from "react-paginate";
import Preloader from "../Preloader/Preloader";
import pretensionsActions from "../../store/actions/pretensions";
import PretensionFactureItem from "./PretensionFactureItem";
import StepNav from "./StepNav";
import CountList from "../CountList/CountList";
import SearchFactures from "./SearchFactures";

const numberDoc = '№ документа';
const dateDoc   = 'Дата';
const nothing = {
  ua : 'Нічого не знайдено за вашими критеріями пошуку. ',
  ru : 'Ничего не найдено по вашим критериям поиска. ',
};
const backText = {
  ua : 'Повернутись до пошуку.',
  ru : 'Вернуться к поиску.',
};

const StepSearchFactures = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const configStore = useSelector((store) => store.configStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const dispatch = useDispatch();

  const listFactures = pretensionsStore.facturesList.map((elem) => (
    <PretensionFactureItem
      key={elem.uid}
      number={elem.docnum}
      date={elem.date}
      uid={elem.uid}
    />
  ));

  const changePerPage = (num) => {
    dispatch(pretensionsActions.setCurrentPage(1));
    dispatch(pretensionsActions.setPerPage(num));
  };

  const pageChange = (data) => {
    dispatch(pretensionsActions.setCurrentPage(data.selected + 1));
  };

  const sortFactures = (column) => {
    let direction = pretensionsStore.pretensionsSortDirection;
    if (direction === 'DESC' || direction === '') {
      direction = 'ASC';
      dispatch(pretensionsActions.setPretensionsSortDirection('ASC'));
    } else {
      direction = 'DESC';
      dispatch(pretensionsActions.setPretensionsSortDirection('DESC'));
    }
    dispatch(pretensionsActions.setPretensionsSortColumn(column));
    dispatch(
      pretensionsActions.fetchFactures(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.searchFactureNum,
        column,
        direction,
        pretensionsStore.perPage,
        pretensionsStore.currentPage,
      ),
    );
  };

  useEffect(() => {
    dispatch(
      pretensionsActions.fetchFactures(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.searchFactureNum,
        pretensionsStore.pretensionsSortColumn,
        pretensionsStore.pretensionsSortDirection,
        pretensionsStore.perPage,
        pretensionsStore.currentPage,
      ),
    );
  }, [
    configStore.isCurrentLangUa,
    deliveryStore.selectCityUid,
    pretensionsStore.perPage,
    pretensionsStore.currentPage,
  ]);

  const resetSearch = () => {
    dispatch(pretensionsActions.setPretensionsError(0));
    dispatch(pretensionsActions.setSearchFactureNum(''));
    dispatch(pretensionsActions.setSearchPretensionStatus('ALL'));
    dispatch(pretensionsActions.setPretensionsSortColumn(''));
    dispatch(pretensionsActions.setPretensionsSortDirection(''));
    dispatch(pretensionsActions.setCurrentPage(1));
    dispatch(
      pretensionsActions.fetchFactures(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        '',
        '',
        '',
        pretensionsStore.perPage,
        1,
      ),
    );
  };

  let content;

  if (pretensionsStore.pretensionsError === 'no such facture') {
    content = <p className="pretensions__content-search-error">
      <i className="icon-info"/>
      {configStore.isCurrentLangUa ? nothing.ua : nothing.ru}
      <button
        onClick={resetSearch}
        type="button"
        className="certificates__content-search-error-button"
      >
        {configStore.isCurrentLangUa ? backText.ua : backText.ru}
      </button>
    </p>
  } else {
    content = <div className="pretensions-facture__search-list">
      <div className="pretensions-facture__list-header">
        <div className="pretensions-facture__header-item col-facture">
          <button type="button"
                  className="pretensions-facture__header-item-text"
                  onClick={() => sortFactures('invoicenum')}>
            {numberDoc}
          </button>
        </div>
        <div className="pretensions-facture__header-item col-date">
          <button type="button"
                  className="pretensions-facture__header-item-text"
                  onClick={() => sortFactures('date')}>
            {dateDoc}
          </button>
        </div>
      </div>
      <ul className="pretensions-facture__list">{listFactures}</ul>
      <div className="pretensions-list__bottom-nav">
        <CountList onClick={changePerPage} currentPerPage={pretensionsStore.perPage} countOf="pretensions"/>
        <ReactPaginate
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pretensionsStore.pageCount}
          forcePage={pretensionsStore.currentPage - 1}
          containerClassName="pager"
          pageLinkClassName="pager__button"
          activeLinkClassName="pager__button--active"
          previousLinkClassName="icon-arrow pager__arrow-prev"
          nextLinkClassName="icon-arrow pager__arrow-next"
          onPageChange={pageChange}
        />
      </div>
    </div>
  }

  return (
    <div className="step-create create-search">
      <StepNav/>
      <SearchFactures/>
      <Preloader/>
      {content}
    </div>
  );
};

export default StepSearchFactures;
