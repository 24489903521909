const SET_CERTIFICATE_LIST = 'SET_CERTIFICATE_LIST';
const SET_FACTURES_PER_PAGE = 'SET_FACTURES_PER_PAGE';
const SET_FACTURES_PAGE = 'SET_FACTURES_PAGE';
const SET_FACTURES_PAGE_COUNT = 'SET_FACTURES_PAGE_COUNT';
const SET_CURRENT_FACTURE = 'SET_CURRENT_FACTURE';
const SET_INVOICE_NUM = 'SET_INVOICE_NUM';
const SET_INFO_PAGE = 'SET_INFO_PAGE';
const SET_CURRENT_FACTURE_UID = 'SET_CURRENT_FACTURE_UID';
const SET_CURRENT_FACTURE_DATE = 'SET_CURRENT_FACTURE_DATE';
const SET_CURRENT_FACTURE_DATE_RAW = 'SET_CURRENT_FACTURE_DATE_RAW';
const SET_INFO_PAGE_TITLE = 'SET_INFO_PAGE_TITLE';
const SET_SEARCH_DATE = 'SET_SEARCH_DATE';
const SET_SEARCH_FACTURE_NUM = 'SET_SEARCH_FACTURE_NUM';
const SET_SEARCH_ERROR_NUM = 'SET_SEARCH_ERROR_NUM';
const SET_GOOD_SEARCH_SERIES = 'SET_GOOD_SEARCH_SERIES';
const SET_GOOD_SEARCH = 'SET_GOOD_SEARCH';
const SET_GOOD_SEARCH_ERROR = 'SET_GOOD_SEARCH_ERROR';
const SET_GOOD_HEADLINE = 'SET_GOOD_HEADLINE';
const SET_PRELOADER = 'SET_PRELOADER';
const SET_FACTURES_SORT_COL = 'SET_FACTURES_SORT_COL';
const SET_FACTURES_SORT_DIR = 'SET_FACTURES_SORT_DIR';

const combineConst = {
  SET_CERTIFICATE_LIST,
  SET_FACTURES_PER_PAGE,
  SET_GOOD_HEADLINE,
  SET_SEARCH_ERROR_NUM,
  SET_GOOD_SEARCH_SERIES,
  SET_SEARCH_DATE,
  SET_GOOD_SEARCH,
  SET_GOOD_SEARCH_ERROR,
  SET_SEARCH_FACTURE_NUM,
  SET_CURRENT_FACTURE_DATE,
  SET_CURRENT_FACTURE_DATE_RAW,
  SET_FACTURES_PAGE,
  SET_CURRENT_FACTURE,
  SET_FACTURES_PAGE_COUNT,
  SET_CURRENT_FACTURE_UID,
  SET_INVOICE_NUM,
  SET_INFO_PAGE,
  SET_INFO_PAGE_TITLE,
  SET_PRELOADER,
  SET_FACTURES_SORT_COL,
  SET_FACTURES_SORT_DIR,
};

export default combineConst;
