import menuTypes from '../types/menu';
import api from '../../api/api';

const setMenuList = (array) => ({
  type: menuTypes.SET_MENU_LIST,
  array,
});

const fetchMenu = (lang, autoDestUID) => async (dispatch) => {
  const noticeData = new FormData;
  noticeData.append('AutoDestUID', autoDestUID);
  const response = await api.get(`${lang ? 'ua' : ''}/api/react/menu`);
  const responseNotice = await api.post(`${lang ? 'ua' : ''}/api/react/notice`, noticeData);
  const navNotify = response.data.result;
  if (responseNotice.data.result){
    navNotify.forEach((elem) => {
      switch (elem.url_key) {
        case 'certificates':
          // eslint-disable-next-line no-param-reassign
          elem.notification = responseNotice.data.result.certificates;
          break;
        case 'documents':
          // eslint-disable-next-line no-param-reassign
          elem.notification = responseNotice.data.result.documents;
          break;
        case 'pretensy':
          // eslint-disable-next-line no-param-reassign
          elem.notification = responseNotice.data.result.pretensy;
          break;
        case 'mails':
          // eslint-disable-next-line no-param-reassign
          elem.notification = responseNotice.data.result.mails;
          break;
        default: break;
      }
    });
  }

  dispatch(setMenuList(navNotify));
};
export default {
  fetchMenu,
  setMenuList,
};
