import React from 'react';
import { useSelector } from 'react-redux';
import preloader from '../../accets/images/loading.svg';

const ModalPreloader = () => {
  const reportStore = useSelector((store) => store.reportStore);

  let isActive = false;
  if (reportStore.modalPreloader) {
    isActive = true;
  }

  return (
    <div
      className={`main_preloader ${isActive? 'main_preloader--active' : ''}`}
    >
      <img src={preloader} alt="" />
    </div>
  );
};



export default ModalPreloader;
