import React from 'react';

const Checkbox = ({ id, text, checkedState, checkedAction }) => (
  <label className="checkbox__wrapper" htmlFor={`checkbox${id}`}>
    <input
      checked={checkedState}
      onChange={checkedAction}
      className="checkbox__input"
      id={`checkbox${id}`}
      type="checkbox"
    />
    <span className="checkbox__tick-wrapper">
      <i className="icon-tick" />
    </span>
    {text && <span className="checkbox__text">{text}</span>}
  </label>
);

export default Checkbox;
