import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import logo from '../../accets/images/mainLogo.svg';

const Logo = () => (
  <a href="/" className="header__logo-link">
    <LazyLoadImage
      wrapperClassName="header__logo-wrapper"
      src={logo}
      effect="opacity"
      height={64}
      widht={352}
    />
  </a>
);
export default Logo;
