import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomScroll from 'react-custom-scroll';
import Checkbox from '../Checkbox/Checkbox';
import certificatesAction from '../../store/actions/certificates';
import facturesActions from '../../store/actions/factures';
import PreloaderRight from "../Preloader/PreloaderRight";

const fromText = {
  ru: 'От',
  ua: 'Від',
};
const chooseCert = {
  ua: 'Обрано сертифікатів:',
  ru: 'Выбрано сертификатов:',
};
const downloadText = {
  ru: 'Загрузить',
  ua: 'Завантажити',
};
const nameText = {
  ru: 'Название',
  ua: 'Найменування',
};
const seriesText = {
  ru: 'Серия',
  ua: 'Серія',
};
const sertText = {
  ru: 'Сертификат',
  ua: 'Сертифікат',
};
const chooseAllText = {
  ru: 'Выбрать все',
  ua: 'Обрати все',
};
const chooseText = {
  ru: 'Выберите, пожалуйста, нужную вам фактуру',
  ua: 'Оберіть, будь-ласка, потрібну вам фактуру',
};

const CertificatesRight = () => {
  const certificatesStore = useSelector((state) => state.certificatesStore);
  const factureStore = useSelector((state) => state.factureStore);
  const configStore = useSelector((store) => store.configStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      certificatesAction.fetchCertificates(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        factureStore.currentFacture,
        factureStore.currentFactureDateRaw,
      ),
    );

    return () => {
      dispatch(certificatesAction.setSelectedList([]));
      dispatch(certificatesAction.setCertificatesList([]));
      dispatch(facturesActions.setGoodSearchText(''));
      dispatch(facturesActions.setGoodSearchSeries(''));
      dispatch(facturesActions.setGoodSearchErrorBool(false));
      dispatch(facturesActions.setGoodSearchHeadline(''));
    };
  }, [configStore.isCurrentLangUa, factureStore.currentFacture]);

  const changeCertificate = (uid) => {
    if (certificatesStore.selectedList.indexOf(uid) !== -1) {
      dispatch(
        certificatesAction.removeCheck(
          uid,
          certificatesStore.selectedList,
          certificatesStore.certificatesList,
        ),
      );
    } else {
      dispatch(
        certificatesAction.addCheck(
          uid,
          certificatesStore.selectedList,
          certificatesStore.certificatesList,
        ),
      );
    }
  };

  const downloadList = (array) => {
    if (array.length !== 0) {
      dispatch(certificatesAction.downloadCertificates(deliveryStore.selectCityUid, array));
    }
  };

  const certificatesList = certificatesStore.certificatesList.map((elem) => (
    <li className="certificates-right__list-item" key={elem.uid}>
      <div className="certificates-right__list-item-checkbox">
        <Checkbox
          checkedState={certificatesStore.selectedList.indexOf(elem.uid) !== -1}
          id={elem.uid}
          checkedAction={() => changeCertificate(elem.uid, elem)}
        />
      </div>
      <div className="certificates-right__list-item-name">
        <p className="certificates-right__list-item-name-text">{elem.good}</p>
      </div>
      <div className="certificates-right__list-item-series">
        <p className="certificates-right__list-item-series-text">{elem.series}</p>
      </div>
      <div className="certificates-right__list-item-certificate">
        <p className="certificates-right__list-item-certificate-text">{elem.itemname}</p>
      </div>
      <button
        onClick={() => downloadList([elem.uid])}
        type="button"
        className="certificates-right__list-item-link"
      >
        <i className="icon-download" />
      </button>
    </li>
  ));

  const checkAllCertificates = () => {
    const array = certificatesStore.certificatesList.map((elem) => elem.uid);
    if (certificatesStore.allItemsChecked) {
      dispatch(certificatesAction.toggleAllCertificates([], certificatesStore.certificatesList));
    } else {
      dispatch(certificatesAction.toggleAllCertificates(array, certificatesStore.certificatesList));
    }
  };


  let certificatesRightContent;
  let isSearchError = false;
  let currentFactureTitle;
  let currentFactureDateTitle;
  if (factureStore.searchErrorNum === 2 && factureStore.searchDate) {
    const searchRawDate = factureStore.searchDate;
    const searchRawDay = searchRawDate.getDate();
    let searchRawMonth = searchRawDate.getMonth() + 1;
    const searchRawYear = searchRawDate.getFullYear();
    if (searchRawMonth < 10) {
      searchRawMonth = `0${searchRawMonth}`;
    }
    const searchFormatedDate = `${searchRawDay}.${searchRawMonth}.${searchRawYear}`
    currentFactureTitle = factureStore.searchFactureNum;
    currentFactureDateTitle = searchFormatedDate;
  } else {
    currentFactureTitle = factureStore.currentFacture;
    currentFactureDateTitle = factureStore.currentFactureDate;
  }

  if (factureStore.searchFactureNum && factureStore.searchDate) {
    if (certificatesStore.certSearchErrorNum > 0) {
      isSearchError = true;
    } else {
      isSearchError = false;
    }
  } else if (factureStore.searchErrorNum > 1) {
    isSearchError = true;
  } else {
    isSearchError = false;
  }

  if (isSearchError){
    certificatesRightContent = '';
  } else if (factureStore.currentFacture === ''
    && certificatesStore.certificatesList.length === 0
  ) {
    certificatesRightContent = <div className="certificates-right__wrapper-empty">
      {configStore.isCurrentLangUa ? chooseText.ua : chooseText.ru}
    </div>
  } else {
    certificatesRightContent = <div className="certificates-right__wrapper">
      <div>
        <p className="certificates-right__title">
          {factureStore.goodSearchHeadline === ''
            ? `Фактура ${currentFactureTitle} ${
              configStore.isCurrentLangUa ? fromText.ua : fromText.ru
            } ${currentFactureDateTitle}`
            : factureStore.goodSearchHeadline}
        </p>
      </div>
      <div className="certificates-right__header">
        <div className="certificates-right__choose">
          <p className="certificates-right__choose-text">
            {configStore.isCurrentLangUa ? chooseCert.ua : chooseCert.ru}
          </p>
          <p className="certificates-right__choose-counter">
            {certificatesStore.selectedList.length}
          </p>
        </div>
        <button
          onClick={() => downloadList(certificatesStore.selectedList)}
          className="certificates-right__header-download"
          type="button"
        >
            <span className="certificates-right__header-download-text">
              {configStore.isCurrentLangUa ? downloadText.ua : downloadText.ru}
            </span>
          <i className="icon-download certificates-right__header-download-icon" />
        </button>
      </div>
      <div className="certificates-right__list-header">
        <div className="certificates-right__list-header-checkbox">
          <Checkbox
            checkedState={certificatesStore.allItemsChecked}
            id="rightListCheck"
            checkedAction={() => checkAllCertificates()}
          />
        </div>
        <div className="certificates-right__list-header-name">
          <p className="certificates-right__list-header-name-text">
            {configStore.isCurrentLangUa ? nameText.ua : nameText.ru}
          </p>
        </div>
        <div className="certificates-right__list-header-series">
          <p className="certificates-right__list-header-series-text">
            {configStore.isCurrentLangUa ? seriesText.ua : seriesText.ru}
          </p>
        </div>
        <div className="certificates-right__list-header-certificate">
          <p className="certificates-right__list-header-certificate-text">
            {configStore.isCurrentLangUa ? sertText.ua : sertText.ru}
          </p>
        </div>
      </div>
      <div className="certificates-right__list-wrapper">
        <CustomScroll allowOuterScroll flex="1" heightRelativeToParent="516px">
          <ul className="certificates-right__list">{certificatesList}</ul>
        </CustomScroll>
      </div>
      <div className="certificates-right__footer">
        <div className="certificates-right__footer-checkbox">
          <Checkbox
            checkedState={certificatesStore.allItemsChecked}
            id="footerListCheck"
            text={configStore.isCurrentLangUa ? chooseAllText.ua : chooseAllText.ru}
            checkedAction={() => checkAllCertificates()}
          />
        </div>
        <div className="certificates-right__footer-choose">
            <span className="certificates-right__footer-choose-text">
              {configStore.isCurrentLangUa ? chooseCert.ua : chooseCert.ru}
            </span>
          <span className="certificates-right__footer-choose-counter">
              {certificatesStore.selectedList.length}
            </span>
        </div>
        <button
          onClick={() => downloadList(certificatesStore.selectedList)}
          type="button"
          className="certificates-right__footer-link"
        >
          <i className="icon-download certificates-right__footer-link-icon" />
          <span className="certificates-right__footer-link-text">
              {configStore.isCurrentLangUa ? downloadText.ua : downloadText.ru}
            </span>
        </button>
      </div>
    </div>
  }


  return (

    <div className='certificates-right'>
      <PreloaderRight/>
      {certificatesRightContent}
    </div>
  );
};

export default CertificatesRight;
