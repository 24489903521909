import React from 'react';
import {
  useSelector
} from 'react-redux';
import Helmet from "react-helmet";
import {Link} from "react-router-dom";
import SearchMails from "../../components/Mails/SearchMails";
import MailsRight from "../../components/Mails/MailsRight";
import MailsList from "../../components/Mails/MailsList";

const Mails = () => {
  const title = {
    ru : 'Отзывы и Браки',
    ua : 'Відгуки та Браки',
  };
  const infoButton = {
    ru : 'Инструкция по разделу',
    ua : 'Інструкція до розділу',
  };

  const configStore   = useSelector((store) => store.configStore);

  return (
    <>
      <Helmet>
        <title>{configStore.isCurrentLangUa ? title.ua : title.ru}</title>
      </Helmet>
      <section className="mails">
        <div className="mails__content">
          <div className="mails__headline-container">
            <h2 className="mails__headline">
              {configStore.isCurrentLangUa ? title.ua : title.ru}
            </h2>
            <Link className="mails__button-instructions" to="/mails-help">
              {configStore.isCurrentLangUa ? infoButton.ua : infoButton.ru}
            </Link>
          </div>
          <SearchMails/>
          <MailsList/>
        </div>
        <MailsRight/>
      </section>
    </>
  );
}


export default Mails;
