import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import configActions from '../../store/actions/config';

const LanguageChanger = () => {
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();

  const languageHandler = (language) => {
    dispatch(configActions.changeLang(language));
  };

  return (
    <div className="language-change__wrapper">
      <button
        type="button"
        onClick={() => languageHandler(true)}
        className={`language-change__button ${
          configStore.isCurrentLangUa ? 'language-change__button--active' : ''
        }`}
      >
        ua
      </button>
      <button
        type="button"
        onClick={() => languageHandler(false)}
        className={`language-change__button ${
          !configStore.isCurrentLangUa ? 'language-change__button--active' : ''
        }`}
      >
        ru
      </button>
    </div>
  );
};

export default LanguageChanger;
