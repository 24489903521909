import React, {useState, useEffect, useRef} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import mailsActions from "../../store/actions/mails";

const MailsFilter = () => {
  const mailsStore = useSelector((state) => state.mailsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();
  const [isOpen, changeDropdownState] = useState(false);
  const activeItem = mailsStore.mailsOnlyNew;

  const filter = {
    ua : 'Фільтрація',
    ru : 'Фильтрация',
  };
  const textAll = {
    ua : 'Усі',
    ru : 'Все',
  };
  const textNew = {
    ua : 'Нові',
    ru : 'Новые',
  };

  const rootEl = useRef(null);

  useEffect(() => {
    const onClick = e => rootEl.current.contains(e.target) || changeDropdownState(false);
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);


  const openFilter = () => {
    if (!isOpen) {
      changeDropdownState(true)
    } else {
      changeDropdownState(false)
    }
  }


  const changeFilter = (num) => {
    changeDropdownState(false);
    dispatch(mailsActions.setMailsOnlyNew(num));
    dispatch(mailsActions.setMailsPage(1));
    dispatch(
      mailsActions.fetchMails(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        mailsStore.dateStart,
        mailsStore.dateEnd,
        mailsStore.searchNum,
        mailsStore.mailsType,
        mailsStore.mailsSortColumn,
        mailsStore.mailsSortDirection,
        mailsStore.mailsPerPage,
        1,
        num
      ),
    );
  }


  return (
    <div className="filter-dropdown" ref={rootEl}>
      <button type="button"
              className="filter-dropdown__button"
              onClick={() => openFilter()}>
        <i className="icon-filter-1"/>
        <span>{configStore.isCurrentLangUa ? filter.ua : filter.ru}</span>
        <i className="icon-chevron-down-mid"/>
      </button>
      <div className={`filter-dropdown__box ${isOpen ? 'open' : ''}`}>
        <div className="filter-dropdown__list">
          <button
            className={`filter-dropdown__item ${activeItem === 0 ? 'active' : ''}`}
            type="button"
            onClick={() => changeFilter(0)}>
            <i className="icon-menu"/>
            {configStore.isCurrentLangUa ? textAll.ua : textAll.ru}
          </button>
          <button
            className={`filter-dropdown__item ${activeItem === 1 ? 'active' : ''}`}
            type="button"
            onClick={() => changeFilter(1)}>
            <i className="icon-lamp"/>
            {configStore.isCurrentLangUa ? textNew.ua : textNew.ru}
          </button>
        </div>
      </div>
    </div>
  )
};

export default MailsFilter;
