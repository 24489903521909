import configTypes from '../types/config';

const initialStore = {
  isUserAuth: false,
  userName: '',
  isCurrentLangUa: true,
  email: '',
};

const configStore = (state = initialStore, action) => {
  switch (action.type) {
    case configTypes.SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case configTypes.SET_USER_AUTH:
      return {
        ...state,
        isUserAuth: action.status,
      };
    case configTypes.SET_USER_NAME:
      return {
        ...state,
        userName: action.name,
      };
    case configTypes.SET_LANG:
      return {
        ...state,
        isCurrentLangUa: action.lang,
      };
    default:
      return state;
  }
};

export default configStore;
