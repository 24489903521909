// switch between pretensions steps and section
const SET_PRETENSIONS_SECTION = 'SET_PRETENSIONS_SECTION';
const SET_PRETENSIONS_STEP = 'SET_PRETENSIONS_STEP';

const SET_PRETENSIONS_LIST = 'SET_PRETENSIONS_LIST';
const SET_FACTURES_LIST = 'SET_FACTURES_LIST';
const SET_IMAGES_LIST = 'SET_IMAGES_LIST';
const SET_IMAGES_ADMIN_LIST = 'SET_IMAGES_ADMIN_LIST';
const SET_IMAGES_CLIENT_LIST = 'SET_IMAGES_CLIENT_LIST';
const SET_PRETENSION_COMMENT = 'SET_PRETENSION_COMMENT';

const SET_PRETENSION_FACTURE_NUM = 'SET_PRETENSION_FACTURE_NUM';
const SET_PRETENSION_FACTURE_DATE = 'SET_PRETENSION_FACTURE_DATE';
const SET_PRETENSION_FACTURE_UID = 'SET_PRETENSION_FACTURE_UID';
const SET_CURRENT_PRETENSION_UID = 'SET_CURRENT_PRETENSION_UID';
const SET_CURRENT_PRETENSION = 'SET_CURRENT_PRETENSION';
const SET_CURRENT_PRETENSION_DIALOG = 'SET_CURRENT_PRETENSION_DIALOG';
const SET_CURRENT_PRETENSION_MESSAGE = 'SET_CURRENT_PRETENSION_MESSAGE';
const SET_PRETENSIONS_ERROR = 'SET_PRETENSIONS_ERROR';

// filters search and pagination
const SET_ERROR_NUM = 'SET_ERROR_NUM';
const SET_SEARCH_DATE_START = 'SET_SEARCH_DATE_START';
const SET_SEARCH_DATE_END = 'SET_SEARCH_DATE_END';
const SET_SEARCH_FACTURE_NUM = 'SET_SEARCH_FACTURE_NUM';
const SET_SEARCH_STATUS = 'SET_SEARCH_STATUS';
const SET_PRETENSIONS_SORT_DIRECTION = 'SET_PRETENSIONS_SORT_DIRECTION';
const SET_PRETENSIONS_SORT_COLUMN = 'SET_PRETENSIONS_SORT_COLUMN';
const SET_PRELOADER = 'SET_PRELOADER';
const SET_LIST_PRELOADER = 'SET_LIST_PRELOADER';
const SET_PER_PAGE = 'SET_PER_PAGE';
const SET_PAGE = 'SET_PAGE';
const SET_PAGE_COUNT = 'SET_PAGE_COUNT';

// custom sorting products
const SET_PRODUCTS_LIST = 'SET_PRODUCTS_LIST';
const SET_PRODUCTS_EDITED_LIST = 'SET_PRODUCTS_EDITED_LIST';
const SET_TABS = 'SET_TABS';
const SET_PRODUCTS_SEARCH_NAME = 'SET_PRODUCTS_SEARCH_NAME';
const SET_PRODUCTS_SORT_DIRECTION = 'PRODUCTS_SORT_DIRECTION';
const SET_PRODUCTS_SORT_COLUMN = 'SET_PRODUCTS_SORT_COLUMN';
const SET_PRODUCTS_PER_PAGE = 'SET_PRODUCTS_PER_PAGE';
const SET_PRODUCTS_PAGE = 'SET_PRODUCTS_PAGE';
const SET_PRODUCTS_PAGE_COUNT = 'SET_PRODUCTS_PAGE_COUNT';
const SET_PRETENSIONS_INFO_COLUMN = 'SET_PRETENSIONS_INFO_COLUMN';
const SET_INFO_PAGE = 'SET_INFO_PAGE';
const SET_INFO_PAGE_TITLE = 'SET_INFO_PAGE_TITLE';

// images
const SET_TOTAL_IMAGES_CLIENT = 'SET_TOTAL_IMAGES_CLIENT';



const combineConst = {
  SET_PRETENSIONS_LIST,
  SET_FACTURES_LIST,
  SET_IMAGES_LIST,
  SET_PRETENSION_COMMENT,
  SET_PRETENSIONS_SECTION,
  SET_PRETENSIONS_STEP,
  SET_PRETENSION_FACTURE_NUM,
  SET_PRETENSION_FACTURE_DATE,
  SET_PRETENSION_FACTURE_UID,
  SET_CURRENT_PRETENSION_UID,
  SET_CURRENT_PRETENSION,
  SET_CURRENT_PRETENSION_DIALOG,
  SET_CURRENT_PRETENSION_MESSAGE,
  SET_SEARCH_DATE_START,
  SET_SEARCH_DATE_END,
  SET_SEARCH_FACTURE_NUM,
  SET_IMAGES_ADMIN_LIST,
  SET_IMAGES_CLIENT_LIST,
  SET_SEARCH_STATUS,
  SET_PRETENSIONS_SORT_DIRECTION,
  SET_PRETENSIONS_SORT_COLUMN,
  SET_ERROR_NUM,
  SET_PRELOADER,
  SET_LIST_PRELOADER,
  SET_PER_PAGE,
  SET_PAGE,
  SET_PAGE_COUNT,
  SET_PRETENSIONS_ERROR,
  SET_PRETENSIONS_INFO_COLUMN,
  SET_INFO_PAGE,
  SET_INFO_PAGE_TITLE,


  SET_PRODUCTS_LIST,
  SET_PRODUCTS_EDITED_LIST,
  SET_TABS,
  SET_PRODUCTS_SEARCH_NAME,
  SET_PRODUCTS_SORT_DIRECTION,
  SET_PRODUCTS_SORT_COLUMN,
  SET_PRODUCTS_PER_PAGE,
  SET_PRODUCTS_PAGE,
  SET_PRODUCTS_PAGE_COUNT,
  SET_TOTAL_IMAGES_CLIENT,
};

export default combineConst;

