import factureTypes from '../types/facture';
import certificatesActions from './certificates';
import configActions from './config';
import api from '../../api/api';
import certificatesStore from "../reducer/certificates";

const setCertificateList = (array) => ({
  type: factureTypes.SET_CERTIFICATE_LIST,
  array,
});

const setCurrentPage = (num) => ({
  type: factureTypes.SET_FACTURES_PAGE,
  num,
});

const setCurrentFacture = (value) => ({
  type: factureTypes.SET_CURRENT_FACTURE,
  value,
});

const setCurrentFactureUid = (uid) => ({
  type: factureTypes.SET_CURRENT_FACTURE_UID,
  uid,
});

const setCurrentFactureDate = (date) => ({
  type: factureTypes.SET_CURRENT_FACTURE_DATE,
  date,
});

const setCurrentFactureDateRaw = (date) => ({
  type: factureTypes.SET_CURRENT_FACTURE_DATE_RAW,
  date,
});

const setPageCount = (num) => ({
  type: factureTypes.SET_FACTURES_PAGE_COUNT,
  num,
});

const setPerPage = (num) => ({
  type: factureTypes.SET_FACTURES_PER_PAGE,
  num,
});

const setInvoiceNum = (num) => ({
  type: factureTypes.SET_INVOICE_NUM,
  num,
});

const setInfoPage = (str) => ({
  type: factureTypes.SET_INFO_PAGE,
  str,
});

const setGoodSearchText = (str) => ({
  type: factureTypes.SET_GOOD_SEARCH,
  str,
});

const setGoodSearchSeries = (str) => ({
  type: factureTypes.SET_GOOD_SEARCH_SERIES,
  str,
});

const setInfoPageTitle = (title) => ({
  type: factureTypes.SET_INFO_PAGE_TITLE,
  title,
});

const setSearchDate = (date) => ({
  type: factureTypes.SET_SEARCH_DATE,
  date,
});

const setSearchNum = (num) => ({
  type: factureTypes.SET_SEARCH_FACTURE_NUM,
  num,
});

const setSearchErrorNum = (num) => ({
  type: factureTypes.SET_SEARCH_ERROR_NUM,
  num,
});

const setGoodSearchErrorBool = (bool) => ({
  type: factureTypes.SET_GOOD_SEARCH_ERROR,
  bool,
});

const setGoodSearchHeadline = (text) => ({
  type: factureTypes.SET_GOOD_HEADLINE,
  text,
});
const setPreloader = (value) => ({
  type: factureTypes.SET_PRELOADER,
  value,
});
const setFacturesSortCol = (value) => ({
  type: factureTypes.SET_FACTURES_SORT_COL,
  value,
});
const setFacturesSortDir = (value) => ({
  type: factureTypes.SET_FACTURES_SORT_DIR,
  value,
});

const searchGood = (lang, uid, goodName, seriesName) => async (dispatch) => {
  dispatch(certificatesActions.setRightPreloader(true));
  // Сбрасываем выбранную фактуру
  await dispatch(setCurrentFacture(''));
  await dispatch(setCurrentFactureDate(undefined));
  await dispatch(setCurrentFactureUid(''));


  let str = '';
  if (lang) {
    str = `Пошук ${goodName} ${seriesName !== '' ? `серія: ${seriesName}` : ''}`;
  } else {
    str = `Поиск ${goodName} ${seriesName !== '' ? `серия: ${seriesName}` : ''}`;
  }
  const data = new FormData();

  data.append('addr_uid', uid);
  data.append('GoodName', goodName);
  data.append('SeriesName', seriesName);

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/certificates`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (response.data.error) {
    await dispatch(setGoodSearchErrorBool(true));
  } else {

    await dispatch(setGoodSearchErrorBool(false));
    await dispatch(certificatesActions.setCertificatesList(response.data.result.list));
    await dispatch(certificatesActions.setSelectedList([]));
    await dispatch(certificatesActions.checkAllItemsBool([], response.data.result.list));
    await dispatch(setGoodSearchHeadline(str));
  }
  dispatch(certificatesActions.setRightPreloader(false));
  return null;
};

const fetchFactures = (lang, uid, perPage, page, docNum, date, sortColumn, sortDirection) => async (dispatch) => {
  dispatch(setPreloader(true));
  const data = new FormData();

  if (sortColumn && sortDirection) {
    data.append('SortColumn', sortColumn);
    data.append('SortDirection', sortDirection);
  }
  data.append('per_page', perPage);
  data.append('AutoDestUID', uid);

  if (page) {
    data.append('page', page);
  } else {
    data.append('page', 1);
  }
  if (docNum) {
    data.append('docnum', docNum);
  }
  if (date) {
    const ourDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    data.append('date', ourDate);
  }

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/factures`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  if (docNum) {
    await dispatch(setSearchNum(docNum));
  }
  if (date) {
    await dispatch(setSearchDate(date));
  }

  if (response.data.error) {
    await dispatch(setSearchErrorNum(2));
  } else {
    await dispatch(setSearchErrorNum(0));
    await dispatch(setCurrentFacture(''));
    await dispatch(setCurrentFactureDate(''));
    await dispatch(setCurrentFactureUid(''));
    await dispatch(setPageCount(response.data.result.total_pages));
    await dispatch(setCertificateList(response.data.result.list));
  }
  dispatch(setPreloader(false));
  return null;
};

const fetchFacturesInfo = (lang) => async (dispatch) => {
  const data = new FormData();

  data.append('url_key', 'instruction-certificates');

  const response = await api.post(`${lang ? 'ua' : ''}/api/react/getpage`, data);

  if (response.data.login === false) {
    await dispatch(configActions.setLoginBool(false));
    return null;
  }

  dispatch(setInfoPage(response.data.structure.content));
  dispatch(setInfoPageTitle(response.data.structure.title));
  return null;
};

export default {
  fetchFactures,
  setInvoiceNum,
  setPerPage,
  setPageCount,
  setCurrentPage,
  fetchFacturesInfo,
  setSearchDate,
  setSearchNum,
  setGoodSearchErrorBool,
  setCurrentFacture,
  setSearchErrorNum,
  setCurrentFactureUid,
  setGoodSearchSeries,
  setGoodSearchText,
  setGoodSearchHeadline,
  setCurrentFactureDate,
  setCurrentFactureDateRaw,
  setCertificateList,
  searchGood,
  setPreloader,
  setFacturesSortCol,
  setFacturesSortDir,
};
