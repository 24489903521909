// commons
const SET_MAILS_ERROR = 'SET_PRETENSIONS_ERROR';

// mails search
const SET_SEARCH_NUM = "SET_SEARCH_NUM";
const SET_DATE_START = 'SET_DATE_START';
const SET_DATE_END = 'SET_DATE_END';
const SET_DATE_START_DISPLAY = 'SET_DATE_START_DISPLAY';
const SET_DATE_END_DISPLAY = 'SET_DATE_END_DISPLAY';

// mails list
const SET_MAILS_LIST = 'SET_MAILS_LIST';
const SET_MAILS_DOWNLOAD_LIST = 'SET_MAILS_DOWNLOAD_LIST';
const SET_MAILS_PRELOADER = 'SET_MAILS_PRELOADER';
const SET_ALL_CHECKED = 'SET_ALL_CHECKED'

// mails filters search and pagination
const SET_MAILS_TYPE = 'SET_MAILS_TYPE';
const SET_MAILS_ONLY_NEW = 'SET_MAILS_ONLY_NEW';
const SET_MAILS_SORT_COLUMN = 'SET_MAILS_SORT_COLUMN';
const SET_MAILS_SORT_DIRECTION = 'SET_MAILS_SORT_DIRECTION';
const SET_MAILS_PER_PAGE = 'SET_MAILS_PER_PAGE';
const SET_MAILS_PAGE = 'SET_MAILS_PAGE';
const SET_MAILS_PAGE_COUNT = 'SET_MAILS_PAGE_COUNT';
const SET_MAILS_TOTAL_COUNT = 'SET_MAILS_TOTAL_COUNT';

// mail item
const SET_CURRENT_MAIL_UID = 'SET_CURRENT_MAIL_UID';

// mail factures list
const SET_FACTURES_PRELOADER = 'SET_FACTURES_PRELOADER';
const SET_MAIL_FACTURES_LIST = 'SET_MAIL_FACTURES_LIST';
const SET_FACTURES_SORT_DIRECTION = 'SET_FACTURES_SORT_DIRECTION';
const SET_FACTURES_SORT_COLUMN = 'SET_FACTURES_SORT_COLUMN';
const SET_FACTURES_PER_PAGE = 'SET_FACTURES_PER_PAGE';
const SET_FACTURES_PAGE = 'SET_FACTURES_PAGE';
const SET_FACTURES_PAGE_COUNT = 'SET_FACTURES_PAGE_COUNT';
const SET_FACTURES_TOTAL_COUNT = 'SET_FACTURES_TOTAL_COUNT';

// info page
const SET_INFO_PAGE = 'SET_INFO_PAGE';
const SET_INFO_PAGE_TITLE = 'SET_INFO_PAGE_TITLE';
const SET_INFO = 'SET_INFO';


const combineConst = {
  SET_MAILS_ERROR,
  SET_SEARCH_NUM,
  SET_DATE_START,
  SET_DATE_END,
  SET_DATE_START_DISPLAY,
  SET_DATE_END_DISPLAY,
  SET_MAILS_LIST,
  SET_MAILS_DOWNLOAD_LIST,
  SET_MAILS_PRELOADER,
  SET_ALL_CHECKED,
  SET_MAILS_TYPE,
  SET_MAILS_ONLY_NEW,
  SET_MAILS_SORT_COLUMN,
  SET_MAILS_SORT_DIRECTION,
  SET_MAILS_PER_PAGE,
  SET_MAILS_PAGE,
  SET_MAILS_PAGE_COUNT,
  SET_MAILS_TOTAL_COUNT,
  SET_CURRENT_MAIL_UID,
  SET_FACTURES_PRELOADER,
  SET_MAIL_FACTURES_LIST,
  SET_FACTURES_SORT_DIRECTION,
  SET_FACTURES_SORT_COLUMN,
  SET_FACTURES_PER_PAGE,
  SET_FACTURES_PAGE,
  SET_FACTURES_PAGE_COUNT,
  SET_FACTURES_TOTAL_COUNT,
  SET_INFO_PAGE,
  SET_INFO_PAGE_TITLE,
  SET_INFO,
};

export default combineConst;

