import React, {useEffect, useRef, useState} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import CustomScroll from "react-custom-scroll";
import DatePicker from "react-datepicker";
import uk from "date-fns/locale/uk";
import ru from "date-fns/locale/ru";
import pretensionsActions from "../../store/actions/pretensions";
import reportActions from "../../store/actions/report";
import Checkbox from "../Checkbox/Checkbox";
import deliveryAction from "../../store/actions/delivery";
import ModalPreloader from "../Preloader/ModalPreloader";



const allDeliveryPlace    = {
  ua : 'Всі пункти доставки',
  ru : 'Все пункты доставки',
};
const searchDeliveryPlace = {
  ua : 'Пошук пункту доставки',
  ru : 'Поиск пункта доставки',
};
const titleCreate = {
  ua : 'Створення звіту',
  ru : 'Создание отчета',
};
const titleDelivery = {
  ua : 'Обрати пункт доставки',
  ru : 'Выбрать пункт доставки',
};
const textStep = {
  ua : 'Крок',
  ru : 'Шаг',
};
const choosePeriod = {
  ua : 'Оберіть період',
  ru : 'Выберите период',
};
const chooseDataType = {
  ua : 'Оберіть тип даних',
  ru : 'Выберите тип данных',
};
const chooseDelivery = {
  ua : 'Оберіть пункт доставки',
  ru : 'Выберите пункт доставки',
};
const chooseDeliveryTitle = {
  ua : 'Обрати пункт доставки',
  ru : 'Выбрать пункт доставки',
};
const chooseAll = {
  ua : 'Обрати всі пункти доставки для всіх клієнтів',
  ru : 'Выбрать все пункты доставки для всех клиентов',
};
const chosenTotal = {
  ua : 'Усього обрано пунктів доставки',
  ru : 'Всего выбрано пунктов доставки',
};
const formalizeReport = {
  ua : 'Сформувати звіт',
  ru : 'Сформировать отчет',
};
const confirmChosen = {
  ua : 'Підтвердити вибір пунктів доставки',
  ru : 'Подтвердить выбор пунктов доставки',
};
const closeWindow = {
  ua : 'Закрити вікно',
  ru : 'Закрыть окно',
};
const modalError = {
  ua : 'За данними критеріями пошуку нічого не знайдено',
  ru : 'По данным критериям поиска ничего не найдено',
};

const ReportModal = () => {
  const reportStore = useSelector((state) => state.reportStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore   = useSelector((store) => store.configStore);
  const datepickerReportStart    = useRef(null);
  const datepickerReportEnd    = useRef(null);
  const dispatch      = useDispatch();


  // Фильтр адресов
  const itemsList     = Array.from(document.querySelectorAll('.report-modal__delivery-list-item .checkbox__wrapper'));
  let childrensList = [];
  let str             = '';
  const searchAddress = (value) => {
    itemsList.forEach((i) => {
      childrensList = Array.from(i.children);
      childrensList.forEach((elem) => {
        if (elem.classList.contains('checkbox__text')) {
          str = elem.textContent.toLowerCase()
        }
      });
      if (str.indexOf(value.toLowerCase()) < 0) {
        i.parentNode.classList.add('hidden');
      } else {
        i.parentNode.classList.remove('hidden');
      }
    });
  };


  const setList = () => {
    const addressesList = deliveryStore.addresses;
    const reportAddressesList = [];
    addressesList.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.checked = false;
      reportAddressesList.push(el);
    });
    dispatch(reportActions.setReportAddresses(reportAddressesList));
  }

  const cityList = () =>
    reportStore.reportAddresses.map((el) => (
      <li className="report-modal__delivery-list-item" key={el.uid}>
        <Checkbox
          checkedState = {el.checked}
          id={el.uid}
          text={el.autodestname}
          checkedAction={() => dispatch(reportActions.changeReportAddressCheck(
            el,
            reportStore.reportAddresses,
            reportStore.reportAddressesChecked,
          ))}
          />
      </li>
    ));

  const setAllAddresses = (val) => {
    dispatch(reportActions.setReportError(''));
    dispatch(reportActions.setReportAddressesAll(val));
    dispatch(reportActions.checkAllAddresses(
      reportStore.reportAddresses,
      val
    ));
  }

  const dataTypes = () =>
    reportStore.reportDataTypes.map((el) => (
      <Checkbox
        id={el.id}
        checkedState = {el.checked}
        text={configStore.isCurrentLangUa ? el.name_ua : el.name_ru}
        checkedAction={() => dispatch(reportActions.changeDataType(el.id, reportStore.reportDataTypes))}
      />
    ));

  const setReportDateStart = (date) => {
    dispatch(reportActions.setReportError(''));
    let dateRawStart = date;
    const dateRawEnd = reportStore.dateStart;
    if (dateRawEnd && dateRawEnd !== ''){
      if (dateRawStart > dateRawEnd) {
        dateRawStart = dateRawEnd
      }
    }
    dispatch(reportActions.setDateStart(dateRawStart));
  };
  const setReportDateEnd = (date) => {
    dispatch(reportActions.setReportError(''));
    let dateRawEnd = date;
    const dateRawStart = reportStore.dateEnd;
    if (dateRawStart && dateRawStart !== ''){
      if (dateRawEnd < dateRawStart) {
        dateRawEnd = dateRawStart
      }
    }
    dispatch(reportActions.setDateEnd(dateRawEnd));
  };
  const handleChangeRaw = (ev, value) => {
    const regexp = new RegExp(
      /[a-zA-Zа-яА-Я!@#$%^&*()_+=?,/]/g
    );
    if (regexp.test(value) || value.length > 10) {
      ev.preventDefault();
    }
  };

  const focusHiddenDatepickerStart = () => {
    datepickerReportStart.current.input.focus();
  };
  const focusHiddenDatepickerEnd = () => {
    datepickerReportEnd.current.input.focus();
  };
  const resetDateStart = () => {
    dispatch(reportActions.setReportError(''));
    dispatch(reportActions.setDateStart(undefined));
  };
  const resetDateEnd = () => {
    dispatch(reportActions.setReportError(''));
    dispatch(reportActions.setDateEnd(undefined));
  };


  let isOpen = false;

  if (reportStore.reportModal === true){
    isOpen = true;
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  const goStep = (step) => {
    dispatch(reportActions.setReportError(''));
    dispatch(reportActions.setReportModalStep(step));
  }

  const closeModal = () => {
    dispatch(reportActions.setReportError(''));
    dispatch(reportActions.setReportModal(false));
    goStep(1);
  }

  const getReport = () => {
    dispatch(reportActions.setReportError(''));
    dispatch(reportActions.formalizeReport(
      configStore.isCurrentLangUa,
      reportStore.dateReportStart,
      reportStore.dateReportEnd,
      reportStore.reportDataTypes,
      reportStore.reportAddressesChecked,
    ));
  }

  useEffect(() => {
    setList()
  }, [
    configStore.isCurrentLangUa,
    reportStore.reportModal,
  ]);

  return (
    <div className={`report-modal ${isOpen ? 'open' : ''}`}>
      <div className="report-modal__wrapper">
        <div className="report-modal__block">
          <button type="button" className="report-modal__close" onClick={closeModal}>
            <i className="icon-close-thin"/>
          </button>
          <div className="report-modal__block-content">
            <ModalPreloader/>
            <div className="report-modal__heading">
              {reportStore.reportModalStep === 1 ? (
                <span>{configStore.isCurrentLangUa ? titleCreate.ua : titleCreate.ru}</span>
              ) : (
                <span>{configStore.isCurrentLangUa ? titleDelivery.ua : titleDelivery.ru}</span>
              )}
            </div>
            <div className={`report-modal__formalize ${reportStore.reportModalStep === 1 ? '' : 'hidden'}`}>
              <div className="report-modal__steps">
                <div className="report-modal__step step1">
                  <div className="report-modal__step-title">
                    <div className="report-modal__step-title-number">
                      1
                    </div>
                    <div className="report-modal__step-title-text">
                    <span className="report-modal__step-title-text-colored">
                      {configStore.isCurrentLangUa ? textStep.ua : textStep.ru} 1: </span>
                      {configStore.isCurrentLangUa ? choosePeriod.ua : choosePeriod.ru}
                    </div>
                  </div>
                  <div className="report-modal__step-content">
                    <div className='search-pretensions__calendar'>
                      <div className="react-datepicker-wrapper">
                        <div className="react-datepicker__input-container">
                          <DatePicker
                            locale={configStore.isCurrentLangUa ? uk : ru}
                            ref={datepickerReportStart}
                            placeholderText="Дата"
                            selected={reportStore.dateReportStart}
                            onChange={(date) => setReportDateStart(date)}
                            dateFormat="dd.MM.yyyy"
                            onChangeRaw={event => handleChangeRaw(event, event.target.value)}
                          />
                        </div>
                      </div>
                      {reportStore.dateReportStart === undefined ? (
                        <button
                          onClick={focusHiddenDatepickerStart}
                          type="button"
                          className="search-certificate__calendar-icon"
                        >
                          <i className="icon-calendar"/>
                        </button>
                      ) : (
                        <button
                          onClick={resetDateStart}
                          type="button"
                          className="search-certificate__button-reset"
                        >
                          <i className="icon-cross"/>
                        </button>
                      )}
                    </div>
                    <span>по</span>
                    <div className='search-pretensions__calendar'>
                      <div className="react-datepicker-wrapper">
                        <div className="react-datepicker__input-container">
                          <DatePicker
                            locale={configStore.isCurrentLangUa ? uk : ru}
                            ref={datepickerReportEnd}
                            placeholderText="Дата"
                            selected={reportStore.dateReportEnd}
                            onChange={(date) => setReportDateEnd(date)}
                            dateFormat="dd.MM.yyyy"
                            onChangeRaw={event => handleChangeRaw(event, event.target.value)}
                          />
                        </div>
                      </div>
                      {reportStore.dateReportEnd === undefined ? (
                        <button
                          onClick={focusHiddenDatepickerEnd}
                          type="button"
                          className="search-certificate__calendar-icon"
                        >
                          <i className="icon-calendar"/>
                        </button>
                      ) : (
                        <button
                          onClick={resetDateEnd}
                          type="button"
                          className="search-certificate__button-reset"
                        >
                          <i className="icon-cross"/>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="report-modal__step step2">
                  <div className="report-modal__step-title">
                    <div className="report-modal__step-title-number">
                      2
                    </div>
                    <div className="report-modal__step-title-text">
                    <span className="report-modal__step-title-text-colored">
                      {configStore.isCurrentLangUa ? textStep.ua : textStep.ru} 2: </span>
                      {configStore.isCurrentLangUa ? chooseDataType.ua : chooseDataType.ru}
                    </div>
                  </div>
                  <div className="report-modal__step-content">
                    {dataTypes()}
                  </div>
                </div>
                <div className="report-modal__step step3">
                  <div className="report-modal__step-title">
                    <div className="report-modal__step-title-number">
                      3
                    </div>
                    <div className="report-modal__step-title-text">
                    <span className="report-modal__step-title-text-colored">
                      {configStore.isCurrentLangUa ? textStep.ua : textStep.ru} 3: </span>
                      {configStore.isCurrentLangUa ? chooseDelivery.ua : chooseDelivery.ru}
                    </div>
                  </div>
                  <div className="report-modal__step-content">
                    <button
                      className="report-modal__btn-choose"
                      type="button"
                      onClick={() => goStep(2)}
                    >
                      {configStore.isCurrentLangUa ? chooseDeliveryTitle.ua : chooseDeliveryTitle.ru}
                    </button>
                    <Checkbox
                      checkedState = {reportStore.reportAddressesAll}
                      id="input-all-addr"
                      text={configStore.isCurrentLangUa ? chooseAll.ua : chooseAll.ru}
                      checkedAction={() => setAllAddresses(!reportStore.reportAddressesAll)}
                    />
                    <div className="report-modal__delivery-chosen">
                      {configStore.isCurrentLangUa ? chosenTotal.ua : chosenTotal.ru}
                      <span className="report-modal__delivery-chosen-val">
                      {reportStore.reportAddressesChecked.length}
                    </span>
                    </div>
                  </div>
                </div>
                <div className="report-modal__step step4">
                  <div className="report-modal__step-title">
                    <div className="report-modal__step-title-number">
                      <i className="icon-tick"/>
                    </div>
                    <button
                      type="button"
                      className="report-modal__btn-submit"
                      onClick={getReport}
                    >
                      <i className="icon-report"/>
                      {configStore.isCurrentLangUa ? formalizeReport.ua : formalizeReport.ru}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`report-modal__choose ${reportStore.reportModalStep === 2 ? '' : 'hidden'}`}>
              <div className="report-modal__delivery-search">
                <input
                  className="delivery__input"
                  id="delivery-input"
                  type="text"
                  placeholder={
                    configStore.isCurrentLangUa ? searchDeliveryPlace.ua : searchDeliveryPlace.ru
                  }
                  onInput={(ev) => searchAddress(ev.target.value)}
                  autoComplete="off"
                />
                <button type="submit" className="delivery__input-submit">
                  <i className="icon-search"/>
                </button>
              </div>
              <div className="report-modal__delivery-list-title">
                {configStore.isCurrentLangUa ? allDeliveryPlace.ua : allDeliveryPlace.ru}
              </div>
              <div className="report-modal__delivery-list">
                <CustomScroll heightRelativeToParent="300px">
                  <ul className="report-modal__delivery-list-ul">{cityList()}</ul>
                </CustomScroll>
              </div>
              <div className="report-modal__delivery-chosen">
                {configStore.isCurrentLangUa ? chosenTotal.ua : chosenTotal.ru}
                <span className="report-modal__delivery-chosen-val">
                {reportStore.reportAddressesChecked.length}
              </span>
              </div>
              <div className="report-modal__delivery-footer">
                <button
                  type="button"
                  className="report-modal__delivery-submit"
                  onClick={() => goStep(1)}
                >
                  {configStore.isCurrentLangUa ? confirmChosen.ua : confirmChosen.ru}
                </button>
                <button
                  type="button"
                  className="report-modal__delivery-back"
                  onClick={() => goStep(1)}
                >
                  {configStore.isCurrentLangUa ? closeWindow.ua : closeWindow.ru}
                </button>
              </div>
            </div>
            <div className={`report-modal__error ${reportStore.reportError === '' ? 'hidden' : ''}`}>
              {configStore.isCurrentLangUa ? modalError.ua : modalError.ru}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default ReportModal;
