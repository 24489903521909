import React, {useEffect} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import pretensionsActions from '../../store/actions/pretensions';

const SearchFactures = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();
  const searchText = {
    ua : 'Пошук',
    ru : 'Поиск',
  };
  const floatDocumentInfo = {
    ua : 'Введіть 3 або більше символів. Допустимі символи: "-", "/" та цифри.',
    ru : 'Введите 3 или более символов. Допустимые символы: "-", "/" и цифры.',
  };
  const searchPlaceholder = {
    ua : '№ фактури, № інтернет-замовлення, № заявки',
    ru : '№ фактуры, № интернет-заказа, № заявки',
  };


  const submitFormFactures = (ev) => {
    ev.preventDefault();
    dispatch(
      pretensionsActions.fetchFactures(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.searchFactureNum,
        pretensionsStore.pretensionsSortColumn,
        pretensionsStore.pretensionsSortDirection,
        10,
        1,
      ),
    );
  };

  const setFactureNum = (ev, num) => {
    ev.preventDefault();
    const regexp = new RegExp('^[0-9/-]+$');

    if (num.match(regexp) !== null || num === '') {
      dispatch(pretensionsActions.setSearchFactureNum(num));
    }
  };

  const resetSearch = () => {
    dispatch(pretensionsActions.setSearchFactureNum(''));
    dispatch(pretensionsActions.setPerPage(10));
    dispatch(pretensionsActions.setCurrentPage(1));
    dispatch(
      pretensionsActions.fetchFactures(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        '',
        '',
        '',
        10,
        1,
      ),
    );
  };

  return (
    <div className="pretension-search-factures">
      <p className="pretension-search-factures__headline">
        {configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
      </p>
      <form className="pretension-search-factures__form" onSubmit={(ev) => submitFormFactures(ev)}>
        <div className="pretension-search-factures__form-wrapper">
          <div className="search-certificate__input-product-wrapper">
						<span className="float-info">
							{configStore.isCurrentLangUa ? floatDocumentInfo.ua : floatDocumentInfo.ru}
						</span>
            <input
              pattern="^[0-9/-]+$"
              className={`pretension-search-factures__input
						${pretensionsStore.errorNum !== 0 ? 'input-error' : ''}
						`}
              type="text"
              onInput={(ev) => setFactureNum(ev, ev.target.value)}
              placeholder={`${configStore.isCurrentLangUa ? searchPlaceholder.ua : searchPlaceholder.ru}`}
              value={pretensionsStore.searchFactureNum}
            />
            {pretensionsStore.searchFactureNum !== '' && (
              <button
                onClick={resetSearch}
                type="button"
                className="search-certificate__button-reset search-certificate__button-reset-facture"
              >
                <i className="icon-cross"/>
              </button>
            )}
          </div>
        </div>
        <button type="submit" className="pretension-search-factures__submit">
          <i className="icon-search pretension-search-factures__submit-icon"/>
          <span className="pretension-search-factures__submit-text">
						{configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
					</span>
        </button>
      </form>
    </div>
  );
};

export default SearchFactures;
