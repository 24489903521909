import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import menuActions from '../../store/actions/menu';

const Navigation = () => {
  const menuState = useSelector((store) => store.menuStore);
  const configState = useSelector((store) => store.configStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const dispatch = useDispatch();

  const navList = menuState.menuList.map((elem) => (
    <NavLink
      className="navigation__item"
      activeClassName="navigation__item-active"
      key={elem.url_key}
      to={elem.react_url}
    >
      <i className={`icon-${elem.class_list} navigation__item-icon`} />
      <span className="navigation__item-text">{elem.title}</span>
      <div className={`navigation__item-notice ${elem.notification > 0 ? '' : 'hidden'}`}>{elem.notification}</div>
    </NavLink>
  ));

  useEffect(() => {
    dispatch(menuActions.fetchMenu(configState.isCurrentLangUa, deliveryStore.selectCityUid));
  }, [configState.isCurrentLangUa, deliveryStore.selectCityUid]);

  return (
    <nav className="navigation">
      <div className="navigation__list">{navList}</div>
    </nav>
  );
};

export default Navigation;

