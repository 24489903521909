import React, {useEffect} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import ReactPaginate from "react-paginate";
import Preloader from "../Preloader/Preloader";
import PretensionItem from "./PretensionItem";
import pretensionsActions from '../../store/actions/pretensions';
import PretensionViewItem from "./PretensionViewItem";
import CountList from "../CountList/CountList";

const textName = {
  ru : 'Наименование товара',
  ua : 'Найменування товару',
};
const textSeries = {
  ru : 'Серия',
  ua : 'Серія',
};
const textAmount = {
  ru : 'Кол-во',
  ua : 'Кіль-ть',
};
const textPrice = {
  ru : 'Цена',
  ua : 'Ціна',
};
const textLack = {
  ru : 'Недостача',
  ua : 'Нестача',
};
const textBroken = {
  ru : 'Бой',
  ua : 'Бій',
};
const textDefect = {
  ru : 'Брак',
  ua : 'Брак',
};
const textSurplus = {
  ru : 'Излишки',
  ua : 'Надлишки',
};




const PretensionViewList = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const configStore    = useSelector((store) => store.configStore);
  const deliveryStore  = useSelector((store) => store.deliveryStore);
  const dispatch       = useDispatch();


  let listPretensionProducts;
  if (pretensionsStore.currentPretension.lines) {
    listPretensionProducts = pretensionsStore.currentPretension.lines.map((elem) => (
      <PretensionViewItem
        key={elem.invoiceoutuid}
        info={elem}
      />
    ));
  } else {
    listPretensionProducts = '';
  }

  const sortProducts = (column) => {
    let direction = pretensionsStore.pretensionsSortDirection;
    if (direction === 'DESC' || direction === '') {
      direction = 'ASC';
      dispatch(pretensionsActions.setPretensionsSortDirection('ASC'));
    } else {
      direction = 'DESC';
      dispatch(pretensionsActions.setPretensionsSortDirection('DESC'));
    }
    dispatch(pretensionsActions.setPretensionsSortColumn(column));
    dispatch(
      pretensionsActions.fetchCurrentPretension(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionsStore.currentPretension.uid,
        pretensionsStore.currentPretension.name,
        column,
        direction,
        pretensionsStore.productsPerPage,
        pretensionsStore.productsPageCurrent,
      ),
    );
  };

  const changePerPage = (num) => {
    dispatch(pretensionsActions.setProductsPage(1));
    dispatch(pretensionsActions.setProductsPerPage(num));
  };

  const pageChange = (data) => {
    dispatch(pretensionsActions.setProductsPage(data.selected + 1));
  };


  useEffect(() => {
    if (deliveryStore.selectCityUid !== '') {
      dispatch(
        pretensionsActions.fetchCurrentPretension(
          configStore.isCurrentLangUa,
          deliveryStore.selectCityUid,
          pretensionsStore.currentPretension.uid,
          pretensionsStore.productsSearchName,
          pretensionsStore.productsSortColumn,
          pretensionsStore.productsSortDirection,
          pretensionsStore.productsPerPage,
          pretensionsStore.productsPageCurrent,
        ),
      )
    }
  }, [
    configStore.isCurrentLangUa,
    deliveryStore.selectCityUid,
    pretensionsStore.productsPerPage,
    pretensionsStore.productsPageCurrent,
  ]);


  return (
    <div className="pretension-view-list">
      <div className="pretension-view-list__header">
        <div className="pretension-view-list__header-item col-name">
          <button className="pretension-view-list__header-item-text"
                  type="button"
                  onClick={() => sortProducts('good')}>
            {configStore.isCurrentLangUa ? textName.ua : textName.ru}
          </button>
        </div>
        <div className="pretension-view-list__header-item col-series">
          <div className="pretension-view-list__header-item-text">
            {configStore.isCurrentLangUa ? textSeries.ua : textSeries.ru}
          </div>
        </div>
        <div className="pretension-view-list__header-item col-amount">
          <div className="pretension-view-list__header-item-text">
            {configStore.isCurrentLangUa ? textAmount.ua : textAmount.ru}
          </div>
        </div>
        <div className="pretension-view-list__header-item col-price">
          <button className="pretension-view-list__header-item-text"
                  type="button"
                  onClick={() => sortProducts('price')}>
            {configStore.isCurrentLangUa ? textPrice.ua : textPrice.ru}
          </button>
        </div>
        <div className="pretension-view-list__header-item col-lack">
          <div className="pretension-view-list__header-item-text">
            {configStore.isCurrentLangUa ? textLack.ua : textLack.ru}
          </div>
        </div>
        <div className="pretension-view-list__header-item col-broken">
          <div className="pretension-view-list__header-item-text">
            {configStore.isCurrentLangUa ? textBroken.ua : textBroken.ru}
          </div>
        </div>
        <div className="pretension-view-list__header-item col-defect">
          <div className="pretension-view-list__header-item-text">
            {configStore.isCurrentLangUa ? textDefect.ua : textDefect.ru}
          </div>
        </div>
        <div className="pretension-view-list__header-item col-surplus">
          <div className="pretension-view-list__header-item-text">
            {configStore.isCurrentLangUa ? textSurplus.ua : textSurplus.ru}
          </div>
        </div>
      </div>
      <ul className="pretension-view-list__list">
        {listPretensionProducts}
      </ul>
      <div className="pretension-view-list__footer">
        <CountList onClick={changePerPage} currentPerPage={pretensionsStore.productsPerPage} countOf="products"/>
        <ReactPaginate
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pretensionsStore.productsPageCount}
          forcePage={pretensionsStore.productsPageCurrent - 1}
          containerClassName="pager"
          pageLinkClassName="pager__button"
          activeLinkClassName="pager__button--active"
          previousLinkClassName="icon-arrow pager__arrow-prev"
          nextLinkClassName="icon-arrow pager__arrow-next"
          onPageChange={pageChange}
        />
      </div>
    </div>
  );
};

export default PretensionViewList;
