import React from 'react';
import { useSelector } from 'react-redux';

const UtpLogin = () => {
  const loginStore = useSelector((store) => store.loginStore);
  const UtpItems = () => loginStore.pageUtp.map((elem) => <li key={elem.id}>{elem.title}</li>);

  return (
    <ul className="utp__list">
      <UtpItems />
    </ul>
  );
};

export default UtpLogin;
