import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import background from '../../accets/images/loginBackground.jpg';
import LoginContent from '../../components/Login/LoginContent';
import LoginAuth from '../../components/Login/LoginAuth';

const title = {
  ru: 'Личный кабинет VentaLTD',
  ua: 'Особистий кабінет VentaLTD',
};

const Login = () => {
  const configStore = useSelector((store) => store.configStore);

  return (
    <>
      <Helmet>
        <title>{configStore.isCurrentLangUa ? title.ua : title.ru}</title>
      </Helmet>
      <div className="login__content" style={{ backgroundImage: `url(${background})` }}>
        <div className="login__wrapper">
          <LoginContent />
          <LoginAuth />
        </div>
      </div>
    </>
  );
};

export default Login;
