import React, {useEffect} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import ReactPaginate from 'react-paginate';
import CountList from '../CountList/CountList';
import DocumentItem from './DocumentItem';
import documentsActions from '../../store/actions/documents';
import Preloader from "../Preloader/Preloader";
import menuActions from "../../store/actions/menu";

const DocumentsFactures = () => {
	const documentsStore = useSelector((state) => state.documentsStore);
	const configStore    = useSelector((store) => store.configStore);
	const deliveryStore  = useSelector((store) => store.deliveryStore);
	const dispatch       = useDispatch();

	const numberDoc = '№ документа';
	const dateDoc   = 'Дата';
	const amountDoc = {
		ua : 'Ціна',
		ru : 'Цена',
	};

	const listFactures = documentsStore.documentsList.map((elem) => (
		<DocumentItem
			key={elem.uid}
			number={elem.docnum}
			date={elem.date}
			uid={elem.uid}
			price={elem.amount}
		/>
	));

	const changePerPage = (num) => {
		dispatch(documentsActions.setPreloader(true));
		dispatch(documentsActions.setCurrentPage(1));
		dispatch(documentsActions.setPerPage(num));
	};

	const pageChange = (data) => {
		dispatch(documentsActions.setCurrentPage(data.selected + 1));
	};

	const sortDocuments = (column) => {
		let direction = documentsStore.documentsSortDirection;
		if (direction === 'DESC' || direction === '') {
			direction = 'ASC';
			dispatch(documentsActions.setDocumentsSortDir('ASC'));
		} else {
			direction = 'DESC';
			dispatch(documentsActions.setDocumentsSortDir('DESC'));
		}
		dispatch(documentsActions.setDocumentsSortCol(column));
		dispatch(
			documentsActions.fetchFactures(
				configStore.isCurrentLangUa,
				deliveryStore.selectCityUid,
				documentsStore.perPage,
				documentsStore.currentPage,
				documentsStore.searchFactureNum,
				column,
				direction,
			),
		);
	};

	useEffect(() => {
		if (deliveryStore.selectCityUid !== '') {
			dispatch(documentsActions.setPreloader(true));
			dispatch(
				documentsActions.fetchFactures(
					configStore.isCurrentLangUa,
					deliveryStore.selectCityUid,
					documentsStore.perPage,
					documentsStore.currentPage,
					documentsStore.searchFactureNum,
				),
			);
		}
	}, [
		configStore.isCurrentLangUa,
		deliveryStore.selectCityUid,
		documentsStore.perPage,
		documentsStore.currentPage,
	]);

  useEffect(() => {
    dispatch(menuActions.fetchMenu(configStore.isCurrentLangUa, deliveryStore.selectCityUid));
  }, [
    deliveryStore.selectCityUid,
  ]);

	return (
		<div className="certificate-content__documents">
			<Preloader/>
			<div className="certificate-content__documents-header">
				<div className="certificate-content__documents-header-document-container">
					<button type="button" onClick={() => sortDocuments('docnum')}
					        className="certificate-content__documents-header-document-text">
						{numberDoc}
					</button>
				</div>
				<div className="certificate-content__documents-header-date-container">
					<button type="button" onClick={() => sortDocuments('date')}
					        className="certificate-content__documents-header-document-text">
						{dateDoc}
					</button>
				</div>
				<div className="certificate-content__documents-header-date-container">
					<button type="button" onClick={() => sortDocuments('amount')}
					        className="certificate-content__documents-header-document-text">
						{configStore.isCurrentLangUa ? amountDoc.ua : amountDoc.ru}
					</button>
				</div>
			</div>
			<ul className="certificate-content__documents-list">{listFactures}</ul>
			<div className="certificate-content__bottom-nav">
				<CountList onClick={changePerPage} currentPerPage={documentsStore.perPage} countOf="factures"/>
				<ReactPaginate
					pageRangeDisplayed={2}
					marginPagesDisplayed={2}
					pageCount={documentsStore.pageCount}
					forcePage={documentsStore.currentPage - 1}
					containerClassName="pager"
					pageLinkClassName="pager__button"
					activeLinkClassName="pager__button--active"
					previousLinkClassName="icon-arrow pager__arrow-prev"
					nextLinkClassName="icon-arrow pager__arrow-next"
					onPageChange={pageChange}
				/>
			</div>
		</div>
	);
};

export default DocumentsFactures;
