import React, {useEffect, useRef, useState} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import CustomScroll from "react-custom-scroll";
import pretensionsActions from "../../store/actions/pretensions";
import PretensionViewItem from "./PretensionViewItem";
import PretensionChatMessage from "./PretensionChatMessage";



const PretensionChat = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore   = useSelector((store) => store.configStore);
  const dispatch      = useDispatch();
  const customScroll = useRef(null);
  const [count, setCount] = useState(0);

  const addannoText  = {
    ru : 'досыл аннотации',
    ua : 'досилання анотації',
  };
  const addgoodText  = {
    ru : 'досыл товара',
    ua : 'досилання товару',
  };
  const buyoverText  = {
    ru : 'приобр. излишек',
    ua : 'утвор. надлишок',
  };
  const docretText  = {
    ru : 'прием возврата с документами',
    ua : 'прийом повернення з документами',
  };
  const exgoodText  = {
    ru : 'обмен товара',
    ua : 'обмін товару',
  };
  const expertText  = {
    ru : 'экспертиза',
    ua : 'экспертиза',
  };
  const fedbrakText  = {
    ru : 'ошиб. Нет серий с фед. браком',
    ua : 'помил. Нема серій з фед. браком',
  };
  const goodretText  = {
    ru : 'возврат товара',
    ua : 'повернення товару',
  };
  const responsText  = {
    ru : 'отзыв поставщика',
    ua : 'відгук постачальника',
  };
  const retoverText  = {
    ru : 'возврат излишек',
    ua : 'повернення надлишок',
  };
  const returnText  = {
    ru : 'возврат',
    ua : 'повернення',
  };
  const voidText  = {
    ru : 'необоснованная',
    ua : 'необґрунтована',
  };
  const waitactText  = {
    ru : 'ТОРГ-2',
    ua : 'ТОРГ-2',
  };
  const problemText  = {
    ru : 'проблемная',
    ua : 'проблемна',
  };
  const corpretText  = {
    ru : 'претензия по кор.ф. невозможна',
    ua : 'претензія щодо кор.ф. неможлива',
  };
  const corretText  = {
    ru : 'позврат по кор.ф. невозможен',
    ua : 'повернення по кор.ф. неможливе',
  };
  const crpriceText  = {
    ru : 'корректировка цены',
    ua : 'коригування ціни',
  };
  const otherscText  = {
    ru : 'оформите в другом разделе',
    ua : 'оформіть в іншому розділі',
  };
  const chatTitle = {
    ru : 'Переписка с Вента',
    ua : 'Листування з Вента',
  }
  const conclusionTitle = {
    ru : 'Заключение',
    ua : 'Висновок',
  }
  const chatPlaceholder = {
    ru : 'Напишите ваше сообщение здесь...',
    ua : 'Напишіть ваше повідомлення тут...',
  }
  const buttonSend = {
    ru : 'Отправить сообщение',
    ua : 'Надіслати повідомлення',
  }

  const countVal = count + 1;
  setTimeout(() => {
    setCount(countVal)
  }, 60000);

  useEffect(() => {
    dispatch(
      pretensionsActions.getMessage(
        configStore.isCurrentLangUa,
        pretensionsStore.currentPretensionUid
      ),
    );
  }, [
    configStore.isCurrentLangUa,
    count,
  ]);



  const messages = pretensionsStore.currentPretensionDialog.map((message) => (
    <PretensionChatMessage
      key={message.uid}
      messageInfo={message}
    />
  ));

  const writeMessage = (value) => {
    let str = value;
    str = str.substring(0, 1000);
    dispatch(pretensionsActions.setCurrentPretensionMessage(str));
  }

  const sendMessage = () => {
    dispatch(pretensionsActions.sendMessage(
      configStore.isCurrentLangUa,
      pretensionsStore.currentPretensionUid,
      pretensionsStore.currentPretensionDialog,
      pretensionsStore.currentPretensionMessage
    ));
    dispatch(pretensionsActions.setCurrentPretensionMessage(''));
  }

  const conclusion = () => {
    const conclusionArr = [];
    if (pretensionsStore.currentPretension.da_addanno === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? addannoText.ua : addannoText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_addgood === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? addgoodText.ua : addgoodText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_buyover === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? buyoverText.ua : buyoverText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_docret === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? docretText.ua : docretText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_exgood === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? exgoodText.ua : exgoodText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_expert === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? expertText.ua : expertText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_fedbrak === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? fedbrakText.ua : fedbrakText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_goodret === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? goodretText.ua : goodretText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_respons === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? responsText.ua : responsText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_retover === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? retoverText.ua : retoverText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_return === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? returnText.ua : returnText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_void === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? voidText.ua : voidText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_waitact === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? waitactText.ua : waitactText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_problem === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? problemText.ua : problemText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_corpret === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? corpretText.ua : corpretText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_corret === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? corretText.ua : corretText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_crprice === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? crpriceText.ua : crpriceText.ru}`);
    }
    if (pretensionsStore.currentPretension.da_othersc === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? otherscText.ua : otherscText.ru}`);
    }

    const conclusionStr = conclusionArr.join(', ');
    return conclusionStr.charAt(0).toUpperCase() + conclusionStr.slice(1);
  }


  return (
    <div className="pretension-chat">
      <span className="pretension-chat__title">{configStore.isCurrentLangUa ? chatTitle.ua : chatTitle.ru}</span>
      <div className="pretension-chat__list">
        <CustomScroll ref={customScroll} heightRelativeToParent="300px" keepAtBottom="true" scrollTo="1000000">
          <div className="pretension-chat__list-inner">
            {messages}
          </div>
        </CustomScroll>
      </div>
      <div className="pretension-chat__conclusion">
        <div className="pretension-chat__conclusion-title">
          {configStore.isCurrentLangUa ? conclusionTitle.ua : conclusionTitle.ru}
        </div>
        <div className="pretension-chat__conclusion-text">{conclusion()}</div>
      </div>
      <div className="pretension-chat__input-group">
        <textarea
          cols="30"
          rows="2"
          onInput={(ev) => writeMessage(ev.target.value)}
          value={pretensionsStore.currentPretensionMessage}
          placeholder={`${configStore.isCurrentLangUa ? chatPlaceholder.ua : chatPlaceholder.ru}`}/>
      </div>
      <button
        className="pretension-chat__btn"
        type="button"
        onClick={sendMessage}>{configStore.isCurrentLangUa ? buttonSend.ua : buttonSend.ru}</button>
    </div>
  );
}


export default PretensionChat;
