import React, {useEffect} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import documentsActions from '../../store/actions/documents';

const SearchDocuments = () => {
	const documentsStore = useSelector((state) => state.documentsStore);
	const deliveryStore = useSelector((store) => store.deliveryStore);
	const configStore = useSelector((store) => store.configStore);
	const dispatch = useDispatch();
	const searchText = {
		ua : 'Пошук',
		ru : 'Поиск',
	};
	const floatDocumentInfo = {
		ua : 'Введіть 3 або більше символів. Допустимі символи: "-", "/" та цифри.',
		ru : 'Введите 3 или более символов. Допустимые символы: "-", "/" и цифры.',
	};

	const submitFormDocument = (ev) => {
		ev.preventDefault();
		dispatch(
			documentsActions.fetchFactures(
				configStore.isCurrentLangUa,
				deliveryStore.selectCityUid,
				documentsStore.perPage,
				documentsStore.page,
				documentsStore.searchFactureNum
			),
		);
	};

	const setFactureNum = (ev, num) => {
		ev.preventDefault();
		const regexp = new RegExp('^[0-9/-]+$');

		if (num.match(regexp) !== null || num === '') {
			dispatch(documentsActions.setSearchNum(num));
		}
	};

	const resetSearch = () => {
		dispatch(documentsActions.setSearchNum(''));
		dispatch(documentsActions.setPerPage(10));
		dispatch(documentsActions.setCurrentPage(1));
		dispatch(
			documentsActions.fetchFactures(
				configStore.isCurrentLangUa,
				deliveryStore.selectCityUid,
				10,
				1,
				''
			),
		);
	};

	return (
		<div className="search-documents">
			<p className="search-documents__headline">
				{configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
			</p>
			<form className="search-documents__form" onSubmit={(ev) => submitFormDocument(ev)}>
				<div className="search-documents__form-wrapper">
					<div className="search-certificate__input-product-wrapper">
						<span className="float-info">
							{configStore.isCurrentLangUa ? floatDocumentInfo.ua : floatDocumentInfo.ru}
						</span>
						<input
							pattern="^[0-9/-]+$"
							className={`search-documents__input
						${documentsStore.searchErrorNum !== 0 ? 'input-error' : ''}
						`}
							type="text"
							onInput={(ev) => setFactureNum(ev, ev.target.value)}
							placeholder="№ документа"
							value={documentsStore.searchFactureNum}
						/>
						{documentsStore.searchFactureNum !== '' && (
							<button
								onClick={resetSearch}
								type="button"
								className="search-certificate__button-reset search-certificate__button-reset-facture"
							>
								<i className="icon-cross"/>
							</button>
						)}
					</div>
				</div>
				<button type="submit" className="search-documents__submit">
					<i className="icon-search search-documents__submit-icon"/>
					<span className="search-documents__submit-text">
						{configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
					</span>
				</button>
			</form>
		</div>
	);
};

export default SearchDocuments;
