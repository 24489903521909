import certificatesTypes from '../types/certificates';

const initialStore = {
  certificatesList: [],
  selectedList: [],
  allItemsChecked: false,
  certSearchErrorNum: 0,
};

const certificatesStore = (state = initialStore, action) => {
  switch (action.type) {
    case certificatesTypes.SET_CERTIFICATES_LIST:
      return {
        ...state,
        certificatesList: action.array,
      };
    case certificatesTypes.SET_ALL_ITEMS_CHECKED:
      return {
        ...state,
        allItemsChecked: action.bool,
      };
    case certificatesTypes.SET_CERTIFICATE_SELECTED_LIST:
      return {
        ...state,
        selectedList: action.array,
      };
    case certificatesTypes.SET_RIGHT_PRELOADER:
      return {
        ...state,
        preloader: action.value,
      };
    case certificatesTypes.SET_CERT_SEARCH_ERROR_NUM:
      return {
        ...state,
        certSearchErrorNum: action.num,
      };
    default:
      return state;
  }
};

export default certificatesStore;
