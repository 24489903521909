import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import pretensionsActions from "../../store/actions/pretensions";

const textClientFilled = {
  ru : 'Заполнено клиентом',
  ua : 'Заповнено клієнтом',
};

const PretensionViewItem = (props) => {
  const configStore    = useSelector((store) => store.configStore);
  const dispatch       = useDispatch();
  const [isOpen, changeOpenState] = useState(false)
  // eslint-disable-next-line
  const { info } = props;

  const toggleWrap = () => {
    if (!isOpen) {
      changeOpenState(true)
    } else {
      changeOpenState(false)
    }
  }

  if (info.diff === '1') {
    return (
      <li className="pretension-view-item extended">
        <div className="pretension-view-item__static-content">
          <div className="pretension-view-item__col col-name">{info.good}</div>
          <div className="pretension-view-item__col col-series">{info.series}</div>
          <div className="pretension-view-item__col col-amount">{info.count}</div>
          <div className="pretension-view-item__col col-price">{info.price} грн</div>
          <div className="pretension-view-item__col col-lack">{info.deficit ? info.deficit : '0'}</div>
          <div className="pretension-view-item__col col-broken">{info.breakage ? info.breakage : '0'}</div>
          <div className="pretension-view-item__col col-defect">{info.waste ? info.waste : '0'}</div>
          <div className="pretension-view-item__col col-surplus">{info.surplus ? info.surplus : '0'}</div>
          <div className="pretension-view-item__pan">
            <i className="icon-draw"/>
            <button type="button"
                    className={`pretension-view-item__button-wrap ${isOpen ? '' : 'closed'}`}
                    onClick={() => toggleWrap()}>
              <i className="icon-chevron-right-bold"/>
            </button>
          </div>
        </div>
        <div className={`pretension-view-item__dynamic-content ${isOpen ? '' : 'wrapped'}`}>
          <div className="dynamic-content__title">
            {configStore.isCurrentLangUa ? textClientFilled.ua : textClientFilled.ru}
          </div>
          <div className="dynamic-content__list">
            <div className="pretension-view-item__col col-name">
              {info.customer_info.good}
            </div>
            <div className="pretension-view-item__col col-series">
              {info.customer_info.series}
            </div>
            <div className="pretension-view-item__col col-amount">
              {info.count}
            </div>
            <div className="pretension-view-item__col col-price">
              {info.customer_info.price} грн
            </div>
            <div className="pretension-view-item__col col-lack">
              {info.customer_info.deficit ? info.customer_info.deficit : '0'}
            </div>
            <div className="pretension-view-item__col col-broken">
              {info.customer_info.breakage ? info.customer_info.breakage : '0'}
            </div>
            <div className="pretension-view-item__col col-defect">
              {info.customer_info.waste ? info.customer_info.waste : '0'}
            </div>
            <div className="pretension-view-item__col col-surplus">
              {info.customer_info.surplus ? info.customer_info.surplus : '0'}
            </div>
          </div>
        </div>
      </li>
    );
  }
  return (
    <li className="pretension-view-item">
      <div className="pretension-view-item__static-content">
        <div className="pretension-view-item__col col-name">{info.customer_info.good}</div>
        <div className="pretension-view-item__col col-series">{info.customer_info.series}</div>
        <div className="pretension-view-item__col col-amount">{info.count}</div>
        <div className="pretension-view-item__col col-price">{info.customer_info.price} грн</div>
        <div className="pretension-view-item__col col-lack">
          {info.customer_info.deficit ? info.customer_info.deficit : '0'}
        </div>
        <div className="pretension-view-item__col col-broken">
          {info.customer_info.breakage ? info.customer_info.breakage : '0'}
        </div>
        <div className="pretension-view-item__col col-defect">
          {info.customer_info.waste ? info.customer_info.waste : '0'}
        </div>
        <div className="pretension-view-item__col col-surplus">
          {info.customer_info.surplus ? info.customer_info.surplus : '0'}
        </div>
      </div>
    </li>
  );

};

export default PretensionViewItem;
