const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST';
const SET_PER_PAGE = 'SET_PER_PAGE';
const SET_PAGE = 'SET_PAGE';
const SET_PAGE_COUNT = 'SET_PAGE_COUNT';
const SET_CURRENT_FACTURE = 'SET_CURRENT_FACTURE';
const SET_INVOICE_NUM = 'SET_INVOICE_NUM';
const SET_INFO_PAGE = 'SET_INFO_PAGE';
const SET_CURRENT_FACTURE_UID = 'SET_CURRENT_FACTURE_UID';
const SET_CURRENT_FACTURE_DATE = 'SET_CURRENT_FACTURE_DATE';
const SET_INFO_PAGE_TITLE = 'SET_INFO_PAGE_TITLE';
const SET_SEARCH_DATE = 'SET_SEARCH_DATE';
const SET_DOCUMENTS_RIGHT_CONTENT = 'SET_DOCUMENTS_RIGHT_CONTENT';
const SET_SEARCH_NUM = 'SET_SEARCH_NUM';
const SET_DOCUMENTS_RIGHT_HEADLINE = 'SET_DOCUMENTS_RIGHT_HEADLINE';
const SET_RIGHT_CHECKBOX = 'SET_RIGHT_CHECKBOX';
const SET_RIGHT_CHECKBOX_ERROR = 'SET_RIGHT_CHECKBOX_ERROR';
const SET_SEARCH_ERROR_NUM = 'SET_SEARCH_ERROR_NUM';
const SET_PRELOADER = 'SET_PRELOADER';
const SET_DOCUMENTS_SORT_COL = 'SET_DOCUMENTS_SORT_COL';
const SET_DOCUMENTS_SORT_DIR = 'SET_DOCUMENTS_SORT_DIR';

const combineConst = {
  SET_DOCUMENTS_LIST,
  SET_DOCUMENTS_RIGHT_CONTENT,
  SET_PER_PAGE,
  SET_SEARCH_DATE,
  SET_SEARCH_NUM,
  SET_DOCUMENTS_RIGHT_HEADLINE,
  SET_RIGHT_CHECKBOX,
  SET_CURRENT_FACTURE_DATE,
  SET_RIGHT_CHECKBOX_ERROR,
  SET_PAGE,
  SET_CURRENT_FACTURE,
  SET_PAGE_COUNT,
  SET_CURRENT_FACTURE_UID,
  SET_INVOICE_NUM,
  SET_INFO_PAGE,
  SET_INFO_PAGE_TITLE,
  SET_SEARCH_ERROR_NUM,
  SET_PRELOADER,
  SET_DOCUMENTS_SORT_COL,
  SET_DOCUMENTS_SORT_DIR,
};

export default combineConst;
