import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import pretensionsActions from '../../store/actions/pretensions';

const title = {
  ru: 'Претензии инструкция VentaLTD',
  ua: 'Претензии інструкція VentaLTD',
};
const button = {
  ru: 'Назад',
  ua: 'Назад',
};

const CertificatesInfo = () => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const dispatch = useDispatch();
  const configStore = useSelector((store) => store.configStore);
  const history = useHistory();

  useEffect(() => {
    dispatch(pretensionsActions.fetchPretensionsInfo(configStore.isCurrentLangUa));
  }, [configStore.isCurrentLangUa]);

  return (
    <>
      <Helmet>
        <title>{configStore.isCurrentLangUa ? title.ua : title.ru}</title>
      </Helmet>
      <div className="certificates-info">

        {/* eslint-disable-next-line react/button-has-type */}
        <button className="button-back" onClick={() => history.goBack()}>
          {configStore.isCurrentLangUa ? button.ua : button.ru}
        </button>
        <h1 className="certificates-info__headline">{pretensionsStore.infoPageTitle}</h1>
        <div dangerouslySetInnerHTML={{ __html: pretensionsStore.infoPage }} />
      </div>
    </>
  );
};

export default CertificatesInfo;
