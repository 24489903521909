import config from '../types/config';
import api from '../../api/api';

const setEmail = (email) => ({
  type: config.SET_EMAIL,
  email,
});

const setLoginBool = (status) => ({
  type: config.SET_USER_AUTH,
  status,
});

const setUserName = (name) => ({
  type: config.SET_USER_NAME,
  name,
});

const changeLang = (language) => ({
  type: config.SET_LANG,
  lang: language,
});

const fetchEmail = (lang) => async (dispatch) => {
  const response = await api.get(`${lang ? 'ua' : ''}/api/react/config`);
  dispatch(setEmail(response.data.result.contacts.email));

  if (response.data.result.user) {
    dispatch(setUserName(response.data.result.user.login));
  }
  // dispatch(setLoginBool(response.data.login));
};

export default { fetchEmail, setEmail, changeLang, setLoginBool };
