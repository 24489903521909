import pretensionsTypes from '../types/pretensions';
import documentsTypes from "../types/documents";

const initialStore = {
  pretensionsList: [],
  facturesList: [],
  productsList: [],
  editedList: new Map(),
  pretensionComment: '',
  pretensionsSection: 1,
  pretensionsStep: 1,
  pretensionFactureNum: 0,
  pretensionFactureDate: '',
  pretensionFactureUid: '',
  currentPretensionUid: '',
  currentPretension: {},
  currentPretensionDialog: [],
  currentPretensionMessage: '',
  dateStart: undefined,
  dateEnd: undefined,
  searchFactureNum: '',
  searchPretensionStatus: 'ALL',
  pretensionsSortDirection: '',
  pretensionsSortColumn: '',
  errorNum: 0,
  preloader: false,
  listPreloader: false,
  currentPage: 1,
  perPage: 10,
  pageCount: 0,
  pretensionsError: '',
  pretensionsInfoColumn: '',
  infoPage: '',
  infoPageTitle: '',

  tabs: 0,
  productsSearchName: '',
  productsSortDirection: '',
  productsSortColumn: '',
  productsPerPage: 10,
  productsPageCount: 0,
  productsPageCurrent: 1,

  imagesList: [], // uploaded images
  imagesAdminList: [], // admin images
  imagesClientList: [], // clients images
  totalImagesClient: [], // total images client
};

const pretensionsStore = (state = initialStore, action) => {
  switch (action.type) {
    case pretensionsTypes.SET_PRETENSIONS_LIST:
      return {
        ...state,
        pretensionsList: action.array,
      };
    case pretensionsTypes.SET_FACTURES_LIST:
      return {
        ...state,
        facturesList: action.array,
      };
    case pretensionsTypes.SET_PRODUCTS_LIST:
      return {
        ...state,
        productsList: action.array,
      };
    case pretensionsTypes.SET_PRODUCTS_EDITED_LIST:
      return {
        ...state,
        editedList: action.array,
      };
    case pretensionsTypes.SET_IMAGES_LIST:
      return {
        ...state,
        imagesList: action.array,
      };
    case pretensionsTypes.SET_IMAGES_ADMIN_LIST:
      return {
        ...state,
        imagesAdminList: action.array,
      };
    case pretensionsTypes.SET_IMAGES_CLIENT_LIST:
      return {
        ...state,
        imagesClientList: action.array,
      };
    case pretensionsTypes.SET_PRETENSION_COMMENT:
      return {
        ...state,
        pretensionComment: action.text,
      };
    case pretensionsTypes.SET_PRETENSIONS_SECTION:
      return {
        ...state,
        pretensionsSection: action.number,
      };
    case pretensionsTypes.SET_PRETENSIONS_STEP:
      return {
        ...state,
        pretensionsStep: action.number,
      };
    case pretensionsTypes.SET_PRETENSION_FACTURE_NUM:
      return {
        ...state,
        pretensionFactureNum: action.number,
      };
    case pretensionsTypes.SET_PRETENSION_FACTURE_DATE:
      return {
        ...state,
        pretensionFactureDate: action.date,
      };
    case pretensionsTypes.SET_PRETENSION_FACTURE_UID:
      return {
        ...state,
        pretensionFactureUid: action.uid,
      };
    case pretensionsTypes.SET_CURRENT_PRETENSION_UID:
      return {
        ...state,
        currentPretensionUid: action.uid,
      };
    case pretensionsTypes.SET_CURRENT_PRETENSION:
      return {
        ...state,
        currentPretension: action.object,
      };
    case pretensionsTypes.SET_CURRENT_PRETENSION_DIALOG:
      return {
        ...state,
        currentPretensionDialog: action.list,
      };
    case pretensionsTypes.SET_CURRENT_PRETENSION_MESSAGE:
      return {
        ...state,
        currentPretensionMessage: action.text,
      };
    case pretensionsTypes.SET_SEARCH_DATE_START:
      return {
        ...state,
        dateStart: action.text,
      };
    case pretensionsTypes.SET_SEARCH_DATE_END:
      return {
        ...state,
        dateEnd: action.text,
      };
    case pretensionsTypes.SET_SEARCH_FACTURE_NUM:
      return {
        ...state,
        searchFactureNum: action.text,
      };
    case pretensionsTypes.SET_SEARCH_STATUS:
      return {
        ...state,
        searchPretensionStatus: action.text,
      };
    case pretensionsTypes.SET_PRETENSIONS_SORT_DIRECTION:
      return {
        ...state,
        pretensionsSortDirection: action.text,
      };
    case pretensionsTypes.SET_PRETENSIONS_SORT_COLUMN:
      return {
        ...state,
        pretensionsSortColumn: action.text,
      };
    case pretensionsTypes.SET_ERROR_NUM:
      return {
        ...state,
        errorNum: action.number,
      };
    case pretensionsTypes.SET_PRELOADER:
      return {
        ...state,
        preloader: action.value,
      };
    case pretensionsTypes.SET_LIST_PRELOADER:
      return {
        ...state,
        listPreloader: action.value,
      };
    case pretensionsTypes.SET_PER_PAGE:
      return {
        ...state,
        perPage: action.num,
      };
    case pretensionsTypes.SET_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.num,
      };
    case pretensionsTypes.SET_PAGE:
      return {
        ...state,
        currentPage: action.num,
      };
    case pretensionsTypes.SET_TABS:
      return {
        ...state,
        tabs: action.num,
      };
    case pretensionsTypes.SET_PRODUCTS_SEARCH_NAME:
      return {
        ...state,
        productsSearchName: action.text,
      };
    case pretensionsTypes.SET_PRODUCTS_SORT_DIRECTION:
      return {
        ...state,
        productsSortDirection: action.text,
      };
    case pretensionsTypes.SET_PRODUCTS_SORT_COLUMN:
      return {
        ...state,
        productsSortColumn: action.text,
      };
    case pretensionsTypes.SET_PRODUCTS_PER_PAGE:
      return {
        ...state,
        productsPerPage: action.num,
      };
    case pretensionsTypes.SET_PRODUCTS_PAGE_COUNT:
      return {
        ...state,
        productsPageCount: action.num,
      };
    case pretensionsTypes.SET_PRODUCTS_PAGE:
      return {
        ...state,
        productsPageCurrent: action.num,
      };
    case pretensionsTypes.SET_PRETENSIONS_ERROR:
      return {
        ...state,
        pretensionsError: action.text,
      };
    case pretensionsTypes.SET_PRETENSIONS_INFO_COLUMN:
      return {
        ...state,
        pretensionsInfoColumn: action.text,
      };
    case pretensionsTypes.SET_INFO_PAGE_TITLE:
      return {
        ...state,
        infoPageTitle: action.title,
      };
    case pretensionsTypes.SET_INFO_PAGE:
      return {
        ...state,
        infoPage: action.str,
      };
    case pretensionsTypes.SET_TOTAL_IMAGES_CLIENT:
      return {
        ...state,
        totalImagesClient: action.array,
      };
    default:
      return state;
  }
};


export default pretensionsStore;
