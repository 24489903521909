import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LogoutLink = () => {
  const configStore = useSelector((store) => store.configStore);

  if (!configStore.isUserAuth) {
    return null;
  }

  return (
    <Link to="/logout" className="header__logout-link">
      <i className="icon-logout" />
    </Link>
  );
};

export default LogoutLink;
