import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import documentsActions from '../../store/actions/documents';
import CheckboxDocuments from '../Checkbox/CheckboxDocuments';

const DocumentsRight = () => {
  const documentsStore = useSelector((store) => store.documentsStore);
  const configStore = useSelector((store) => store.configStore);
  const dispatch = useDispatch();

  const checkboxText = {
    ru: 'Принять условия',
    ua: 'Прийняти умову',
  };

  useEffect(() => {
    dispatch(documentsActions.fetchDocumentsRight(configStore.isCurrentLangUa));

    if (localStorage.getItem('documentsCheck') !== null) {
      dispatch(documentsActions.setDocRightCheckbox(!!localStorage.getItem('documentsCheck')));
    }
  }, [configStore.isCurrentLangUa]);

  const checkDocument = (bool) => {
    dispatch(documentsActions.setDocRightCheckbox(bool));
    dispatch(documentsActions.setDocRightCheckboxError(false));
  };

  return (
    <div className="document-right">
      <div
        className={`document-right__wrapper ${
          documentsStore.rightCheckboxError ? 'document-right__wrapper--error' : ''
        }`}
      >
        <h1 className="document-right__title">{documentsStore.rightHeadline}</h1>
        <p
          className="document-right__content"
          dangerouslySetInnerHTML={{ __html: documentsStore.rightContent }}
        />
        <CheckboxDocuments
          id="documents"
          checkedAction={() => checkDocument(true)}
          checkedState={documentsStore.rightCheckbox}
          text={configStore.isCurrentLangUa ? checkboxText.ua : checkboxText.ru}
        />
      </div>
    </div>
  );
};

export default DocumentsRight;
