import React, {useEffect} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import Helmet from "react-helmet";
import PretensionsChoose from "../../components/Pretensions/PretensionsChoose";
import PretensionCreate from "../../components/Pretensions/PretensionCreate";
import PretensionView from "../../components/Pretensions/PretensionView";
import pretensionsActions from "../../store/actions/pretensions";
import PretensionModal from "../../components/Pretensions/PretensionModal";
import ReportModal from "../../components/Report/ReportModal";



const Pretensions = () => {
  const title                   = {
    ru : 'Претензии VentaLTD',
    ua : 'Претензії VentaLTD',
  };

  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore   = useSelector((store) => store.configStore);
  const dispatch      = useDispatch();

  let pretensionsSection;
  switch (pretensionsStore.pretensionsSection) {
    case 1 :
      pretensionsSection = <PretensionsChoose/>
      break;
    case 2 :
      pretensionsSection = <PretensionCreate/>
      break;
    case 3 :
      pretensionsSection = <PretensionView/>
      break;
    default:
      break;
  }

  return (
    <>
      <Helmet>
        <title>{configStore.isCurrentLangUa ? title.ua : title.ru}</title>
      </Helmet>
      {pretensionsSection}
      <PretensionModal/>
      <ReportModal/>
    </>
  );
}


export default Pretensions;
