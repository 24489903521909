import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import pretensionsActions from "../../store/actions/pretensions";
import PretensionStatus from "./PretensionStatus";

const PretensionItem = (props) => {
  const pretensionsStore = useSelector((state) => state.pretensionsStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const configStore    = useSelector((store) => store.configStore);
  const dispatch       = useDispatch();
  // eslint-disable-next-line
  const { number, date, facture, hasphoto, changed, edits, sum, updated, status, pretensionUid, factureUid, factureDate, pretensionItem, changeddate} = props;

  const addannoText  = {
    ru : 'досыл аннотации',
    ua : 'досилання анотації',
  };
  const addgoodText  = {
    ru : 'досыл товара',
    ua : 'досилання товару',
  };
  const buyoverText  = {
    ru : 'приобр. излишек',
    ua : 'утвор. надлишок',
  };
  const docretText  = {
    ru : 'прием возврата с документами',
    ua : 'прийом повернення з документами',
  };
  const exgoodText  = {
    ru : 'обмен товара',
    ua : 'обмін товару',
  };
  const expertText  = {
    ru : 'экспертиза',
    ua : 'экспертиза',
  };
  const fedbrakText  = {
    ru : 'ошиб. Нет серий с фед. браком',
    ua : 'помил. Нема серій з фед. браком',
  };
  const goodretText  = {
    ru : 'возврат товара',
    ua : 'повернення товару',
  };
  const responsText  = {
    ru : 'отзыв поставщика',
    ua : 'відгук постачальника',
  };
  const retoverText  = {
    ru : 'возврат излишек',
    ua : 'повернення надлишок',
  };
  const returnText  = {
    ru : 'возврат',
    ua : 'повернення',
  };
  const voidText  = {
    ru : 'необоснованная',
    ua : 'необґрунтована',
  };
  const waitactText  = {
    ru : 'ТОРГ-2',
    ua : 'ТОРГ-2',
  };
  const problemText  = {
    ru : 'проблемная',
    ua : 'проблемна',
  };
  const corpretText  = {
    ru : 'претензия по кор.ф. невозможна',
    ua : 'претензія щодо кор.ф. неможлива',
  };
  const corretText  = {
    ru : 'позврат по кор.ф. невозможен',
    ua : 'повернення по кор.ф. неможливе',
  };
  const crpriceText  = {
    ru : 'корректировка цены',
    ua : 'коригування ціни',
  };
  const otherscText  = {
    ru : 'оформите в другом разделе',
    ua : 'оформіть в іншому розділі',
  };

  const openPretension = () => {
    if (status !== 'SENT') {
      dispatch(pretensionsActions.setCurrentPretensionUid(pretensionUid));
      dispatch(pretensionsActions.setProductsPage(1));
      dispatch(pretensionsActions.setSearchFactureNum(''));
      dispatch(pretensionsActions.setImagesList([]));
      dispatch(pretensionsActions.setImagesClientList([]));
      dispatch(pretensionsActions.setImagesAdminList([]));
      dispatch(pretensionsActions.fetchCurrentPretension(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionUid,
      ));
      dispatch(pretensionsActions.fetchImages(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        pretensionUid,
      ));
    } else if (status === 'SENT') {
      dispatch(pretensionsActions.setPretensionsError('still_in_status_sent'));
    } else if (status === 'ERROR') {
      dispatch(pretensionsActions.setPretensionsError('still_in_status_error'));
    }
  }

  const conclusion = () => {
    const conclusionArr = [];
    if (pretensionItem.da_addanno === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? addannoText.ua : addannoText.ru}`);
    }
    if (pretensionItem.da_addgood === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? addgoodText.ua : addgoodText.ru}`);
    }
    if (pretensionItem.da_buyover === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? buyoverText.ua : buyoverText.ru}`);
    }
    if (pretensionItem.da_docret === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? docretText.ua : docretText.ru}`);
    }
    if (pretensionItem.da_exgood === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? exgoodText.ua : exgoodText.ru}`);
    }
    if (pretensionItem.da_expert === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? expertText.ua : expertText.ru}`);
    }
    if (pretensionItem.da_fedbrak === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? fedbrakText.ua : fedbrakText.ru}`);
    }
    if (pretensionItem.da_goodret === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? goodretText.ua : goodretText.ru}`);
    }
    if (pretensionItem.da_respons === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? responsText.ua : responsText.ru}`);
    }
    if (pretensionItem.da_retover === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? retoverText.ua : retoverText.ru}`);
    }
    if (pretensionItem.da_return === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? returnText.ua : returnText.ru}`);
    }
    if (pretensionItem.da_void === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? voidText.ua : voidText.ru}`);
    }
    if (pretensionItem.da_waitact === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? waitactText.ua : waitactText.ru}`);
    }
    if (pretensionItem.da_problem === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? problemText.ua : problemText.ru}`);
    }
    if (pretensionItem.da_corpret === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? corpretText.ua : corpretText.ru}`);
    }
    if (pretensionItem.da_corret === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? corretText.ua : corretText.ru}`);
    }
    if (pretensionItem.da_crprice === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? crpriceText.ua : crpriceText.ru}`);
    }
    if (pretensionItem.da_othersc === '1') {
      conclusionArr.push(`${configStore.isCurrentLangUa ? otherscText.ua : otherscText.ru}`);
    }

    let conclusionStr = '-';
    if (conclusionArr.length > 0) {
      conclusionStr = conclusionArr.join(', ');
    }
    return conclusionStr.charAt(0).toUpperCase() + conclusionStr.slice(1);
  }

  return (
    <li className={`pretension-item
    ${changed === '1' && status !== 'ERROR' ? 'edited' : ''}
    ${pretensionItem.da_problem === '1' ? 'problem' : ''}`}>
      <div className="pretension-item__col col-number">
        <button type="button" onClick={openPretension}>{number}</button>
      </div>
      <div className="pretension-item__col col-date">{date}</div>
      <div className="pretension-item__col col-facture">{facture}</div>
      <div className={`pretension-item__col col-photo ${hasphoto === '1' ? 'has-photo' : ''}`}>
        <i className="icon-photo-camera"/>
      </div>
      <div className={`pretension-item__col col-edits ${edits === '1' ? 'has-edits' : ''}`}>
        <i className="icon-draw"/>
      </div>
      <div className="pretension-item__col col-answer">
        {conclusion()}
      </div>
      <div className="pretension-item__col col-sum">{sum} грн</div>
      <div className="pretension-item__col col-updated">{updated}</div>
      <div className="pretension-item__col col-status">
        <PretensionStatus status={status}/>
      </div>
      <div className="pretension-item__col col-btn">
        <button type="button" onClick={openPretension}>
          <i className="icon-chevron-right-bold"/>
        </button>
      </div>
    </li>
  );
};

export default PretensionItem;
