import React, {useEffect, useRef} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import DatePicker from "react-datepicker";
import uk from "date-fns/locale/uk";
import ru from "date-fns/locale/ru";
import mailsActions from "../../store/actions/mails";

const SearchMails = () => {
  const mailsStore = useSelector((store) => store.mailsStore);
  const configStore = useSelector((store) => store.configStore);
  const deliveryStore = useSelector((store) => store.deliveryStore);
  const dispatch = useDispatch();
  const datepickerStart    = useRef(null);
  const datepickerEnd    = useRef(null);

  const searchText = {
    ua : 'Пошук',
    ru : 'Поиск',
  };
  const floatmailsInfo = {
    ua : 'Введіть 3 або більше символів. Допустимі символи: "-", "/" та цифри.',
    ru : 'Введите 3 или более символов. Допустимые символы: "-", "/" и цифры.',
  };
  const placeholderNum = {
    ua : '№ фактури',
    ru : '№ фактуры',
  }
  const dateTitle = {
    ua : 'За період з',
    ru : 'За период с',
  }

  const submitFormMails = (ev) => {
    ev.preventDefault();
    dispatch(
      mailsActions.fetchMails(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        mailsStore.dateStart,
        mailsStore.dateEnd,
        mailsStore.searchNum,
        mailsStore.mailsType,
        mailsStore.mailsSortColumn,
        mailsStore.mailsSortDirection,
        mailsStore.mailsPerPage,
        mailsStore.mailsPage,
        mailsStore.mailsOnlyNew,
      ),
    );
  };

  const setMailsNum = (ev, num) => {
    ev.preventDefault();
    const regexp = new RegExp('^[0-9/-]+$');

    if (num.match(regexp) !== null || num === '') {
      dispatch(mailsActions.setSearchNum(num));
    }
  };

  const resetMailsNum = () => {
    dispatch(mailsActions.setSearchNum(''));
    dispatch(
      mailsActions.fetchMails(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        mailsStore.dateStart,
        mailsStore.dateEnd,
        '',
        mailsStore.mailsType,
        mailsStore.mailsSortColumn,
        mailsStore.mailsSortDirection,
        mailsStore.mailsPerPage,
        mailsStore.mailsPage,
        mailsStore.mailsOnlyNew,
      ),
    );
  };

  const setDateStart = (date) => {
    let dateRawStart = date;
    const dateRawEnd = mailsStore.dateEnd;
    if (dateRawEnd && dateRawEnd !== ''){
      if (dateRawStart > dateRawEnd) {
        dateRawStart = dateRawEnd
      }
    }

    dispatch(mailsActions.setDateStart(dateRawStart));
  };

  const setDateEnd = (date) => {
    let dateRawEnd = date;
    const dateRawStart = mailsStore.dateStart;
    if (dateRawStart && dateRawStart !== ''){
      if (dateRawEnd < dateRawStart) {
        dateRawEnd = dateRawStart
      }
    }

    dispatch(mailsActions.setDateEnd(dateRawEnd));
  };

  const handleChangeRaw = (ev, value) => {
    const regexp = new RegExp(
      /[a-zA-Zа-яА-Я!@#$%^&*()_+=?,/]/g
    );
    if (regexp.test(value) || value.length > 10) {
      ev.preventDefault();
    }
  };

  const focusHiddenDatepickerStart = () => {
    datepickerStart.current.input.focus();
  };
  const focusHiddenDatepickerEnd = () => {
    datepickerEnd.current.input.focus();
  };

  const resetDateStart = () => {
    dispatch(mailsActions.setDateStart(undefined));
    dispatch(
      mailsActions.fetchMails(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        undefined,
        mailsStore.dateEnd,
        mailsStore.searchNum,
        mailsStore.mailsType,
        mailsStore.mailsSortColumn,
        mailsStore.mailsSortDirection,
        mailsStore.mailsPerPage,
        mailsStore.mailsPage,
        mailsStore.mailsOnlyNew,
      ),
    );
  };
  const resetDateEnd = () => {
    dispatch(mailsActions.setDateEnd(undefined));
    dispatch(
      mailsActions.fetchMails(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        mailsStore.dateStart,
        undefined,
        mailsStore.searchNum,
        mailsStore.mailsType,
        mailsStore.mailsSortColumn,
        mailsStore.mailsSortDirection,
        mailsStore.mailsPerPage,
        mailsStore.mailsPage,
        mailsStore.mailsOnlyNew,
      ),
    );
  };

  return (
    <div className="search-mails">
      <p className="search-mails__headline">
        {configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
      </p>
      <form className="search-mails__form"
            onSubmit={(ev) => submitFormMails(ev)}>
        <div className="search-mails__form-wrapper">
          <div className="search-mails__input-product-wrapper">
						<span className="float-info">
							{configStore.isCurrentLangUa ? floatmailsInfo.ua : floatmailsInfo.ru}
						</span>
            <input
              pattern="^[0-9/-]+$"
              className={`search-mails__input
						${mailsStore.mailsError !== 'no-such-number' ? 'input-error' : ''}
						`}
              type="text"
              placeholder={`${configStore.isCurrentLangUa ? placeholderNum.ua : placeholderNum.ru}`}
              onInput={(ev) => setMailsNum(ev, ev.target.value)}
              value={mailsStore.searchNum}
            />
            {mailsStore.searchNum && mailsStore.searchNum.length > 0 && (
              <button
                onClick={resetMailsNum}
                type="button"
                className="search-certificate__button-reset search-certificate__button-reset-facture">
                <i className="icon-cross"/>
              </button>
            )}
          </div>
          <span>{configStore.isCurrentLangUa ? dateTitle.ua : dateTitle.ru}</span>
          <div className='search-mails__calendar'>
            <div className="react-datepicker-wrapper">
              <div className="react-datepicker__input-container">
                <DatePicker
                  locale={configStore.isCurrentLangUa ? uk : ru}
                  ref={datepickerStart}
                  placeholderText="Дата"
                  dateFormat="dd.MM.yyyy"
                  selected={mailsStore.dateStart}
                  onChange={(date) => setDateStart(date)}
                  onChangeRaw={event => handleChangeRaw(event, event.target.value)}
                />
              </div>
            </div>
            {mailsStore.dateStart === undefined ? (
              <button
                onClick={focusHiddenDatepickerStart}
                type="button"
                className="search-certificate__calendar-icon"
              >
                <i className="icon-calendar"/>
              </button>
            ) : (
              <button
                onClick={resetDateStart}
                type="button"
                className="search-certificate__button-reset"
              >
                <i className="icon-cross"/>
              </button>
            )}
          </div>
          <span>по</span>
          <div className='search-mails__calendar'>
            <div className="react-datepicker-wrapper">
              <div className="react-datepicker__input-container">
                <DatePicker
                  locale={configStore.isCurrentLangUa ? uk : ru}
                  ref={datepickerEnd}
                  placeholderText="Дата"
                  dateFormat="dd.MM.yyyy"
                  selected={mailsStore.dateEnd}
                  onChange={(date) => setDateEnd(date)}
                  onChangeRaw={event => handleChangeRaw(event, event.target.value)}
                />
              </div>
            </div>
            {mailsStore.dateEnd === undefined ? (
              <button
                onClick={focusHiddenDatepickerEnd}
                type="button"
                className="search-certificate__calendar-icon"
              >
                <i className="icon-calendar"/>
              </button>
            ) : (
              <button
                onClick={resetDateEnd}
                type="button"
                className="search-certificate__button-reset"
              >
                <i className="icon-cross"/>
              </button>
            )}
          </div>
        </div>
        <button type="submit" className="search-mails__submit">
          <i className="icon-search search-mails__submit-icon"/>
          <span className="search-mails__submit-text">
						{configStore.isCurrentLangUa ? searchText.ua : searchText.ru}
					</span>
        </button>
      </form>
    </div>
  );
};

export default SearchMails;
