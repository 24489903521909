import React, {useEffect} from 'react';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import SearchDocuments from '../../components/Documents/SearchDocuments';
import DocumentsFactures from '../../components/Documents/DocumentsFactures';
import DocumentsRight from '../../components/Documents/DocumentsRight';
import documentsActions from "../../store/actions/documents";

const title                   = {
	ru : 'Документы VentaLTD',
	ua : 'Документи VentaLTD',
};
const documentsHeadline       = {
	ru : 'Документы',
	ua : 'Документи',
};
const documentsHeadlineButton = {
	ru : 'Инструкция по разделу',
	ua : 'Інструкція до розділу',
};
const nothing                   = {
	ua : 'Нічого не знайдено за вашими критеріями пошуку. ',
	ru : 'Ничего не найдено по вашим критериям поиска. ',
};
const backText                  = {
	ua : 'Повернутись до пошуку.',
	ru : 'Вернуться к поиску.',
};

const Documents = () => {
	const documentsStore = useSelector((state) => state.documentsStore);
	const deliveryStore = useSelector((store) => store.deliveryStore);
	const configStore   = useSelector((store) => store.configStore);
	const dispatch      = useDispatch();

	const resetSearch = () => {
		dispatch(documentsActions.setSearchNum(''));
		dispatch(documentsActions.setPerPage(10));
		dispatch(documentsActions.setCurrentPage(1));
		dispatch(
			documentsActions.fetchFactures(
				configStore.isCurrentLangUa,
				deliveryStore.selectCityUid,
				10,
				1,
				''
			),
		);
	};

	useEffect(
		() => () => {
			dispatch(documentsActions.setSearchErrorNum(0));
			dispatch(documentsActions.setDocumentsList([]));
			dispatch(documentsActions.setCurrentPage(1));
			dispatch(documentsActions.setSearchNum(''));
		},
		[],
	);

	return (
		<>
			<Helmet>
				<title>{configStore.isCurrentLangUa ? title.ua : title.ru}</title>
			</Helmet>
			<section className="certificates">
				<div className="certificates__headline-container">
					<h2 className="certificates__headline">
						{configStore.isCurrentLangUa ? documentsHeadline.ua : documentsHeadline.ru}
					</h2>
					<Link className="certificates__button-instructions" to="/documents-help">
						{configStore.isCurrentLangUa ? documentsHeadlineButton.ua : documentsHeadlineButton.ru}
					</Link>
				</div>
				<SearchDocuments/>
				<div className="certificates__content">
					{documentsStore.searchErrorNum !== 1 ? (
						<DocumentsFactures/>
					) : (
						<p className="certificates__content-search-error">
              <i className="icon-info"/>
							{configStore.isCurrentLangUa ? nothing.ua : nothing.ru}
							<button
								onClick={resetSearch}
								type="button"
								className="certificates__content-search-error-button"
							>
								{configStore.isCurrentLangUa ? backText.ua : backText.ru}
							</button>
						</p>
					)}
					<DocumentsRight/>
				</div>
			</section>
		</>
	);
};

export default Documents;
