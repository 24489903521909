import loginTypes from '../types/login';
import configActions from './config';
import api from '../../api/api';

const setHeadline = (headline) => ({
  type: loginTypes.SET_HEADLINE,
  headline,
});

const setBottomText = (text) => ({
  type: loginTypes.SET_BOTTOM_TEXT,
  text,
});

const setFormText = (text) => ({
  type: loginTypes.SET_FORM_TEXT,
  text,
});

const setFormHeadline = (headline) => ({
  type: loginTypes.SET_FORM_HEADLINE,
  headline,
});

const setRememberCheck = (state) => ({
  type: loginTypes.SET_REMEMBER_CHECK,
  check: !state,
});

const setLoginValue = (value) => ({
  type: loginTypes.SET_LOGIN_VALUE,
  value,
});

const setPasswordValue = (value) => ({
  type: loginTypes.SET_PASSWORD_VALUE,
  value,
});

const setErrorForm = (errorText) => ({
  type: loginTypes.SET_FORM_ERROR,
  errorText,
});

const setUtp = (array) => {
  const utpArray = array.map((elem) => ({ id: elem.id, title: elem.title }));

  return {
    type: loginTypes.SET_UTP,
    utpArray,
  };
};

const setLogin = (value) => ({
  type: loginTypes.SET_LOGIN_PRELOADER,
  value,
});

const logout = () => async (dispatch) => {
  await dispatch(configActions.setLoginBool(false));
  const response = await api.get('/api/react/logout');

  await dispatch(configActions.setLoginBool(response.data.login));
};

const fetchInfo = (lang) => async (dispatch) => {
  const response = await api.get(`${lang ? 'ua' : ''}/api/react/authinfo`);
  const responseAuth = await api.get(`${lang ? 'ua' : ''}/api/react/api_settings`);

  dispatch(configActions.setLoginBool(responseAuth.data.login));
  dispatch(setUtp(response.data.result.utp.block_list));
  dispatch(setHeadline(response.data.result.auth_text.title));
  dispatch(setFormText(response.data.result.auth_form.content));
  dispatch(setFormHeadline(response.data.result.auth_form.title));
  dispatch(setBottomText(response.data.result.auth_text.content));
};

const submitForm = (login, password) => async (dispatch) => {
  dispatch(setLogin(true));
  dispatch(setErrorForm(''));

  const data = new FormData();
  data.append('login', login);
  data.append('password', password);

  const response = await api.post('/api/react/login', data);

  if (response.data.error) {
    dispatch(setErrorForm(response.data.error));
  }

  if (response.data.login) {
    dispatch(configActions.setLoginBool(response.data.login));
  }

  dispatch(setLogin(false));
};

export default {
  fetchInfo,
  setRememberCheck,
  setPasswordValue,
  setLoginValue,
  submitForm,
  setLogin,
  logout,
};
