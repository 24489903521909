import React, {useEffect} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import ReactPaginate from 'react-paginate';
import CountList from '../CountList/CountList';
import FactureItem from './FactureItem';
import facturesActions from '../../store/actions/factures';
import Preloader from "../Preloader/Preloader";
import documentsActions from "../../store/actions/documents";
import menuActions from "../../store/actions/menu";

const numberDoc = '№ документа';
const dateDoc   = 'Дата';

const CertificateFactures = () => {
	const factureStore = useSelector((state) => state.factureStore);
	const configStore = useSelector((store) => store.configStore);
	const deliveryStore = useSelector((store) => store.deliveryStore);
	const dispatch = useDispatch();

	const changeCurrentFacture = (docNum, date, uid, elem) => {
		dispatch(facturesActions.setCurrentFacture(docNum));
		dispatch(facturesActions.setCurrentFactureDate(date));
		dispatch(facturesActions.setCurrentFactureUid(uid));
		dispatch(facturesActions.setGoodSearchText(''));
		dispatch(facturesActions.setGoodSearchSeries(''));
		dispatch(facturesActions.setGoodSearchErrorBool(false));
		dispatch(facturesActions.setCurrentFactureDateRaw(elem.с_date));
	};

	const listFactures = factureStore.certificateList.map((elem) => (
		<FactureItem
			key={elem.uid}
			onClick={() => changeCurrentFacture(elem.docnum, elem.date, elem.uid, elem)}
			number={elem.docnum}
			date={elem.date}
			uid={elem.uid}
			isActive={factureStore.currentFacture === elem.docnum}
		/>
	));

	const changePerPage = (num) => {
		dispatch(documentsActions.setPreloader(true));
		dispatch(facturesActions.setPerPage(num));
		dispatch(facturesActions.setCurrentPage(1));
	};

	const pageChange = (data) => {
		dispatch(facturesActions.setCurrentPage(data.selected + 1));
	};

	const sortFactures = (column) => {
		let direction = factureStore.facturesSortDirection;
		if (direction === 'DESC' || direction === '') {
			direction = 'ASC';
			dispatch(facturesActions.setFacturesSortDir('ASC'));
		} else {
			direction = 'DESC';
			dispatch(facturesActions.setFacturesSortDir('DESC'));
		}
		dispatch(facturesActions.setFacturesSortCol(column));
		dispatch(
			facturesActions.fetchFactures(
				configStore.isCurrentLangUa,
				deliveryStore.selectCityUid,
				factureStore.perPage,
				factureStore.currentPage,
				factureStore.searchFactureNum,
				factureStore.searchDate,
				column,
				direction,
			),
		);
	};

	useEffect(() => {
		if (deliveryStore.selectCityUid !== '') {
			dispatch(
				facturesActions.fetchFactures(
					configStore.isCurrentLangUa,
					deliveryStore.selectCityUid,
					factureStore.perPage,
					factureStore.currentPage,
					factureStore.searchFactureNum,
					factureStore.searchDate,
					factureStore.facturesSortColumn,
					factureStore.facturesSortDirection,
				),
			);
		}
	}, [
		configStore.isCurrentLangUa,
		deliveryStore.selectCityUid,
		factureStore.perPage,
		factureStore.currentPage,
	]);

  useEffect(() => {
    dispatch(menuActions.fetchMenu(configStore.isCurrentLangUa, deliveryStore.selectCityUid));
  }, [
    deliveryStore.selectCityUid,
  ]);

	return (
		<div className="certificate-content__documents">
			<Preloader/>
			<div className="certificate-content__documents-header">
				<div className="certificate-content__documents-header-document-container">
					<button type="button" onClick={() => sortFactures('docnum')}
					   className="certificate-content__documents-header-document-text">
						{numberDoc}
					</button>
				</div>
				<div className="certificate-content__documents-header-date-container">
					<button type="button" onClick={() => sortFactures('date')}
					        className="certificate-content__documents-header-date-text">
						{dateDoc}
					</button>
				</div>
			</div>
			<ul className="certificate-content__documents-list">{listFactures}</ul>
			<div className="certificate-content__bottom-nav">
				<CountList onClick={changePerPage} currentPerPage={factureStore.perPage} countOf="factures"/>
				<ReactPaginate
					pageRangeDisplayed={2}
					marginPagesDisplayed={2}
					pageCount={factureStore.pageCount}
					forcePage={factureStore.currentPage - 1}
					containerClassName="pager"
					pageLinkClassName="pager__button"
					activeLinkClassName="pager__button--active"
					previousLinkClassName="icon-arrow pager__arrow-prev"
					nextLinkClassName="icon-arrow pager__arrow-next"
					onPageChange={pageChange}
				/>
			</div>
		</div>
	);
};

export default CertificateFactures;
