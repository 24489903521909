import React, {useEffect} from 'react';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import SearchCertificates from '../../components/Certificates/SearchCertificates';
import CertificateFactures from '../../components/Certificates/CertificateFactures';
import CertificatesRight from '../../components/Certificates/CertificatesRight';
import facturesActions from '../../store/actions/factures';
import certificatesAction from "../../store/actions/certificates";

const certificateHeadline       = {
	ua : 'Cертифікати',
	ru : 'Сертификаты',
};
const certificateHeadlineButton = {
	ua : 'Інструкція до розділу',
	ru : 'Инструкция по разделу',
};
const title                     = {
	ru : 'Сертификаты VentaLTD',
	ua : 'Cертифікати VentaLTD',
};
const error1                   = {
  ua : 'Нічого не знайдено за вашими критеріями пошуку.\n' +
    'Для пошуку фактур за період більший 30 днів' +
    ' введіть точний номер фактури і обов\'язково вкажіть дату фактури. ',
  ru : 'Ничего не найдено по вашим критериям поиска.\n' +
    'Для поиска фактур за период более 30 дней' +
    ' введите точный номер фактуры и обязательно укажите дату фактуры ',
};
const error2                   = {
  ua : 'Нічого не знайдено за вашими критеріями пошуку.\n' +
    'Для пошуку сертифікатів фактур за період більший 30 днів' +
    ' введіть точний номер фактури і обов\'язково вкажіть дату фактури. ',
  ru : 'Ничего не найдено по вашим критериям поиска.\n' +
    'Для поиска сертификатов фактур за период более 30 дней' +
    ' введите точный номер фактуры и обязательно укажите дату фактуры ',
};
const backText                  = {
	ua : 'Повернутись до пошуку.',
	ru : 'Вернуться к поиску.',
};

const Certificates = () => {
	const factureStore  = useSelector((state) => state.factureStore);
	const deliveryStore = useSelector((store) => store.deliveryStore);
  const certificatesStore = useSelector((store) => store.certificatesStore);
	const configStore   = useSelector((store) => store.configStore);
	const dispatch      = useDispatch();

	useEffect(
		() => () => {
			dispatch(facturesActions.setSearchErrorNum(0));
			dispatch(facturesActions.setCurrentFacture(''));
			dispatch(facturesActions.setCertificateList([]));
			dispatch(facturesActions.setCurrentPage(1));
			dispatch(facturesActions.setCurrentFactureUid(''));
			dispatch(facturesActions.setSearchNum(''));
		},
		[],
	);

	const resetSearch = () => {
		dispatch(facturesActions.setSearchDate(undefined));
		dispatch(facturesActions.setSearchNum(''));
		dispatch(facturesActions.setPerPage(10));
		dispatch(facturesActions.setCurrentPage(1));
		dispatch(
			facturesActions.fetchFactures(
				configStore.isCurrentLangUa,
				deliveryStore.selectCityUid,
				10,
				1,
				'',
				undefined,
			),
		);
    dispatch(
      certificatesAction.fetchCertificates(
        configStore.isCurrentLangUa,
        deliveryStore.selectCityUid,
        '',
        undefined,
      ),
    );
	};

	let leftContent;
	if (certificatesStore.certificatesList.length > 0 && factureStore.searchErrorNum === 2) {
    leftContent = '';
  } else if (factureStore.searchErrorNum === 2 && certificatesStore.certSearchErrorNum === 0) {
    leftContent = <p className="certificates__content-search-error">
      <i className="icon-info"/>
      {configStore.isCurrentLangUa ? error1.ua : error1.ru}
      <button
        onClick={resetSearch}
        type="button"
        className="certificates__content-search-error-button"
      >
        {configStore.isCurrentLangUa ? backText.ua : backText.ru}
      </button>
    </p>
  } else if (certificatesStore.certSearchErrorNum === 1) {
    leftContent = <p className="certificates__content-search-error">
      {configStore.isCurrentLangUa ? error2.ua : error2.ru}
      <button
        onClick={resetSearch}
        type="button"
        className="certificates__content-search-error-button"
      >
        {configStore.isCurrentLangUa ? backText.ua : backText.ru}
      </button>
    </p>
  } else {
    leftContent = <CertificateFactures/>
  }

	return (
		<>
			<Helmet>
				<title>{configStore.isCurrentLangUa ? title.ua : title.ru}</title>
			</Helmet>
			<section className="certificates">
				<div className="certificates__headline-container">
					<h2 className="certificates__headline">
						{configStore.isCurrentLangUa ? certificateHeadline.ua : certificateHeadline.ru}
					</h2>
					<Link className="certificates__button-instructions" to="/certificates-help">
						{configStore.isCurrentLangUa
							? certificateHeadlineButton.ua
							: certificateHeadlineButton.ru}
					</Link>
				</div>
				<SearchCertificates/>
				<div className="certificates__content">
					{leftContent}
          <CertificatesRight/>
				</div>
			</section>
		</>
	);
};

export default Certificates;
